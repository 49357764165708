import { injectable, injectFromBase } from 'inversify';

import { CollectionName, DbCollectionCreator } from '@/features/system/db';

import type { IInvitationDC } from '../dataContracts';

import { referralMigrationStrategy } from './migrations';
import { referralSchema } from './schema';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class ReferralCollectionCreator extends DbCollectionCreator<IInvitationDC> {
  constructor() {
    super({
      collectionName: CollectionName.Invitation,
      schema: referralSchema,
      migrationStrategies: referralMigrationStrategy,
    });
  }
}
