import { FC, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { Reason } from '../ReasonStep';

import OfferIcon from './assets/icon.svg?react';
import { useOfferStepViewModel } from './useOfferStepViewModel';

import styles from './styles.module.scss';

const OfferStepLayout: FC<{
  title: ReactElement | string;
  description: ReactElement | string;
  disclaimer: ReactElement | string;
  getDiscountAction: {
    text: string;
    disabled?: boolean;
    onClick: () => void;
  };
  cancelSubscriptionAction: {
    text: string;
    disabled?: boolean;
    onClick: () => void;
  };
}> = ({
  title,
  description,
  disclaimer,
  getDiscountAction,
  cancelSubscriptionAction,
}) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.icon}>
        <OfferIcon />
      </Box>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}>{description}</p>
      <Button
        variant="contained"
        color="gradientOrangeToRed"
        className={styles.getDiscountAction}
        onClick={getDiscountAction.onClick}
        disabled={getDiscountAction.disabled ?? false}
      >
        {getDiscountAction.text}
      </Button>
      <p className={styles.disclaimer}>{disclaimer}</p>
      <Button
        variant="text"
        color="primary"
        className={styles.cancelSubscriptionAction}
        onClick={cancelSubscriptionAction.onClick}
        disabled={cancelSubscriptionAction.disabled ?? false}
      >
        {cancelSubscriptionAction.text}
      </Button>
    </Box>
  );
};

export const OfferStep: FC<{
  reason: Reason;
  onClose: () => void;
}> = ({ reason, onClose }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.cancelSubscriptionModal.offerStep',
  });

  const { billingCycle, getDiscount, cancelSubscription } = useOfferStepViewModel({
    onClose,
  });

  switch (reason) {
    case 'tooExpensive':
      return (
        <OfferStepLayout
          title={t('tooExpensive.title')}
          description={
            <Trans t={t} i18nKey="tooExpensive.description" components={{ b: <b /> }} />
          }
          disclaimer={
            billingCycle.data ? t(`tooExpensive.disclaimer.${billingCycle.data}`) : ''
          }
          getDiscountAction={{
            text: t('tooExpensive.actions.getDiscount'),
            onClick: getDiscount,
            disabled: cancelSubscription.isLoading,
          }}
          cancelSubscriptionAction={{
            text: t('tooExpensive.actions.cancelSubscription'),
            onClick: cancelSubscription.asyncExecute,
            disabled: cancelSubscription.isLoading,
          }}
        />
      );
    case 'foundAlternative':
      return (
        <OfferStepLayout
          title={t('foundAlternative.title')}
          description={
            <Trans
              t={t}
              i18nKey="foundAlternative.description"
              components={{
                b: <b />,
              }}
            />
          }
          disclaimer={
            billingCycle.data ? t(`foundAlternative.disclaimer.${billingCycle.data}`) : ''
          }
          getDiscountAction={{
            text: t('foundAlternative.actions.getDiscount'),
            onClick: getDiscount,
            disabled: cancelSubscription.isLoading,
          }}
          cancelSubscriptionAction={{
            text: t('foundAlternative.actions.cancelSubscription'),
            onClick: cancelSubscription.asyncExecute,
            disabled: cancelSubscription.isLoading,
          }}
        />
      );
    case 'nonUserFriendly':
      return (
        <OfferStepLayout
          title={t('nonUserFriendly.title')}
          description={
            <Trans
              t={t}
              i18nKey="nonUserFriendly.description"
              components={{
                a: <a target="_blank" href={EXTERNAL_ROUTES.REQUEST_DEMO} />,
              }}
            />
          }
          disclaimer={
            billingCycle.data ? t(`nonUserFriendly.disclaimer.${billingCycle.data}`) : ''
          }
          getDiscountAction={{
            text: t('nonUserFriendly.actions.getDiscount'),
            onClick: getDiscount,
            disabled: cancelSubscription.isLoading,
          }}
          cancelSubscriptionAction={{
            text: t('nonUserFriendly.actions.cancelSubscription'),
            onClick: cancelSubscription.asyncExecute,
            disabled: cancelSubscription.isLoading,
          }}
        />
      );
    case 'onlyNeededForShortTerm':
      return (
        <OfferStepLayout
          title={t('onlyNeededForShortTerm.title')}
          description={
            <Trans
              t={t}
              i18nKey="onlyNeededForShortTerm.description"
              components={{
                b: <b />,
              }}
            />
          }
          disclaimer={
            billingCycle.data
              ? t(`onlyNeededForShortTerm.disclaimer.${billingCycle.data}`)
              : ''
          }
          getDiscountAction={{
            text: t('onlyNeededForShortTerm.actions.getDiscount'),
            onClick: getDiscount,
            disabled: cancelSubscription.isLoading,
          }}
          cancelSubscriptionAction={{
            text: t('onlyNeededForShortTerm.actions.cancelSubscription'),
            onClick: cancelSubscription.asyncExecute,
            disabled: cancelSubscription.isLoading,
          }}
        />
      );
    default:
      return (
        <OfferStepLayout
          title={t('other.title')}
          description={
            <Trans
              t={t}
              i18nKey="other.description"
              components={{
                b: <b />,
              }}
            />
          }
          disclaimer={billingCycle.data ? t(`other.disclaimer.${billingCycle.data}`) : ''}
          getDiscountAction={{
            text: t('other.actions.getDiscount'),
            onClick: getDiscount,
            disabled: cancelSubscription.isLoading,
          }}
          cancelSubscriptionAction={{
            text: t('other.actions.cancelSubscription'),
            onClick: cancelSubscription.asyncExecute,
            disabled: cancelSubscription.isLoading,
          }}
        />
      );
  }
};
