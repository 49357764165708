import { ReactNode, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { useRedirectAfterLogin } from '@/router/hooks';
import { ROUTES } from '@/router/routes';

import { COUNTRIES_DC, useAccount, useAccountUseCase } from '@/features/common/account';
import {
  AuthIdentityProvider,
  InvalidEmailError,
  NonUniquePhoneNumberError,
  useAuthUseCase,
  useProvider,
  UserAlreadyExistsError,
} from '@/features/common/auth';
import { useExtensionState } from '@/features/common/extension';
import { useAccountSettingsUseCase } from '@/features/settings/features/account/ui';
import { useSignUpUseCase } from '@/features/signup';
import { useAnalytics } from '@/features/system/analytics';
import { useGetDeviceCountry } from '@/features/system/geoSync';

import { useBehaviorSubject } from '@/utils/rx';
import {
  NameValidationSchema,
  PasswordValidationSchema,
  PhoneValidationSchema,
  useFormWithSchema,
  WorkEmailValidationSchema,
} from '@/utils/validation';

import styles from './styles.module.scss';

const CreateAccountSchema = yup.object({
  email: WorkEmailValidationSchema,
  fullName: NameValidationSchema,
  phone: PhoneValidationSchema,
  country: yup.mixed<COUNTRIES_DC>().oneOf(Object.values(COUNTRIES_DC)),
  password: yup.lazy((value) => {
    if (value !== undefined) {
      return PasswordValidationSchema;
    }
    return yup.string().notRequired();
  }),
});

type CreateAccountFormType = yup.InferType<typeof CreateAccountSchema>;

type UseCreateAccountViewModel = () => {
  error?: { type: string; text: ReactNode };
  form: UseFormReturn<CreateAccountFormType>;
  disabled: boolean;
  onSubmit: () => void;
  onEmailChange: () => void;
  onPhoneChange: (phone: string) => void;
  onCountryChange: (country: string) => void;
};

const useCreateAccountViewModel: UseCreateAccountViewModel = () => {
  const [_, redirectAfterLogin] = useRedirectAfterLogin();
  const signUpUseCases = useSignUpUseCase();
  const accountSettingsUseCase = useAccountSettingsUseCase();
  const accountUseCase = useAccountUseCase();
  const authUseCase = useAuthUseCase();
  const snackbar = useSnackbar();

  const [emailForAccountCreation] = useBehaviorSubject(
    accountUseCase.getEmailForAccountCreation(),
  );

  const { isInstalled: isExtensionInstalled } = useExtensionState();

  const { t } = useTranslation('auth');
  const provider = useProvider();
  const { account } = useAccount();
  const [error, setError] = useState<{ type: string; text: ReactNode } | undefined>();
  const navigate = useNavigate();
  const {
    trackViewCreateAccountPage,
    trackSignupCompleteWithProvider,
    trackSignupCompleteWithEmail,
  } = useAnalytics();
  const hasAccount = !!account;
  const country = useGetDeviceCountry();
  const form = useFormWithSchema(CreateAccountSchema, {
    defaultValues: {
      email: account?.email ?? emailForAccountCreation,
      fullName: account?.fullName ?? '',
      phone: account?.settings.phone ?? '',
      country: country as COUNTRIES_DC,
      ...(!Boolean(hasAccount && provider) && { password: '' }),
    },
  });

  useEffect(() => {
    if (hasAccount) {
      form.reset({
        email: account.email,
        fullName: account?.fullName ?? '',
        phone: account.settings?.phone ?? '',
        country: account.settings?.country ?? (country as COUNTRIES_DC),
      });
    }
  }, [account?.uuid]);

  const handleErrorOfCreateAccount = (error: unknown): void => {
    switch (true) {
      case error instanceof UserAlreadyExistsError:
        setError({
          type: 'email',
          text: (
            <Trans t={t} i18nKey={'signup.errors.exist'}>
              This email is already exist.
              <Link to={ROUTES.LOGIN} className={styles.linkCreate}>
                Sign In now
              </Link>
            </Trans>
          ),
        });
        break;
      case error instanceof InvalidEmailError:
        setError({
          type: 'email',
          text: t('signup.errors.validEmail'),
        });
        break;
      case error instanceof NonUniquePhoneNumberError:
        setError({
          type: 'phone',
          text: (
            <Trans
              t={t}
              i18nKey={'signup.errors.invalidPhone'}
              components={{
                a: (
                  <Link
                    to={ROUTES.LOGIN}
                    className={styles.linkCreate}
                    onClick={async (): Promise<void> => {
                      if (!hasAccount) {
                        accountUseCase.deleteEmailForAccountCreation();
                      }

                      await authUseCase.signOut();
                      navigate(ROUTES.LOGIN);
                    }}
                  />
                ),
              }}
            >
              This phone number is already registered.
              <Link to={ROUTES.LOGIN} className={styles.linkCreate}>
                Login
              </Link>
            </Trans>
          ),
        });
        break;
      default:
        snackbar.enqueueSnackbar({
          variant: 'error',
          message: 'Can not complete account setup',
          description: error instanceof Error ? error.message : 'Unknown error',
        });
        break;
    }
  };

  useEffect(() => {
    trackViewCreateAccountPage();

    const handleHistoryChange = (): void => {
      accountUseCase.deleteEmailForAccountCreation();
    };

    window.addEventListener('popstate', handleHistoryChange);

    return (): void => {
      window.removeEventListener('popstate', handleHistoryChange);
    };
  }, []);

  const onPhoneChange = (phone: string): void => {
    form.setValue('phone', phone, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const onCountryChange = (country: string): void => {
    form.setValue('country', country as COUNTRIES_DC, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const onEmailChange = async (): Promise<void> => {
    if (hasAccount) {
      await accountSettingsUseCase.deleteAccount();
      navigate(ROUTES.SIGN_UP);
      return;
    }

    accountUseCase.deleteEmailForAccountCreation();
    await authUseCase.signOut();
    navigate(ROUTES.SIGN_UP);
  };

  const onSetupAccountWithoutCredentials = async (payload: {
    info: {
      displayName: string;
      phoneNumber: string;
      country: COUNTRIES_DC;
    };
  }): Promise<void> => {
    try {
      await signUpUseCases.setupAccount(payload);

      if (provider && provider !== AuthIdentityProvider.password) {
        trackSignupCompleteWithProvider(provider);
      }
    } catch (error) {
      handleErrorOfCreateAccount(error);
    }
  };

  const onSetupAccountWithCredentials = async ({
    info,
    credentials,
  }: {
    info: {
      displayName: string;
      phoneNumber: string;
      country: COUNTRIES_DC;
    };
    credentials: {
      email: string;
      password: string;
    };
  }): Promise<void> => {
    try {
      await signUpUseCases.setupAccount({
        info,
        credentials,
      });

      trackSignupCompleteWithEmail();
    } catch (error) {
      handleErrorOfCreateAccount(error);
    }
  };

  const onSubmit = (): void => {
    void form.handleSubmit(
      async ({ fullName, country, phone, email, password }: CreateAccountFormType) => {
        const info = {
          displayName: fullName,
          phoneNumber: phone,
          country: country || COUNTRIES_DC.US,
        };

        if (!isExtensionInstalled) {
          redirectAfterLogin(ROUTES.ONBOARDING_INSTALL_EXTENSION);
        }

        if (hasAccount && provider) {
          await onSetupAccountWithoutCredentials({ info });
        } else {
          if (password)
            await onSetupAccountWithCredentials({
              info,
              credentials: { email, password },
            });
        }
      },
    )();
  };

  return {
    onSubmit: onSubmit,
    error,
    form,
    disabled: form.formState.isSubmitting,
    onEmailChange,
    onPhoneChange,
    onCountryChange,
  };
};

export default useCreateAccountViewModel;
