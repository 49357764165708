import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';

import { ROUTES } from '@/router/routes';

import { BillingError, PaymentError, useBillingUseCase } from '@/features/common/billing';
import { useSubscriptionUseCase } from '@/features/common/workspace';
import { useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';
import { NetworkError } from '@/features/system/network';

import { useDocumentMeta } from '@/hooks';

import { PromotionExpiredError } from '../domain/errors/PromotionExpiredError';

import { PaymentDetailsContext, PaymentFormValues } from './contexts';
import {
  PaymentFailedReason,
  useCanBuy,
  usePaymentDetailsAnalytic,
  usePaymentErrorSnackbar,
  usePaymentSummary,
} from './hooks';

export interface IPaymentDetailsViewModel {
  summary: ReturnType<typeof usePaymentSummary>;
  isProcessing: boolean;
  onSubmit: () => Promise<void>;
}

export const usePaymentDetailsViewModel = (): IPaymentDetailsViewModel => {
  useDocumentMeta({
    title: 'payment.title',
    description: 'payment.description',
  });

  const { t } = useTranslation('paymentDetails');

  const { targetProduct, currentSubscription } = useContext(PaymentDetailsContext);
  const form = useFormContext<PaymentFormValues>();

  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const analytic = usePaymentDetailsAnalytic();
  const { trackFbPixelEvent } = useAnalytics();

  useEffect(() => {
    analytic.pageViewed();
  }, []);

  const billingUseCase = useBillingUseCase();
  const subscriptionUseCase = useSubscriptionUseCase();
  const analytics = useAnalytics();

  const logger = useAppLogger();

  const summary = usePaymentSummary();

  const canBuy = useCanBuy();

  const errorSnackbar = usePaymentErrorSnackbar();

  async function assertPaymnetMethodIsPresent(params: {
    elements?: Nullable<StripeElements>;
    formValues: PaymentFormValues;
  }): Promise<void> {
    const { elements, formValues } = params;

    // we have saved payment method, so we don't need to submit the form
    if (formValues.paymentMethod) return;

    if (!elements) {
      throw PaymentError.paymentProviderLoadingError();
    }

    const result = await elements.submit();

    if (result.error) {
      throw PaymentError.fromStripeError(result.error);
    }
  }

  async function confirmPayment(params: {
    stripe: Stripe;
    elements: Nullable<StripeElements>;
    formValues: PaymentFormValues;
    secret: string;
  }): Promise<void> {
    const { elements, formValues, secret, stripe } = params;

    if (!secret) return;

    if (formValues.paymentMethod) {
      const { error } = await stripe.confirmPayment({
        clientSecret: secret,
        confirmParams: {
          return_url: `${window.location.origin}${ROUTES.SETTINGS.SUBSCRIPTION}`,
          payment_method: formValues.paymentMethod.id,
          payment_method_data: {
            billing_details: {
              address: {
                country: formValues.billingDetails.country,
                postal_code: formValues.billingDetails.postalCode,
              },
              email: formValues.billingDetails.email,
            },
            allow_redisplay: 'always',
          },
        },
      });

      if (error) {
        throw PaymentError.fromStripeError(error);
      }

      return;
    }

    if (!elements) {
      throw PaymentError.paymentProviderLoadingError();
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: secret,
      confirmParams: {
        return_url: `${window.location.origin}${ROUTES.SETTINGS.SUBSCRIPTION}`,
        payment_method_data: {
          billing_details: {
            address: {
              country: formValues.billingDetails.country,
              postal_code: formValues.billingDetails.postalCode,
            },
            email: formValues.billingDetails.email,
          },
          allow_redisplay: 'always',
        },
        save_payment_method: true,
      },
      redirect: 'if_required',
    });

    if (error) {
      throw PaymentError.fromStripeError(error);
    }
  }

  const createSubscription = async (
    formValues: PaymentFormValues,
    stripe: Stripe,
  ): Promise<void> => {
    if (summary.receipt.data.total < 0.5) {
      throw PaymentError.minimumAmountError();
    }

    await billingUseCase.updateBillingDetails(formValues.billingDetails);

    const { secret } = await subscriptionUseCase.create({
      plan: targetProduct.id,
      billingDetailsFilled: true,
      promoCode: formValues.promotionCode,
      quantity: formValues.quantity,
    });

    if (secret) {
      await confirmPayment({ stripe, elements, formValues, secret });
    }

    await subscriptionUseCase.confirmPlanChange(targetProduct.id);

    analytic.paymentSuccess({
      name: targetProduct.id,
    });

    trackFbPixelEvent('Purchase', {
      value: summary.receipt.data.total.toString(),
      currency: summary.receipt.data.currency,
    });

    navigate(ROUTES.SETTINGS.SUBSCRIPTION, { state: { justSubscribed: true } });
  };

  const updateSubscription = async (
    formValues: PaymentFormValues,
    stripe: Stripe,
  ): Promise<void> => {
    await billingUseCase.updateBillingDetails(formValues.billingDetails);

    const { secret, paid } = await subscriptionUseCase.update({
      plan: targetProduct.id,
      billingDetailsFilled: true,
      promoCode: formValues.promotionCode,
      quantity: formValues.seats,
    });

    if (!paid) {
      if (!secret) {
        throw new PaymentError('Client secret is missing');
      }

      if (summary.receipt.data.total < 0.5) {
        throw PaymentError.minimumAmountError();
      }

      await confirmPayment({ formValues, stripe, elements, secret });
    }

    await subscriptionUseCase.confirmPlanChange(targetProduct.id);

    analytic.paymentSuccess({
      name: targetProduct.id,
    });

    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
  };

  const handlePaymentError = (error: unknown): void => {
    logger.error(error);

    switch (true) {
      case error instanceof PromotionExpiredError:
        summary.onPromotionCodeRemove();
        errorSnackbar.show(t('promotionCode.expired'));
        analytic.paymentFailed(targetProduct.id, PaymentFailedReason.ServerError);
        break;
      case error instanceof NetworkError:
        errorSnackbar.show(error.response?.data?.message ?? error.message);
        analytic.paymentFailed(targetProduct.id, PaymentFailedReason.ServerError);
        break;
      case error instanceof PaymentError:
        errorSnackbar.show(error.message);
        analytic.paymentFailed(targetProduct.id, PaymentFailedReason.CardDetails);
        return;
      case error instanceof BillingError:
      case error instanceof Error:
        errorSnackbar.show(error.message);
        analytic.paymentFailed(targetProduct.id, PaymentFailedReason.ServerError);
        break;
      default:
        errorSnackbar.show();
        analytic.paymentFailed(targetProduct.id, PaymentFailedReason.ServerError);
        break;
    }
  };

  const getBillingCycleChange = (): Nullable<string> => {
    if (currentSubscription.planIsFree) return;
    if (currentSubscription.billingCycle === targetProduct.cycle) return;

    switch (targetProduct.cycle) {
      case 'annually':
        return 'Change to yearly';
      case 'monthly':
        return 'Change to monthly';
      case 'daily':
        return 'Change to daily';
    }
  };

  const handleSubmit = form.handleSubmit(
    async (formValues) => {
      analytic.clickSubmitPurchase();
      try {
        const allowedToBuy = await canBuy();

        if (!allowedToBuy) throw new Error('You can not buy this product');

        if (!stripe) {
          throw PaymentError.paymentProviderLoadingError();
        }

        await assertPaymnetMethodIsPresent({ elements, formValues });

        const isUpgradable = await firstValueFrom(subscriptionUseCase.isUpgradable());

        const newBillingCycle = getBillingCycleChange();

        if (isUpgradable) {
          await updateSubscription(formValues, stripe);
        } else {
          await createSubscription(formValues, stripe);
        }

        if (newBillingCycle) {
          analytics.trackChangeSubscription(newBillingCycle);
        }
      } catch (error) {
        handlePaymentError(error);
      }
    },
    async () => {
      if (!form.formState.isValid) {
        analytic.paymentFailed(targetProduct.id, PaymentFailedReason.EmptyFields);
      }
    },
  );

  return {
    summary,
    isProcessing: form.formState.isSubmitting,
    onSubmit: handleSubmit,
  };
};
