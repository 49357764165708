import { inject, injectable, injectFromBase } from 'inversify';
import { RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types';
import { combineLatest, first, firstValueFrom, map, of, switchMap } from 'rxjs';

import { CONTACT_LIST_TYPES, CONTACT_TYPES, TAG_TYPES } from '@/ioc/types';

import { CollectionName, ContactDocument } from '@/features/system/db';
import {
  IReplicationService,
  ReplicationService,
} from '@/features/system/replication/data/ReplicationService';

import { IContactSyncRepository } from '../../domain';
import { IContactApiService } from '../network/ContactApiSevices';

import { IContactDao } from './dao';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class ContactReplicationService extends ReplicationService<ContactDocument> {
  @inject(CONTACT_TYPES.ContactApiService)
  private apiService: IContactApiService;

  @inject(CONTACT_TYPES.ContactSyncRepository)
  private syncRepository: IContactSyncRepository;

  @inject(TAG_TYPES.TagReplicationService)
  private tagReplicationService: IReplicationService;

  @inject(CONTACT_LIST_TYPES.ContactListReplicationService)
  private contactListReplicationService: IReplicationService;

  @inject(CONTACT_TYPES.ContactDao)
  private contactDao: IContactDao;

  constructor() {
    super({
      collectionName: CollectionName.Contact,
      pullStreamFactory: () => {
        return this.syncRepository.getEvent().pipe(
          switchMap(({ event }) => {
            return combineLatest([
              this.contactDao
                .findByIds(event.contacts.map((contact) => contact.uuid))
                .pipe(first()),
              of(event.contacts),
            ]);
          }),
          map(([currentContact, eventContacts]) => {
            return eventContacts.filter((contact) => {
              const existedContact = currentContact.find((c) => c.uuid === contact.uuid);
              if (existedContact) return existedContact.updated_at <= contact.updated_at;

              return true;
            });
          }),
          map((contacts) => ({
            documents: contacts as ContactDocument[],
            checkpoint: { resume_token: null },
          })),
        );
      },
      pushHandlers: {
        update: (docs) => this.syncUpdate(docs),
      },
    });
  }

  override canPush(): boolean {
    return (
      super.canPush() &&
      !(
        this.tagReplicationService.isActive() ||
        this.contactListReplicationService.isActive()
      )
    );
  }

  private syncUpdate(
    docsToUpdate: RxReplicationWriteToMasterRow<ContactDocument>[],
  ): Promise<ContactDocument[]> {
    return firstValueFrom(
      this.apiService
        .updateContacts(docsToUpdate.map((doc) => doc.newDocumentState))
        .pipe(
          map((contacts) => {
            return contacts.map((contact) => ({ ...contact, _deleted: false }));
          }),
        ),
    );
  }
}
