import type { IUpcomingInvoiceEntity } from '@/features/common/billing';

import {
  FixedReceiptAdjustment,
  type IReceiptAdjustmentEntity,
  type IUpcomingReceiptEntity,
} from '../../domain';

const PREVIOUS_PLAN_ADJ_RETURN_DESCRIPTION = 'Unused time on'; // discount in case of upgrade
const PRORATED_ADJ_DESCRIPTION = 'Remaining time on';

const toInteranlAmount = (amount: number): number => amount / 100;

export const mapUpcomingReceipt = (
  upcomingInvoice: IUpcomingInvoiceEntity,
  options?: {
    promoCode?: string;
  },
): IUpcomingReceiptEntity => {
  const promoCode = options?.promoCode;

  const adjustments: IReceiptAdjustmentEntity[] = [];

  const prevPlanRefund = upcomingInvoice.lines.data.find((line) =>
    line.description.includes(PREVIOUS_PLAN_ADJ_RETURN_DESCRIPTION),
  );

  if (prevPlanRefund) {
    adjustments.push(
      new FixedReceiptAdjustment({
        title: 'Previous plan refund',
        amount: Math.abs(toInteranlAmount(prevPlanRefund.amount)),
        type: 'discount',
      }),
    );
  }

  const prorated =
    upcomingInvoice.lines.data.find((line) =>
      line.description.includes(PRORATED_ADJ_DESCRIPTION),
    ) ?? upcomingInvoice.lines.data[1];

  if (prorated) {
    const planPrice =
      (prorated.plan.amount ?? prorated.price.tiers?.at(0)?.flat_amount ?? 0) *
      prorated.quantity;
    const planPriceToPay = prorated.amount;

    const proratedDiscount = planPrice - planPriceToPay;

    adjustments.push(
      new FixedReceiptAdjustment({
        title: `Prorated discount`,
        amount: Math.abs(toInteranlAmount(proratedDiscount)),
        type: 'discount',
      }),
    );
  }

  let promocodeAdj: IReceiptAdjustmentEntity | undefined;

  if (promoCode && upcomingInvoice.discount?.promotion_code === promoCode) {
    const discount = upcomingInvoice.discount;
    const discountAmount =
      upcomingInvoice.total_discount_amounts.find((d) => d.discount === discount.id)
        ?.amount ?? 0;

    if (discountAmount) {
      promocodeAdj = new FixedReceiptAdjustment({
        id: promoCode,
        title: discount.coupon.name,
        amount: toInteranlAmount(discountAmount),
        type: 'discount',
        isApplyable: true,
      });
    }
  }

  return {
    product: {
      amount: toInteranlAmount(
        prorated?.plan.amount ??
          upcomingInvoice.lines.data.find((d) => !d.proration)?.plan.amount ??
          0,
      ),
    },
    currency: upcomingInvoice.currency,
    adjustments,
    dueDate: upcomingInvoice.next_payment_attempt
      ? new Date(upcomingInvoice.next_payment_attempt * 1000)
      : new Date(),
    promoCode: promocodeAdj,
    total: toInteranlAmount(upcomingInvoice.total),
  };
};
