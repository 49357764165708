import { injectable, injectFromBase } from 'inversify';

import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import { ICompanyDC } from '../../dataContracts';

export type ICompanyDao = IDbCollection<ICompanyDC, 'company_id'>;

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class CompanyDao
  extends DbCollection<ICompanyDC, 'company_id'>
  implements ICompanyDao
{
  constructor() {
    super({ collectionName: CollectionName.Company, idKey: 'company_id' });
  }
}
