import { FC, useCallback, useContext, useMemo } from 'react';
import type { GridColDef } from '@mui/x-data-grid-pro';

import type { IContactEntity } from '@/features/common/contact';
import { IContactListEntity } from '@/features/common/contactList';

import { ContactsContext } from '../../context';

import {
  DefaultListNoResultsOverlay,
  NoFilterResultsOverlay,
  NoResultsOverlay,
} from './components';
import { useTableColumns } from './hooks';
import { ContactsTableSortItem } from './types';

enum NoResultOverlayKeys {
  NoFilterResultsOverlay,
  DefaultListNoResultsOverlay,
  NoResultsOverlay,
}

type UseContactsTableViewModelResult = {
  page: number;
  columns: GridColDef<IContactEntity>[];
  rows: IContactEntity[];
  pageSize: number;
  pagesCount: number;
  noResultsOverlay: FC;
  contactEntitiesCount: number;
  isLoading: boolean;
  handlePaginationChange: (e: any, newPage: number) => void;
  handleRowSelectionModelChange: (selected: any) => void;
  handleSortModelChange: (sortData: any) => void;
};

const NoResultOverlaysMapByKey = {
  [NoResultOverlayKeys.NoFilterResultsOverlay]: NoFilterResultsOverlay,
  [NoResultOverlayKeys.DefaultListNoResultsOverlay]: DefaultListNoResultsOverlay,
  [NoResultOverlayKeys.NoResultsOverlay]: NoResultsOverlay,
};

export const useContactsTableViewModel = ({
  currentContactList,
  defaultContactList,
}: {
  currentContactList: IContactListEntity;
  defaultContactList: IContactListEntity;
}): UseContactsTableViewModelResult => {
  const {
    contacts,
    setSort,
    setSelectedContacts,
    page,
    pageSize,
    setPage,
    filtersIsApplied,
  } = useContext(ContactsContext);
  const getNoResultsOverlayKey = (): NoResultOverlayKeys => {
    const isDefaultContactList = currentContactList.uuid === defaultContactList.uuid;

    if (isDefaultContactList && !filtersIsApplied) {
      return NoResultOverlayKeys.DefaultListNoResultsOverlay;
    }

    if (filtersIsApplied) {
      return NoResultOverlayKeys.NoFilterResultsOverlay;
    }

    return NoResultOverlayKeys.NoResultsOverlay;
  };
  const columns = useTableColumns();

  const noResultsOverlay = useMemo(() => {
    return NoResultOverlaysMapByKey[getNoResultsOverlayKey()];
  }, [contacts.isLoading]);

  const handleSortModelChange = useCallback((sortData: ContactsTableSortItem[]): void => {
    setSort(sortData[0]);
  }, []);

  const handleRowSelectionModelChange = useCallback((selected): void => {
    setSelectedContacts(selected);
  }, []);

  const handlePaginationChange = useCallback((_, newPage): void => {
    setPage(newPage);
  }, []);

  return {
    page,
    columns,
    rows: contacts.data.entities || [],
    pageSize: pageSize,
    pagesCount: Math.max(1, Math.ceil(contacts.data.totalCount / pageSize)),
    noResultsOverlay,
    contactEntitiesCount: contacts.data.totalCount,
    isLoading: contacts.isLoading,
    handlePaginationChange,
    handleRowSelectionModelChange,
    handleSortModelChange,
  };
};
