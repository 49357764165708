import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { useAnalytics } from '@/features/system/analytics';

import { Preloader } from '@/components';

import styles from './styles.module.scss';

type Action = () => Promise<unknown> | unknown;

type PaymentActionsProps = {
  status: 'active' | 'canceled' | 'suspended' | 'grace';
  onCancel: Action;
  onResubsribe: Action;
  isGift: boolean;
};

export const PaymentActions: FC<PaymentActionsProps> = ({
  status,
  onCancel,
  onResubsribe,
  isGift,
}) => {
  const { t } = useTranslation('billingSettings', { keyPrefix: 'paymentDetails' });
  const analytics = useAnalytics();

  const [isProcessing, setIsProcessing] = useState(false);

  const withIsProcessing = (action: Action) => {
    return async () => {
      setIsProcessing(true);
      try {
        await action();
      } finally {
        setIsProcessing(false);
      }
    };
  };

  const handleCancelSubscriptionClick = async (): Promise<void> => {
    await onCancel();
    analytics.trackCancelSubscriptionButtonClick();
  };

  switch (status) {
    case 'active':
      return isGift ? null : (
        <Button
          variant="text"
          size="small"
          color="info"
          onClick={withIsProcessing(handleCancelSubscriptionClick)}
          disabled={isProcessing}
        >
          <Typography
            variant="textInButton"
            fontSize={12}
            fontWeight={500}
            className={styles.cancelSubscription}
          >
            {t('cancelSubscription')}
          </Typography>
        </Button>
      );
    case 'canceled':
    case 'suspended':
      return (
        <Button
          variant="contained"
          size="small"
          color="gradientOrangeToRed"
          onClick={withIsProcessing(onResubsribe)}
          disabled={isProcessing || isGift}
        >
          <Typography
            variant="textInButton"
            fontSize={12}
            fontWeight={500}
            className={styles.resubscribe}
          >
            {isProcessing ? <Preloader /> : t('resubscribe')}
          </Typography>
        </Button>
      );
    default:
      return null;
  }
};
