import { injectable } from 'inversify';

import { IAnalyticRepository } from '../domain';

export interface IGoogleTagManagerRepository
  extends IAnalyticRepository<Record<string, unknown>> {}

@injectable()
export class GoogleTagManagerRepository implements IGoogleTagManagerRepository {
  public trackEvent(eventType: string, eventOptions?: Record<string, unknown>): void {
    if ('dataLayer' in window && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({
        event: eventType,
        ...eventOptions,
      });
    } else {
      console.warn('[GoogleTagManagerRepository]: GTM dataLayer is not available');
    }
  }
}
