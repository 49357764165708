import { useContext } from 'react';

import { useAppLogger } from '@/features/system/logger';

import { useAsyncExecutor, UseAsyncExecutorResult } from '@/hooks';

import { EnrichmentUploadingContext } from '../../../../EnrichmentUploadingContext';
import { useEnrichmentUseCase } from '../../../../hooks';

export const useUserAccessDeniedModalViewModel = (): UseAsyncExecutorResult<boolean> => {
  const enrichmentUseCase = useEnrichmentUseCase();
  const { uploadAccessDeniedModal } = useContext(EnrichmentUploadingContext);
  const logger = useAppLogger();

  const contactAdmin = async (): Promise<boolean> => {
    try {
      const result = await enrichmentUseCase.contactAdmin();
      uploadAccessDeniedModal.onClose();
      return result;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  return useAsyncExecutor(contactAdmin);
};
