import { injectable, injectFromBase } from 'inversify';

import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import type { IWorkspaceDC } from '../../dataContracts';

export type IWorkspaceDao = IDbCollection<IWorkspaceDC, 'uuid'>;

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export default class WorkspaceDao
  extends DbCollection<IWorkspaceDC, 'uuid'>
  implements IWorkspaceDao
{
  constructor() {
    super({ collectionName: CollectionName.Workspace, idKey: 'uuid' });
  }
}
