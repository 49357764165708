import { FC, useState } from 'react';

import { SelectReason } from './components/SelectReason';
import { SpecifyReason } from './components/SpecifyReason';
import { type Reason } from './types';

export const ReasonStep: FC<{
  onClose: () => void;
  onGoToNextStep: (reason: Reason, specificReason?: string) => void;
}> = ({ onClose, onGoToNextStep }) => {
  const [activeReason, setActiveReason] = useState<Reason | null>(null);

  if (activeReason === 'other') {
    return (
      <SpecifyReason
        onClose={onClose}
        onGoToNextStep={(value): void => onGoToNextStep('other', value)}
      />
    );
  }

  return (
    <SelectReason
      value={activeReason}
      onSelect={setActiveReason}
      onClose={onClose}
      onGoToNextStep={(): void => {
        if (!activeReason) return;

        onGoToNextStep(activeReason);
      }}
    />
  );
};
