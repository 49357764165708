import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, OutlinedInput } from '@mui/material';

import styles from './styles.module.scss';

export const SpecifyReason: FC<{
  onClose: () => void;
  onGoToNextStep: (value: string) => void;
}> = ({ onClose, onGoToNextStep }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.cancelSubscriptionModal.reasonStep.specifyView',
  });
  const [value, setValue] = useState('');

  return (
    <Box className={styles.wrapper}>
      <h4 className={styles.title}>{t('title')}</h4>
      <p className={styles.description}>
        <Trans t={t} i18nKey="description" components={{ span: <span /> }} />
      </p>
      <OutlinedInput
        onChange={(e): void => setValue(e.target.value)}
        value={value}
        className={styles.inputWrapper}
        classes={{
          input: styles.input,
        }}
        placeholder={t('input.placeholder')}
        maxRows={4}
        multiline
      />
      <Box className={styles.actions}>
        <Button variant="text" color="primary" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(): void => {
            onGoToNextStep(value);
          }}
          disabled={!value}
        >
          {t('buttons.next')}
        </Button>
      </Box>
    </Box>
  );
};
