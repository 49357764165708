import { useState } from 'react';

import { useAnalytics } from '@/features/system/analytics';

import { type Reason } from './components/ReasonStep';

type DefaultStep = {
  viewType: 'default';
};

type ReasonStep = {
  viewType: 'reason';
};

type OfferStep = {
  viewType: 'offer';
  state: {
    reason: Reason;
    specificReason?: string;
  };
};

type Step = DefaultStep | ReasonStep | OfferStep;

export const useCancelSubscriptionViewModel = () => {
  const analytics = useAnalytics();

  const [step, setStep] = useState<Step>({
    viewType: 'default',
  });

  const goToReasonStep = (): void => {
    setStep({
      viewType: 'reason',
    });
  };

  const goToOfferStep = (reason: Reason, specificReason?: string): void => {
    setStep({
      viewType: 'offer',
      state: { reason, specificReason },
    });
    analytics.trackChooseCancelSubscriptionReason(reason);
  };

  return {
    step,
    goToReasonStep,
    goToOfferStep,
  };
};
