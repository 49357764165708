import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';
import { Box, Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import { ROUTES } from '@/router/routes';

import { CallToAction } from '@/features/callToAction';
import { FeatureEnabled } from '@/features/system/featureFlag';

import { LogoBase } from '@/components/Icons';
import { Logo } from '@/components/Icons';

import { CreditUsage } from './components/CreditUsage';
import { UserMenuContainer } from './components/UserMenu/UserMenuContainer';
import { HeaderGuideContainer } from './components';
import { useHeaderViewModel } from './HeaderViewModel';

import styles from './styles.module.scss';

export const EmptyHeader: FC = () => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.leftSide}>
        <NavLink to={ROUTES.DASHBOARD}>
          <div className={styles.logo}>
            <Logo />
          </div>
        </NavLink>
      </div>
    </header>
  );
};

export const Header: FC = () => {
  const { t } = useTranslation(['common', 'plans']);

  const { userData } = useHeaderViewModel();

  return (
    <Box component="header" className={styles.wrapper}>
      <Box className={styles.leftSide}>
        <NavLink to={ROUTES.DASHBOARD}>
          <Box className={styles.logo}>
            <Logo />
            <LogoBase />
          </Box>
        </NavLink>
        <Box mr={1} />
        <Box className={styles.planBadge}>{userData.planName}</Box>
        <Box className={styles.leftDivider} />
        <Box className={styles.headerVerticalDivider} mr={3.75} />
        <Box component="nav" className={styles.navigation}>
          <NavLink to={ROUTES.DASHBOARD}>
            <Typography variant="subtitle1" fontWeight={500}>
              {t('header.nav.dashboard')}
            </Typography>
          </NavLink>
          <NavLink to={ROUTES.CONTACTS_ENTRY}>
            <Typography variant="subtitle1" fontWeight={500}>
              {t('header.nav.savedContacts')}
            </Typography>
          </NavLink>
          <FeatureEnabled featureName="FEATURE_ENRICHMENT">
            <NavLink to={ROUTES.ENRICHMENT_ENTRY}>
              <Typography variant="subtitle1" fontWeight={500}>
                {t('header.nav.enrichment')}
              </Typography>
            </NavLink>
          </FeatureEnabled>
          <NavLink to={ROUTES.INTEGRATIONS}>
            <Typography variant="subtitle1" fontWeight={500}>
              {t('header.nav.integrations')}
            </Typography>
          </NavLink>
        </Box>
      </Box>
      <Box className={styles.rightSide}>
        <Stack direction="row" alignItems="center">
          <HeaderGuideContainer />
          <CreditUsage />
          <CallToAction
            variant="contained"
            color="gradientOrangeToRed"
            show={['getCredits']}
            className={styles.getCreditsButton}
          />
        </Stack>
        <Box className={clsx(styles.headerVerticalDivider, styles.dividerUserMenu)} />
        <UserMenuContainer userData={userData} />
      </Box>
    </Box>
  );
};
