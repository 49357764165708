import { injectable, injectFromBase } from 'inversify';

import { DbCollectionCreator } from '@/features/system/db';
import { CollectionName } from '@/features/system/db';

import { IContactDC } from '../dataContracts';

import { contactSchema } from './schema/contactSchema';
import { contactMigrationStrategies } from './migration';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class ContactCollectionCreator extends DbCollectionCreator<IContactDC> {
  constructor() {
    super({
      collectionName: CollectionName.Contact,
      schema: contactSchema,
      migrationStrategies: contactMigrationStrategies,
    });
  }
}
