import { FC } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';

export const PaymentMethods: FC = () => {
  return (
    <PaymentElement
      options={{
        layout: {
          type: 'tabs',
        },
        wallets: {
          applePay: 'auto',
          googlePay: 'auto',
        },
        paymentMethodOrder: ['card', 'google_pay', 'apple_pay', 'cash_app'],
        fields: {
          billingDetails: {
            address: {
              country: 'never',
              postalCode: 'never',
            },
          },
        },
      }}
    />
  );
};
