import type { StripeElementsOptions } from '@stripe/stripe-js';
import { inject, injectable } from 'inversify';
import { groupBy } from 'ramda';
import { map, Observable, switchMap } from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { ISubscriptionUseCase } from '@/features/common/workspace';

import type { IBillingRepository } from '../data';

import type { IBillingUseCase } from './abstractions';
import type {
  IBillingDetailsEntity,
  IBillingInvoiceEntity,
  IPaymentMethodEntity,
  IPaymentMethodUpdateSessionEntity,
  IProductEntity,
  IUpcomingInvoiceEntity,
} from './entities';
import type { BillingCycle, PlanType } from './types';

@injectable()
export class BillingUseCase implements IBillingUseCase {
  @inject(BILLING_TYPES.BillingRepository)
  private billingRepository: IBillingRepository;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  getPlanType(): Observable<PlanType> {
    return this.subscriptionUseCase
      .getSubscription()
      .pipe(map((subscription) => subscription.planType));
  }

  getBillingDetails = (): Observable<IBillingDetailsEntity> => {
    return this.billingRepository.getBillingDetails();
  };

  updateBillingDetails = (
    billingDetails: IBillingDetailsEntity,
  ): Promise<IBillingDetailsEntity> => {
    return this.billingRepository.updateBillingDetails(billingDetails);
  };

  getInvoices(): Observable<IBillingInvoiceEntity[]> {
    return this.subscriptionUseCase.getSubscription().pipe(
      switchMap(() => this.billingRepository.getInvoices()),
      map((invoices) => {
        return invoices.sort((a, b) => {
          const dataA = new Date(a.date);
          const dataB = new Date(b.date);
          return dataB.getTime() - dataA.getTime();
        });
      }),
    );
  }

  getPaymentMethod(): Observable<Nullable<IPaymentMethodEntity>> {
    return this.billingRepository.getPaymentMethod();
  }

  updatePaymentMethod(params: {
    successUrl: string;
    cancelUrl: string;
  }): Promise<IPaymentMethodUpdateSessionEntity> {
    return this.billingRepository.updatePaymentMethod(params);
  }

  getUpcomingInvoice(params: {
    plan: string;
    quantity?: number;
  }): Observable<IUpcomingInvoiceEntity> {
    return this.billingRepository.getUpcomingInvoice(params);
  }

  getProducts(): Observable<IProductEntity[]> {
    return this.billingRepository.getProducts();
  }

  getProduct(id: string): Observable<IProductEntity> {
    return this.billingRepository.getProduct(id);
  }

  getMaxAnnualDiscount(): Observable<number> {
    return this.getProducts().pipe(
      map((products) => {
        const groupedProducts = groupBy<IProductEntity>((item) => item.family)(products);

        const annaulDiscounts = Object.values(groupedProducts).map((products) => {
          const monthlyProduct = products?.find((product) => product.cycle === 'monthly');
          const annualProduct = products?.find((product) => product.cycle === 'annually');

          if (!monthlyProduct || !annualProduct) return 0;

          // can not compare prices with different price policies
          if (monthlyProduct.isPriceFixed !== annualProduct.isPriceFixed) return 0;

          const monthlyPrice = monthlyProduct.price;
          const annualPrice = annualProduct.price / 12;

          return Math.round(((monthlyPrice - annualPrice) / monthlyPrice) * 100);
        });

        return Math.max(...annaulDiscounts, 0);
      }),
    );
  }

  getAvailablePlanTypes(): Observable<PlanType[]> {
    return this.billingRepository.getPlansOrderMap().pipe(
      map((orderMap) => {
        return Object.entries(orderMap)
          .toSorted((a, b) => {
            const [, orderA = 0] = a;
            const [, orderB = 0] = b;

            return orderA - orderB;
          })
          .map(([planType]) => planType as PlanType);
      }),
    );
  }

  getProductComperator(): Observable<(a: IProductEntity, b: IProductEntity) => number> {
    return this.billingRepository.getPlansOrderMap().pipe(
      map((orderMap) => {
        return (a: IProductEntity, b: IProductEntity) => {
          const aOrder = orderMap[a.family] ?? 0;
          const bOrder = orderMap[b.family] ?? 0;

          if (aOrder > bOrder) return 1;
          if (aOrder < bOrder) return -1;

          const aCycle = BillingUseCase.getCycleTire(a.cycle);
          const bCycle = BillingUseCase.getCycleTire(b.cycle);

          if (aCycle > bCycle) return 1;
          if (aCycle < bCycle) return -1;

          return 0;
        };
      }),
    );
  }

  getStripeOptions(): Observable<StripeElementsOptions> {
    return this.billingRepository.getStripeOptions();
  }

  setStripeOptions(options: StripeElementsOptions): void {
    this.billingRepository.setStripeOptions(options);
  }

  private static getCycleTire(cycle: IProductEntity['cycle'] | BillingCycle): number {
    switch (cycle) {
      case 'monthly':
        return 1;
      case 'annually':
        return 2;
      case 'daily':
      default:
        return 0;
    }
  }
}
