import { inject, injectable, injectFromBase } from 'inversify';

import { ENRICHMENT_TYPES } from '@/ioc/types';

import { DbCollectionCreator, IMigrationService } from '@/features/system/db';
import { CollectionName } from '@/features/system/db';

import { IEnrichmentDC } from '../dataContract';

import { EnrichmentSchema } from './schema';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class EnrichmentCollectionCreator extends DbCollectionCreator<IEnrichmentDC> {
  constructor(
    @inject(ENRICHMENT_TYPES.EnrichmentMigrationService)
    migrationService: IMigrationService,
  ) {
    super({
      collectionName: CollectionName.Enrichment,
      schema: EnrichmentSchema,
      migrationStrategies: migrationService.getMigrationStrategies(),
    });
  }
}
