export enum WalkthroughStep {
  idle = 'non steps completed',
  installedExtension = 'extension installed',
  revealedContact = 'contact revealed',
  allDone = 'all steps completed',
}

export enum EnrichmentUploadError {
  InternetConnection = 'internet connection',
  RecordsOverLimit = 'too many records in the file',
  FileSizeOverLimit = 'file size too big',
  FileFormatInvalid = 'invalid file format',
  NotEnoughCredits = 'not enough credits',
}

export enum EnrichmentReportSelectedData {
  Emails = 'only email',
  Phones = 'only phone numbers',
  Both = 'emails and phone numbers',
}
