import { injectable, injectFromBase } from 'inversify';

import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import { IContactByFiltersDC } from '../../dataContracts';

export type IContactByFiltersDao = IDbCollection<IContactByFiltersDC, 'uuid'>;

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class ContactByFiltersDao
  extends DbCollection<IContactByFiltersDC, 'uuid'>
  implements IContactByFiltersDao
{
  constructor() {
    super({ collectionName: CollectionName.ContactByFilters, idKey: 'uuid' });
  }
}
