export const ReasonsArray = [
  'tooExpensive',
  'dataQualityIssues',
  'foundAlternative',
  'nonUserFriendly',
  'onlyNeededForShortTerm',
  'other',
] as const;

export type Reason = (typeof ReasonsArray)[number];
