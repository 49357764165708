import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import CloseIcon from '@/assets/icons/close-round.svg?react';

import BgImage from './assets/icon.svg?react';
import { useDefaultStepViewModel } from './useDefaultStepViewModel';

import styles from './styles.module.scss';

const DefaultStepLayout: FC<{
  title: string;
  description: string;
  list: {
    title: string;
    description: string;
  }[];
  nextStepAction: ReactElement;
  keepSubscriptionAction: ReactElement;
  onClose: () => void;
}> = ({ title, description, list, nextStepAction, keepSubscriptionAction, onClose }) => {
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
        <ul className={styles.list}>
          {list.map((item) => (
            <li key={item.title} className={styles.listItem}>
              <h6>{item.title}</h6>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>
        <Box className={styles.actions}>
          {nextStepAction}
          {keepSubscriptionAction}
        </Box>
      </Box>
      <Box className={styles.image}>
        <BgImage />
      </Box>
      <Box className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </Box>
    </Box>
  );
};

export const DefaultStep: FC<{
  onClose: () => void;
  onGoToNextStep: () => void;
}> = ({ onClose, onGoToNextStep }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.cancelSubscriptionModal.defaultStep',
  });
  const { viewType, creditsLeft } = useDefaultStepViewModel();

  if (viewType === 'unlimited') {
    return (
      <DefaultStepLayout
        title={t('unlimitedView.title')}
        description={t('unlimitedView.description')}
        list={[
          {
            title: t('unlimitedView.items.0.title'),
            description: t('unlimitedView.items.0.description'),
          },
          {
            title: t('unlimitedView.items.1.title'),
            description: t('unlimitedView.items.1.description'),
          },
          {
            title: t('unlimitedView.items.2.title'),
            description: t('unlimitedView.items.2.description'),
          },
        ]}
        nextStepAction={
          <Button key="cancel" variant="text" color="primary" onClick={onGoToNextStep}>
            {t('unlimitedView.buttons.cancel')}
          </Button>
        }
        keepSubscriptionAction={
          <Button key="keep" variant="contained" color="primary" onClick={onClose}>
            {t('unlimitedView.buttons.keep')}
          </Button>
        }
        onClose={onClose}
      />
    );
  }

  return (
    <DefaultStepLayout
      title={t('creditBasedView.title')}
      description={t('creditBasedView.description')}
      list={[
        {
          title: t('creditBasedView.items.0.title', {
            count: creditsLeft,
          }),
          description: t('creditBasedView.items.0.description'),
        },
        {
          title: t('creditBasedView.items.1.title'),
          description: t('creditBasedView.items.1.description'),
        },
        {
          title: t('creditBasedView.items.2.title'),
          description: t('creditBasedView.items.2.description'),
        },
      ]}
      nextStepAction={
        <Button key="cancel" variant="text" color="primary" onClick={onGoToNextStep}>
          {t('creditBasedView.buttons.cancel')}
        </Button>
      }
      keepSubscriptionAction={
        <Button key="keep" variant="contained" color="primary" onClick={onClose}>
          {t('creditBasedView.buttons.keep')}
        </Button>
      }
      onClose={onClose}
    />
  );
};
