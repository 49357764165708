import { useCreditUseCase, useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

export const useDefaultStepViewModel = (): {
  viewType: 'unlimited' | 'creditBased';
  creditsLeft: number;
} => {
  const subscriptionUseCase = useSubscriptionUseCase();
  const creditUseCase = useCreditUseCase();
  const { data: isUnlimitedPlan } = useObservableResult(() =>
    subscriptionUseCase.getIsUnlimitedPlan(),
  );

  const { data: aggregatedFullCreditsInfo } = useObservableResult(() =>
    creditUseCase.getFullAggregatedCreditsInfo(),
  );

  const getCreditsLeft = (): number => {
    if (isUnlimitedPlan || !aggregatedFullCreditsInfo) {
      return 0;
    }

    return aggregatedFullCreditsInfo.limit - aggregatedFullCreditsInfo.used;
  };

  return {
    viewType: isUnlimitedPlan ? 'unlimited' : 'creditBased',
    creditsLeft: getCreditsLeft(),
  };
};
