import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

import { ROUTES } from '@/router/routes';

import { BillingCycle, useCurrentBillingCycle } from '@/features/common/billing';
import { useSubscriptionUseCase } from '@/features/common/workspace';
import { useAnalytics } from '@/features/system/analytics';

import { useAsyncExecutor, type UseAsyncExecutorResult } from '@/hooks';

import { ObservableResult } from '@/utils/rx';

export const useOfferStepViewModel = (params: {
  onClose: () => void;
}): {
  billingCycle: ObservableResult<BillingCycle>;
  cancelSubscription: UseAsyncExecutorResult<void>;
  getDiscount: () => void;
} => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions',
  });
  const billingCycle = useCurrentBillingCycle();
  const subscriptionUseCase = useSubscriptionUseCase();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const cancelSubscription = useAsyncExecutor(async (): Promise<void> => {
    try {
      await subscriptionUseCase.cancel();
      params.onClose();
      snackbar.enqueueSnackbar(t('subscriptionCancelled.title'), {
        variant: 'success',
        description: t('subscriptionCancelled.description'),
      });
      analytics.trackSubscriptionCanceled();
    } catch (error) {
      snackbar.enqueueSnackbar(t('subscriptionCancelFailed.title'), {
        variant: 'error',
        description: t('subscriptionCancelFailed.description'),
      });
    }
  });

  const getDiscount = (): void => {
    navigate(ROUTES.BILLING.PAYMENT_DETAILS);
  };

  useEffect(() => {
    analytics.trackCancelSubscriptionOfferView();
  }, []);

  return {
    billingCycle,
    cancelSubscription,
    getDiscount,
  };
};
