import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import authEN from './locales/en/auth.json';
import billingEN from './locales/en/billing.json';
import billingSettingsEN from './locales/en/billingSettings.json';
import commonEN from './locales/en/common.json';
import contactsEN from './locales/en/contacts.json';
import contactsTableEN from './locales/en/contactsTable.json';
import dashboardEN from './locales/en/dashboard.json';
import enrichmentEN from './locales/en/enrichment.json';
import integrationsEN from './locales/en/integrations.json';
import listsEN from './locales/en/lists.json';
import metaEN from './locales/en/meta.json';
import monthsEN from './locales/en/months';
import monthsShortcutsEN from './locales/en/monthsShortcuts';
import paymentDetailsEN from './locales/en/paymentDetails.json';
import plansEN from './locales/en/plans.json';
import referralEN from './locales/en/referral.json';
import retentionEN from './locales/en/retention.json';
import rewardsEN from './locales/en/rewards.json';
import settingsEn from './locales/en/settings.json';
import supportEN from './locales/en/support.json';
import tagEN from './locales/en/tag.json';
import validationEN from './locales/en/validation.json';

void i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'common',
  resources: {
    en: {
      auth: authEN,
      common: commonEN,
      lists: listsEN,
      dashboard: dashboardEN,
      integrations: integrationsEN,
      contacts: contactsEN,
      settings: settingsEn,
      contactsTable: contactsTableEN,
      plans: plansEN,
      monthsShortcuts: monthsShortcutsEN,
      months: monthsEN,
      paymentDetails: paymentDetailsEN,
      billing: billingEN,
      billingSettings: billingSettingsEN,
      tag: tagEN,
      validation: validationEN,
      meta: metaEN,
      support: supportEN,
      retention: retentionEN,
      enrichment: enrichmentEN,
      referral: referralEN,
      rewards: rewardsEN,
    },
  },
});

void i18n.changeLanguage('en');

export default i18n;
