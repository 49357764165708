import { injectable, injectFromBase } from 'inversify';

import { DbCollectionCreator } from '@/features/system/db';
import { CollectionName } from '@/features/system/db';

import { IContactListDC } from '../dataContracts/ContactListDC';

import { contactListMigrationStrategies } from './migration/migration';
import { contactListSchema } from './schema/contactListSchema';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class ContactListCollectionCreator extends DbCollectionCreator<IContactListDC> {
  constructor() {
    super({
      collectionName: CollectionName.ContactList,
      schema: contactListSchema,
      migrationStrategies: contactListMigrationStrategies,
    });
  }
}
