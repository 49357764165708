import { injectable, injectFromBase } from 'inversify';
import { mergeDeepRight } from 'ramda';
import { first, map, Observable, switchMap } from 'rxjs';

import { IAccountDC } from '@/features/common/account';
import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

export type IAccountDao = IDbCollection<IAccountDC, 'uuid'> & {
  updateCurrent(account: Partial<IAccountDC>): Observable<IAccountDC>;
  getCurrent(): Observable<IAccountDC | null>;
};

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export default class AccountDao
  extends DbCollection<IAccountDC, 'uuid'>
  implements IAccountDao
{
  constructor() {
    super({ collectionName: CollectionName.Account, idKey: 'uuid' });
  }

  updateCurrent = (account: Partial<IAccountDC>): Observable<IAccountDC> => {
    return this.getCurrent().pipe(
      first(),
      switchMap((acc) => {
        if (!acc) {
          throw new Error('Account not found');
        }

        return this.upsert(mergeDeepRight(acc, account) as IAccountDC);
      }),
    );
  };

  getCurrent = (): Observable<IAccountDC | null> => {
    return this.findAll().pipe(map((accounts) => accounts[0] ?? null));
  };
}
