import React, { useContext, useState } from 'react';
import type { Container, ServiceIdentifier } from 'inversify';

const InversifyContext = React.createContext<{ container: Container | null }>({
  container: null,
});

type Props = {
  children: any;
  container: Container;
};

export const IocProvider: React.FC<Props> = (props) => {
  return (
    <InversifyContext.Provider value={{ container: props.container }}>
      {props.children}
    </InversifyContext.Provider>
  );
};

export function useInjection<T>(identifier: ServiceIdentifier<T>): T {
  const { container } = useContext(InversifyContext);

  if (!container) {
    throw new Error('No IOC container is initialized...');
  }

  const [service] = useState(() => container.get<T>(identifier));
  return service;
}
