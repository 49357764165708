/* tslint:disable:restrict-plus-operands */
import dayjs, { Dayjs } from 'dayjs';

import { AggregatedChartDataEntity } from '../entities/AggregatedChartDataEntity';
import { ChartDataEntity } from '../entities/ChartDataEntity';

export abstract class Aggregator {
  public result: AggregatedChartDataEntity[] = [];
  protected dateObjs: number[] = [];
  protected data: ChartDataEntity[] = [];
  protected from: Dayjs;
  protected to: Dayjs;

  protected constructor(dataOriginal: ChartDataEntity[], from: Dayjs, to: Dayjs) {
    this.from = from;
    this.to = to;
    if (dataOriginal.length > 0) {
      this.data = [...dataOriginal].reverse();
    }
    this.dateObjs.push(to.startOf('day').unix());
  }

  protected groupDataByTimestamps(
    dateTimestamps: number[],
    data: ChartDataEntity[],
  ): AggregatedChartDataEntity[] {
    const map = new Map<number, AggregatedChartDataEntity>();

    dateTimestamps.forEach((t, i) => {
      map.set(i, {
        at: t,
        member: data[0]?.member ?? null,
        creditsUsed: 0,
        emailsCount: 0,
        phonesCount: 0,
      });
    });

    data.forEach((value) => {
      const entityDate = dayjs.unix(value.at);

      const periodIndex = dateTimestamps.findIndex((endTimestamp, index) => {
        const startTimestamp =
          index === dateTimestamps.length - 1 ? 0 : dateTimestamps[index + 1];
        const start = dayjs.unix(startTimestamp);
        const end = dayjs.unix(endTimestamp);

        return this.isDateInRange(entityDate, { start, end });
      });

      const record = map.get(periodIndex);

      if (record) {
        map.set(periodIndex, {
          ...record,
          creditsUsed: record.creditsUsed + value.creditsUsed,
          emailsCount: record.emailsCount + value.emailsCount,
          phonesCount: record.phonesCount + value.phonesCount,
        });
      }
    });

    return Array.from(map.values()).reverse();
  }

  private isDateInRange(
    date: dayjs.Dayjs,
    range: { start: dayjs.Dayjs; end: dayjs.Dayjs },
  ): boolean {
    return (
      date.isSame(range.end, 'day') ||
      (date.isBefore(range.end, 'day') && date.isAfter(range.start, 'day'))
    );
  }
}
