import { useSubscriptionUseCase } from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import type { BillingCycle } from '../../domain';

export function useCurrentBillingCycle(): ObservableResult<BillingCycle> {
  const subscriptionUseCase = useSubscriptionUseCase();

  return useObservableResult(() => subscriptionUseCase.getSubscriptionBillingCycle());
}
