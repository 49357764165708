import { inject, injectable } from 'inversify';
import { filter } from 'rxjs';

import { ACCOUNT_TYPES, APP_LOGGER_TYPES, AUTH_TYPES, COOKIES_TYPES } from '@/ioc/types';

import type { IAccountEntity, IAccountRepository } from '@/features/common/account';
import type { IAuthRepository } from '@/features/common/auth';
import { AuthStatus } from '@/features/common/auth';
import type { AppLogger } from '@/features/system/logger';

import type { ICookiesManager } from '../../cookies';
import { IAnalyticRepository } from '../domain';

export interface ICustomerIORepository
  extends IAnalyticRepository<Record<string, string>> {
  page(event: string, attrs?: Record<string, string>): void;
}

@injectable()
export class CustomerIORepository implements ICustomerIORepository {
  private readonly cio;
  constructor(
    @inject(APP_LOGGER_TYPES.AppLogger)
    private logger: AppLogger,

    @inject(ACCOUNT_TYPES.AccountRepository)
    private accountRepository: IAccountRepository,

    @inject(AUTH_TYPES.AuthRepository)
    private authRepository: IAuthRepository,

    @inject(COOKIES_TYPES.CookiesManager)
    private cookiesManager: ICookiesManager,
  ) {
    if (typeof _cio === 'undefined') {
      this.logger.error('CustomerIO is not initialized');
    }

    this.cio = _cio;
    this.listenAccountToIdentifyUser();
    this.listenSignOut();
  }

  private identifyUser(account: IAccountEntity): void {
    if (!this.cio) return;

    this.cio.identify({
      id: account.uuid,
      first_name: account.fullName ? account.fullName.split(' ')[0] : '',
      email: account.email,
    });
  }

  private wipeCookies(): void {
    this.cookiesManager.remove('_cioid');
    this.cookiesManager.remove('_cioanonid');
  }

  private listenAccountToIdentifyUser(): void {
    this.accountRepository
      .getAccount()
      .pipe(filter((account) => !!account))
      .subscribe((account) => {
        this.identifyUser(account);
      });
  }

  private listenSignOut(): void {
    this.authRepository
      .getAuthStatus()
      .pipe(filter((authStatus) => authStatus === AuthStatus.Unauthorized))
      .subscribe(() => {
        this.wipeCookies();
      });
  }

  public trackEvent(event: string, attrs?: Record<string, string>): void {
    if (!this.cio) return;

    this.cio.track(event, attrs);
  }

  public page(event: string, attrs?: Record<string, string>): void {
    if (!this.cio) return;

    this.cio.page(event, attrs);
  }
}
