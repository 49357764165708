import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

import { type Reason, ReasonsArray } from '../../types';

import styles from './styles.module.scss';

export const SelectReason: FC<{
  value: Reason | null;
  onSelect: (reason: Reason) => void;
  onClose: () => void;
  onGoToNextStep: () => void;
}> = ({ value, onSelect, onClose, onGoToNextStep }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.cancelSubscriptionModal.reasonStep.selectView',
  });

  return (
    <Box className={styles.wrapper}>
      <h4 className={styles.title}>{t('title')}</h4>
      <ul className={styles.list}>
        {ReasonsArray.map((reason) => (
          <li
            className={clsx(styles.listItem, {
              [styles.active]: value === reason,
            })}
            key={reason}
            onClick={(): void => onSelect(reason)}
          >
            {t(`items.${reason}`)}
          </li>
        ))}
      </ul>
      <Box className={styles.actions}>
        <Button variant="text" color="primary" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!value}
          onClick={onGoToNextStep}
        >
          {t('buttons.next')}
        </Button>
      </Box>
    </Box>
  );
};
