import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';

import { useObservableResult } from '@/utils/rx';

// @ts-ignore
import LightFont from '@/assets/fonts/SofiaProLight.woff2';
// @ts-ignore
import MediumFont from '@/assets/fonts/SofiaProRegular.woff2';

import { useBillingUseCase } from './useBillingUseCase';
import { useStripeInstance } from './useStripeInstance';

type UseStripeProviderSetup = () => {
  options: StripeElementsOptions;
  stripe: Stripe | null;
};

export const useStripeProviderSetup: UseStripeProviderSetup = () => {
  const { t } = useTranslation('common');
  const stripeInstance = useStripeInstance();
  const { enqueueSnackbar } = useSnackbar();
  const billingUseCase = useBillingUseCase();

  const { data: options } = useObservableResult(() => billingUseCase.getStripeOptions(), {
    defaultData: {
      fonts: [
        {
          family: 'SofiaPro',
          src: `url('${window.location.origin}${LightFont}') format('woff2')`,
          weight: '400',
        },
        {
          family: 'SofiaPro',
          src: `url('${window.location.origin}${MediumFont}') format('woff2')`,
          weight: '500',
        },
      ],
      mode: 'subscription',
      currency: 'usd',
      amount: 1000,
      setup_future_usage: 'off_session',
      setupFutureUsage: 'off_session',
    },
  });

  useEffect(() => {
    if (stripeInstance.hasError) {
      enqueueSnackbar(t('stripe.failedToInitialize'), {
        variant: 'error',
      });
    }
  }, [stripeInstance.hasError]);

  return {
    options,
    stripe: stripeInstance.data,
  };
};
