import { injectable, injectFromBase } from 'inversify';
import { combineLatest, map, Observable } from 'rxjs';

import { DbState } from '@/features/system/db';

import type { IIntegrationsConfigDC } from '../dataContracts';

export interface IIntegrationsState {
  getState(): Observable<Nullable<IIntegrationsConfigDC>>;
  setState(state: IIntegrationsConfigDC): Promise<void>;
}

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class IntegrationsState
  extends DbState<IIntegrationsConfigDC>
  implements IIntegrationsState
{
  constructor() {
    super({ stateName: 'integrations' });
  }

  getState(): Observable<Nullable<IIntegrationsConfigDC>> {
    return combineLatest({
      integrations: this.get$('integrations'),
      providers: this.get$('providers'),
    }).pipe(
      map(({ integrations, providers }) => {
        if (!integrations && !providers) {
          return null;
        }

        return {
          integrations: integrations ?? [],
          providers: providers ?? [],
        };
      }),
    );
  }

  async setState(state: IIntegrationsConfigDC): Promise<void> {
    await Promise.all([
      this.set('integrations', () => state.integrations),
      this.set('providers', () => state.providers),
    ]);
  }
}
