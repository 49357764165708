import { AuthIdentityProvider } from '@/features/common/auth';

export const mapAuthIdentityProviderToAnalyticIdentityProviderName = (
  provider: AuthIdentityProvider,
): 'Google' | 'Microsoft' | 'Email' => {
  switch (provider) {
    case AuthIdentityProvider.google:
      return 'Google';
    case AuthIdentityProvider.microsoft:
      return 'Microsoft';
    default:
      return 'Email';
  }
};
