import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, NavLink } from 'react-router';
import { Box, Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { useExtensionState } from '@/features/common/extension';
import { useOnboardingStepState } from '@/features/common/onboarding';

import { Logo } from '@/components/Icons';
import { SplashScreen } from '@/components/SplashScreen';

import ChromeIcon from '@/assets/icons/chromeIcon.svg?react';

import { useInstallExtensionPageViewModel } from './useInstallExtensionPageViewModel';

import styles from './styles.module.scss';

const InstallExtensionPage: React.FC = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'installExtensionPage' });
  const { countdown, onInstall, onSkip } = useInstallExtensionPageViewModel();

  return (
    <Box className={styles.page}>
      <Box className={styles.header}>
        <NavLink to={ROUTES.DASHBOARD}>
          <Box>
            <Logo />
          </Box>
        </NavLink>
      </Box>
      <Box className={styles.body}>
        <Box className={styles.card}>
          <Box className={styles.icon}>
            <ChromeIcon />
          </Box>
          <Typography
            variant="h1"
            fontSize="36px"
            fontWeight="900"
            className={styles.title}
          >
            <Trans t={t} i18nKey="title" />
          </Typography>
          <Typography className={styles.subtitle}>
            <Trans t={t} i18nKey="subtitle" components={{ 1: <span /> }} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={onInstall}
            size="large"
            className={styles.installButton}
          >
            {t('actions.install', { countdown })}
          </Button>
          <Button className={styles.skipButton} color="info" onClick={onSkip}>
            {t('actions.skip')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const InstallExtensionPageGuard: React.FC = () => {
  const extensionState = useExtensionState();
  const installExtensionOnboardingStepState = useOnboardingStepState('installExtension');
  const viewInstallExtensionPageOnboardingStepState = useOnboardingStepState(
    'viewInstallExtensionPage',
  );

  switch (true) {
    case installExtensionOnboardingStepState.isLoading ||
      viewInstallExtensionPageOnboardingStepState.isLoading:
      return <SplashScreen />;
    case installExtensionOnboardingStepState.data === 'completed':
      return <Navigate to={ROUTES.DASHBOARD} replace />;
    case viewInstallExtensionPageOnboardingStepState.data === 'completed':
      return <Navigate to={ROUTES.DASHBOARD} replace />;
    case extensionState.isInstalled:
      return <Navigate to={ROUTES.DASHBOARD} replace />;
    default:
      return <InstallExtensionPage />;
  }
};

export { InstallExtensionPageGuard as InstallExtensionPage };
