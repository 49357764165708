import { inject, injectable } from 'inversify';

import { ANALYTICS_TYPES } from '@/ioc/types';

import { AuthIdentityProvider } from '@/features/common/auth';
import { PlanType } from '@/features/common/billing';

import { IFacebookPixelRepository, IGoogleTagManagerRepository } from '../data';
import { type IAmplitudeRepository } from '../data/AmplitudeRepository';
import type { ICustomerIORepository } from '../data/CustomerIORepository';

import {
  EnrichmentReportSelectedData,
  EnrichmentUploadError,
  WalkthroughStep,
} from './types/eventProperties';
import { mapAuthIdentityProviderToAnalyticIdentityProviderName } from './utils';

@injectable()
export class AnalyticsUseCase {
  @inject(ANALYTICS_TYPES.AmplitudeRepository)
  private amplitudeRepository: IAmplitudeRepository;

  @inject(ANALYTICS_TYPES.GoogleTagManagerRepository)
  private googleTagManagerRepository: IGoogleTagManagerRepository;

  @inject(ANALYTICS_TYPES.FacebookPixelRepository)
  private facebookPixelRepository: IFacebookPixelRepository;

  @inject(ANALYTICS_TYPES.CustomerIORepository)
  private customerIORepository: ICustomerIORepository;

  public trackFbPixelEvent = (type: string, options?: Record<string, string>): void => {
    this.facebookPixelRepository.trackEvent(type, options);
  };

  public trackViewSignupPage = (): void => {
    this.amplitudeRepository.trackEvent('view_signup_page_web');
    this.googleTagManagerRepository.trackEvent('View_Signup_Page1');
  };

  public trackSignupClickGoogle = (): void => {
    this.amplitudeRepository.trackEvent('signup_click_button_web', {
      type: 'Google',
    });
    this.googleTagManagerRepository.trackEvent('Click_Signup_Google');
  };

  public trackSignupClickMicrosoft = (): void => {
    this.amplitudeRepository.trackEvent('signup_click_button_web', {
      type: 'Microsoft',
    });
    this.googleTagManagerRepository.trackEvent('Click_Signup_Microsoft');
  };

  public trackSignupClickEmail = (): void => {
    this.amplitudeRepository.trackEvent('signup_click_button_web', {
      type: 'Email',
    });
    this.googleTagManagerRepository.trackEvent('Click_Signup_Email');
  };

  public trackSignupError = (error: string): void => {
    this.amplitudeRepository.trackEvent('signup_error', {
      type: error,
    });
  };

  public trackViewCreateAccountPage = (): void => {
    this.amplitudeRepository.trackEvent('view_create_account_page');
    this.googleTagManagerRepository.trackEvent('View_Signup_Page2');
  };

  public trackSignupCompleteWithProvider = (
    provider: Omit<AuthIdentityProvider, 'password'>,
  ): void => {
    this.googleTagManagerRepository.trackEvent(`SignUp_Complete_${provider}`);
    this.googleTagManagerRepository.trackEvent('Signup_complete');

    this.facebookPixelRepository.trackEvent('CompleteRegistration');
    this.amplitudeRepository.trackEvent('signup_complete', {
      type: provider,
    });
    this.amplitudeRepository.trackEvent('user_product_walkthrough', {
      step: 'non steps completed',
    });
  };

  public trackSignupCompleteWithEmail = (): void => {
    this.googleTagManagerRepository.trackEvent('SignUp_Complete_Email');
    this.amplitudeRepository.trackEvent('signup_complete', {
      type: 'Email',
    });
    this.facebookPixelRepository.trackEvent('CompleteRegistration');
  };

  public trackViewVerificationEmailPage = (): void => {
    this.amplitudeRepository.trackEvent('view_verification_page');
    this.googleTagManagerRepository.trackEvent('View_Verification_Page');
  };

  public trackViewVerificationPhonePage = (): void => {
    this.amplitudeRepository.trackEvent('view_verification_page');
    this.googleTagManagerRepository.trackEvent('View_Verification_Page');
  };

  public trackViewInstallExtensionPage = (): void => {
    this.amplitudeRepository.trackEvent('view_Install_extension_page');
    this.googleTagManagerRepository.trackEvent('View_InstallExtension_Page');
    this.googleTagManagerRepository.trackEvent('Install_extension_page_viewed');
  };

  public trackInstallExtension = (): void => {
    this.googleTagManagerRepository.trackEvent('Click_Install');
    this.googleTagManagerRepository.trackEvent('Download_extension_clicked');

    this.amplitudeRepository.trackEvent('Download_extension_clicked');
  };

  public trackSkipInstallExtension = (): void => {
    this.googleTagManagerRepository.trackEvent(
      'Install_extension_page_click_maybe_later',
    );
  };

  public trackWatchTutorial = (): void => {
    this.amplitudeRepository.trackEvent('watch_tutorial', {
      page: 'dashboard',
    });
  };

  public trackClickChangeCard = (): void => {
    this.amplitudeRepository.trackEvent('click_change_card');
  };

  public trackSubscriptionCanceled = (): void => {
    this.amplitudeRepository.trackEvent('subscription_canceled');
    this.customerIORepository.trackEvent('cancel_subscription');
  };

  public trackCancelSubscriptionButtonClick = (): void => {
    this.amplitudeRepository.trackEvent('user_clicks_cancel_subscription_button');
  };

  public trackChooseCancelSubscriptionReason = (reason: string): void => {
    this.amplitudeRepository.trackEvent('cancellation_flow_choose_cancellation_reason', {
      reason,
    });
  };

  public trackCancelSubscriptionOfferView = (): void => {
    this.amplitudeRepository.trackEvent('cancellation_flow_view_offer');
  };

  public trackCancelSubscriptionRedeemOffer = (): void => {
    this.amplitudeRepository.trackEvent('cancellation_flow_redeem_offer');
  };

  public trackCompanyFieldClick = (): void => {
    this.amplitudeRepository.trackEvent('company_field_click');
  };

  public trackContactInfoEmailAction = (
    type: 'copy email' | 'send email' | 'wrong info',
  ): void => {
    this.amplitudeRepository.trackEvent('contact_info_email_action', {
      type,
    });
  };

  public trackContactInfoPhoneAction = (
    type: 'whatsapp' | 'sms' | 'call' | 'wrong info' | 'copy phone',
  ): void => {
    this.amplitudeRepository.trackEvent('contact_info_phone_action', {
      type,
    });
  };

  public trackContactInfoSocialAction = (): void => {
    this.amplitudeRepository.trackEvent('contact_info_social_action');
  };

  public trackContactOwnerChange = (): void => {
    this.amplitudeRepository.trackEvent('change_contact_owner');
  };

  public trackContactAddTag = (): void => {
    this.amplitudeRepository.trackEvent('contact_add_tag', {
      platform: 'web',
    });
  };

  public trackExportButtonClick = (): void => {
    this.amplitudeRepository.trackEvent('export_button_click', {
      platform: 'web',
    });
  };

  public trackExportCompleted = (): void => {
    this.amplitudeRepository.trackEvent('export_completed', {
      platform: 'web',
    });
    this.googleTagManagerRepository.trackEvent('Contact_exported');
  };

  public trackCreateNewList = (): void => {
    this.amplitudeRepository.trackEvent('create_new_list', {
      platform: 'web',
    });
  };

  public trackUserProductWalkthrough = (step: WalkthroughStep): void => {
    this.amplitudeRepository.trackEvent('user_product_walkthrough', {
      step,
    });
    if (step === WalkthroughStep.installedExtension) {
      this.facebookPixelRepository.trackEvent('SubmitApplication');
    }
  };

  public trackFilterByDate = (
    page: 'contacts' | 'dashboard' | 'contact_lists',
    time: string,
  ): void => {
    this.amplitudeRepository.trackEvent('filter_by_time', {
      page,
      time,
    });
  };

  public trackFilterByUsers = (
    page: 'contacts' | 'dashboard' | 'contact_lists',
  ): void => {
    this.amplitudeRepository.trackEvent('filter_by_users', {
      page,
    });
  };

  public trackContactAddToList = (): void => {
    this.amplitudeRepository.trackEvent('contact_add_to_list', {
      platform: 'web',
    });
  };

  public trackViewAllContactsList = (
    type: 'with contacts' | 'empty',
    contactsCount: number,
  ): void => {
    this.amplitudeRepository.trackEvent('view_all_contacts_list', {
      type,
      contacts_count: contactsCount,
    });
  };

  public trackViewList = (
    type: 'with contacts' | 'empty',
    contactsCount: number,
  ): void => {
    this.amplitudeRepository.trackEvent('view_list_page', {
      type,
      contacts_count: contactsCount,
    });
  };

  public trackEnrichmentUploadError = (type: EnrichmentUploadError): void => {
    this.amplitudeRepository.trackEvent('upload_csv_file_error', {
      type,
    });
  };

  public trackEnrichmentCompleted = (): void => {
    this.amplitudeRepository.trackEvent('enrich_csv_completed');
  };

  public trackEnrichmentUploadCompleted = (): void => {
    this.amplitudeRepository.trackEvent('upload_csv_file_completed');
  };

  public trackEnrichmentDownloadCsv = (): void => {
    this.amplitudeRepository.trackEvent('download_csv');
  };

  public trackEnrichmentReportChosenData = (type: EnrichmentReportSelectedData): void => {
    this.amplitudeRepository.trackEvent('csv_choose_data_to_enrich', {
      type,
    });
  };

  public trackEnrichmentUploadBtnClick = (plan: PlanType | null): void => {
    this.amplitudeRepository.trackEvent('user_clicks_upload_csv_button', {
      plan,
    });
  };

  public trackIntegrationsPageView = (): void => {
    this.amplitudeRepository.trackEvent('view_integrations_page');
  };

  public trackIntegrationsConnectClick = (type: string): void => {
    this.amplitudeRepository.trackEvent('integrations_clicked_connect', {
      type,
    });
  };

  public trackIntegrationsRequestSent = (name: string): void => {
    this.amplitudeRepository.trackEvent('request_integrations_sent', {
      name,
    });
  };

  public trackViewContactsTab = (listsCount: number): void => {
    this.amplitudeRepository.trackEvent('view_contacts_tab', {
      lists_count: listsCount,
    });
  };

  public trackNewListClick = (): void => {
    this.amplitudeRepository.trackEvent('new_list_clicked');
  };

  public trackDeleteList = (): void => {
    this.amplitudeRepository.trackEvent('delete_list');
  };

  public trackRenameList = (): void => {
    this.amplitudeRepository.trackEvent('rename_list');
  };

  public trackPaymentDetailsPageView = (): void => {
    this.amplitudeRepository.trackEvent('view_payment_details_page');
    this.customerIORepository.page('user_viewed_payment_details_page');
  };

  public trackPaymentSuccess = (name: string): void => {
    this.amplitudeRepository.trackEvent('payment_success', { package: name });
    this.googleTagManagerRepository.trackEvent('User_purchased');
    this.customerIORepository.trackEvent('user_successfully_purchased');
  };

  public trackPaymentFailed = (name: string, reason: string): void => {
    this.amplitudeRepository.trackEvent('payment_failed', { package: name, reason });
    this.googleTagManagerRepository.trackEvent('Purchase_failed');
    this.customerIORepository.trackEvent('payment_failed');
  };

  public trackSubmitPurchaseClick = (): void => {
    this.amplitudeRepository.trackEvent('clicks_submit_purchase');
  };

  public trackViewPlansPage = (params: { currentPlan: string }): void => {
    const storageKey = 'cm_page_view_counter_user_viewed_plans_page';
    const counter = localStorage.getItem(storageKey);
    this.amplitudeRepository.trackEvent('view_plans_page', {
      current_plan: params.currentPlan,
    });

    if (!counter) {
      this.customerIORepository.page('user_viewed_plans_page');
      localStorage.setItem(storageKey, '1');
    } else if (counter === '1') {
      this.customerIORepository.page('viewed_pricing_x2');
    }
  };

  public trackViewSubscriptionPage = (): void => {
    this.amplitudeRepository.trackEvent('view_subscription_page');
  };

  public trackSelectPlan = (plan: string): void => {
    this.amplitudeRepository.trackEvent('clicks_select_plan', {
      package: plan,
    });
  };

  public trackClickTalkToSales = (): void => {
    this.amplitudeRepository.trackEvent('click_talk_to_sales');
  };

  public trackChangeSubscription = (type: string): void => {
    this.amplitudeRepository.trackEvent('change_subscription', {
      type,
    });
  };

  public trackCopyReferralLink = (): void => {
    this.amplitudeRepository.trackEvent('copy_referral_link');
  };

  public trackSendReferralEmail = (): void => {
    this.amplitudeRepository.trackEvent('send_referral_email');
  };

  public trackViewResetPasswordPage = (): void => {
    this.amplitudeRepository.trackEvent('view_reset_password_page');
  };

  public trackResetPasswordComplete = (): void => {
    this.amplitudeRepository.trackEvent('reset_password_complete');
  };

  public trackReceivedRetentionOffer = (): void => {
    this.amplitudeRepository.trackEvent('received_retention_offer');
  };

  public trackAcceptedRetentionOffer = (): void => {
    this.amplitudeRepository.trackEvent('accepted_retention_offer');
  };

  public trackDeclinedRetentionOffer = (): void => {
    this.amplitudeRepository.trackEvent('declined_retention_offer');
  };

  public trackViewMyAccountPage = (): void => {
    this.amplitudeRepository.trackEvent('view_my_account_page');
  };

  public trackChangeEmail = (): void => {
    this.amplitudeRepository.trackEvent('change_email');
  };

  public trackChangePassword = (): void => {
    this.amplitudeRepository.trackEvent('change_password');
  };

  public trackDeleteAccount = (): void => {
    this.amplitudeRepository.trackEvent('delete_account');
  };

  public trackViewTagManagementPage = (): void => {
    this.amplitudeRepository.trackEvent('tag_management_viewed');
  };

  public trackCreateNewTag = (): void => {
    this.amplitudeRepository.trackEvent('create_new_tag', {
      platform: 'web',
    });
  };

  public trackRemoveTag = (name: string): void => {
    this.amplitudeRepository.trackEvent('remove_tag', {
      tag_name: name,
    });
  };

  public trackAddTeamMemberButtonClick = (): void => {
    this.amplitudeRepository.trackEvent('invite_button_clicked');
  };

  public trackViewTeamManagementPage = (): void => {
    this.amplitudeRepository.trackEvent('view_team_managment_page');
  };

  public trackAddTeamMember = (role: string): void => {
    this.amplitudeRepository.trackEvent('add_member', {
      role,
    });
    this.googleTagManagerRepository.trackEvent('Team_member_invited');
  };

  public trackResendInvitation = (): void => {
    this.amplitudeRepository.trackEvent('resend_invitation');
  };

  public trackRemoveTeamMember = (): void => {
    this.amplitudeRepository.trackEvent('remove_member');
  };

  public trackClickForgotPassword = (): void => {
    this.amplitudeRepository.trackEvent('forgot_password_click_button');
  };

  public trackForgotPasswordInsertEmail = (): void => {
    this.amplitudeRepository.trackEvent('forgot_password_insert_email');
  };

  public trackResetPasswordEmailSent = (): void => {
    this.amplitudeRepository.trackEvent('reset_password_email_sent');
  };

  public trackSigninPageView = (): void => {
    this.amplitudeRepository.trackEvent('view_signin_page_web');
  };

  public trackSigninError = (type: string): void => {
    this.amplitudeRepository.trackEvent('signin_error', {
      type,
    });
  };

  public trackSigninButtonClick = (signInProvider: AuthIdentityProvider): void => {
    this.amplitudeRepository.trackEvent('signin_click_button_web', {
      type: mapAuthIdentityProviderToAnalyticIdentityProviderName(signInProvider),
    });
  };

  public trackSigninSuccess = (signInProvider: AuthIdentityProvider): void => {
    this.amplitudeRepository.trackEvent('signin_success', {
      type: mapAuthIdentityProviderToAnalyticIdentityProviderName(signInProvider),
    });
  };

  public trackViewDashboardPage = (): void => {
    this.amplitudeRepository.trackEvent('view_dashboard_page');
    this.googleTagManagerRepository.trackEvent('Dashboard_viewed');
  };

  public trackInviteeOpenLink = (): void => {
    this.amplitudeRepository.trackEvent('invitee_open_link');
  };

  public trackIntegrationConnectSuccess = (type: string): void => {
    this.amplitudeRepository.trackEvent('integration_connect_success', {
      type,
    });
  };

  public trackIntegrationConnectError = (reason?: string): void => {
    this.amplitudeRepository.trackEvent('integration_connect_error', {
      reason,
    });
  };

  public trackIntegrationResuestToAdmin = (): void => {
    this.amplitudeRepository.trackEvent('integration_requested_from_admin');
  };

  public trackCustomIntegrationRequestSent = (): void => {
    this.amplitudeRepository.trackEvent('custom_request_integrations_sent');
  };

  public trackRewardsRedeemSuccess = (): void => {
    this.amplitudeRepository.trackEvent('engagement_redeem_5_credits');
  };

  public trackRewardsRedeemError = (type: 'already_receive' | 'network_error'): void => {
    this.amplitudeRepository.trackEvent('engagement_redeem_5_credits_error', {
      type,
    });
  };

  public trackMobileSignUpOpenChromeStore(): void {
    this.googleTagManagerRepository.trackEvent('Mobile_signup_open_chrome_store');
  }

  public trackUserRedeem5Credits = (): void => {
    this.customerIORepository.trackEvent('user_redeem_5_free_credits');
  };

  public trackUserAddedEmailOnWebform = (params?: { email: string }): void => {
    this.customerIORepository.trackEvent('user_added_email_on_webform', params);
  };
}
