import { AxiosObservable } from 'axios-observable';
import { catchError, of, shareReplay, tap, throwError } from 'rxjs';

import type { CacheEntry, CacheOptions } from './types';

export interface IHttpClientCache {
  apply: <T>(req: () => AxiosObservable<T>, options: CacheOptions) => void;
  cleanup: () => void;
}

class LRUCache {
  private maxSize: number;
  private cache: Map<string, CacheEntry<any>> = new Map();

  constructor(maxSize = 10) {
    this.maxSize = maxSize;
  }

  set<T>(key: string, entry: CacheEntry<T>): void {
    if (this.cache.size >= this.maxSize) {
      const oldestKey = this.cache.keys().next().value;
      this.cache.delete(oldestKey);
    }

    this.cache.set(key, entry);
  }

  get<T>(key: string): Nullable<CacheEntry<T>> {
    if (!this.cache.has(key)) return null;

    const entry = this.cache.get(key)!;

    this.cache.delete(key);
    this.cache.set(key, entry);

    return entry;
  }

  delete(key: string): void {
    this.cache.delete(key);
  }

  clear(): void {
    this.cache.clear();
  }
}

export class HttpClientCache implements IHttpClientCache {
  private lruCache: LRUCache;

  constructor(maxSize = 10) {
    this.lruCache = new LRUCache(maxSize);
  }

  private applyCacheFirstPolicy<T>(
    req: () => AxiosObservable<T>,
    cacheOptions: {
      cacheKey: string;
      revalidateAfter: number;
    },
  ): AxiosObservable<T> {
    const cacheEntry = this.lruCache.get<T>(cacheOptions.cacheKey);
    const now = Date.now();

    if (cacheEntry && now - cacheEntry.timestamp < cacheOptions.revalidateAfter) {
      return of(cacheEntry.data);
    } else {
      return req().pipe(
        tap((response) =>
          this.lruCache.set(cacheOptions.cacheKey, {
            data: response,
            timestamp: Date.now(),
          }),
        ),
        shareReplay(1),
      );
    }
  }

  private applyNetworkFirstPolicy<T>(
    req: () => AxiosObservable<T>,
    cacheOptions: {
      cacheKey: string;
      revalidateAfter: number;
    },
  ): AxiosObservable<T> {
    return req().pipe(
      catchError((error) => {
        const cacheEntry = this.lruCache.get<T>(cacheOptions.cacheKey);
        if (cacheEntry) return of(cacheEntry.data);
        return throwError(() => error);
      }),
    );
  }

  public apply<T>(
    req: () => AxiosObservable<T>,
    options: Required<CacheOptions>,
  ): AxiosObservable<T> {
    const { cachePolicy, revalidateAfter, cacheKey } = options;

    const cacheOptions = {
      revalidateAfter,
      cacheKey,
    };

    switch (cachePolicy) {
      case 'network-first':
        return this.applyNetworkFirstPolicy(req, cacheOptions);
      case 'cache-first':
        return this.applyCacheFirstPolicy(req, cacheOptions);
      case 'no-cache':
      default:
        return req();
    }
  }

  public cleanup(): void {
    this.lruCache.clear();
  }
}
