import { FieldPath, get, useFormContext, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { IPaymentMethodEntity } from '@/features/common/billing';

import type { PaymentFormValues } from '../../contexts';

type PaymentControlOverrides = {
  seats: {
    value: number;
    onChange: (value: number) => void;
  };
  'billingDetails.country': {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
  };
  'paymentMethod.card': Pick<IPaymentMethodEntity, 'card'> | undefined;
};

type PaymentControl = <K extends FieldPath<PaymentFormValues>>(
  key: K,
) => K extends keyof PaymentControlOverrides
  ? PaymentControlOverrides[K]
  : { name: K } & UseFormRegisterReturn<typeof key>;

type UsePaymentFormViewModel = (params: { isProcessing: boolean }) => {
  t: ReturnType<typeof useTranslation>['t'];
  bindPaymentFormField: (
    key: FieldPath<PaymentFormValues>,
    required: boolean,
  ) => {
    name: FieldPath<PaymentFormValues>;
    labelText: string;
    required: boolean;
    disabled: boolean;
    error: boolean;
    errorText: string | undefined;
  };
  bindPaymentControl: PaymentControl;
  hasPaymentMethod: boolean;
};

export const usePaymentFormViewModel: UsePaymentFormViewModel = ({ isProcessing }) => {
  const form = useFormContext<PaymentFormValues>();
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'form' });
  const { t: tField } = useTranslation('billing', { keyPrefix: 'fields' });
  const { t: tErrors } = useTranslation('billing', { keyPrefix: 'validations' });

  console.log(form.formState.errors);

  const field = (key: string): { label: string; placeholder: string } => {
    const k = key.replace('billingDetails.', '');
    return {
      label: tField(`${k}.label`),
      placeholder: tField(`${k}.placeholder`),
    };
  };

  // eslint-disable-next-line
  const paymentFormField = (key: FieldPath<PaymentFormValues>, required: boolean) => {
    const hasError = !!get(form.formState.errors, key);
    return {
      name: key,
      labelText: field(key).label,
      required,
      disabled: isProcessing,
      error: hasError,
      errorText: hasError
        ? tErrors(get(form.formState.errors, key)?.message)?.replaceAll(
            'validations.',
            '',
          )
        : undefined,
    };
  };

  const seats = form.watch('seats');
  const country = form.watch('billingDetails.country');
  const card = form.watch('paymentMethod.card');

  // @ts-ignore
  const paymentControl: PaymentControl = (key) => {
    switch (key) {
      case 'seats':
        return {
          value: seats,
          onChange: (newSeats: number): void => form.setValue('seats', newSeats),
        } satisfies PaymentControlOverrides['seats'];
      case 'billingDetails.country':
        return {
          placeholder: field(key).placeholder,
          value: country,
          onChange: (newCountry: string): void =>
            form.setValue('billingDetails.country', newCountry),
        } satisfies PaymentControlOverrides['billingDetails.country'];
      case 'paymentMethod.card' as const:
        return {
          card,
        } satisfies PaymentControlOverrides['paymentMethod.card'];
      default:
        return {
          placeholder: field(key).placeholder,
          ...form.register(key),
        };
    }
  };

  return {
    t,
    bindPaymentFormField: paymentFormField,
    bindPaymentControl: paymentControl,
    hasPaymentMethod: !!form.watch('paymentMethod'),
  };
};
