import { injectable, injectFromBase } from 'inversify';

import { DbCollectionCreator } from '@/features/system/db';
import { CollectionName } from '@/features/system/db';

import { IContactByFiltersDC } from '../dataContracts';

import { contactByFiltersMigrationStrategies } from './migration';
import { contactByFiltersSchema } from './schema';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class ContactByFiltersCollectionCreator extends DbCollectionCreator<IContactByFiltersDC> {
  constructor() {
    super({
      collectionName: CollectionName.ContactByFilters,
      schema: contactByFiltersSchema,
      migrationStrategies: contactByFiltersMigrationStrategies,
    });
  }
}
