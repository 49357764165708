import { injectable, injectFromBase } from 'inversify';
import { mergeDeepRight } from 'ramda';
import { MangoQuery } from 'rxdb';
import { map, Observable } from 'rxjs';

import { IContactListDC } from '@/features/common/contactList';
import { CollectionName, DbCollection, IDbCollection } from '@/features/system/db';

import { ContactListSortSchema } from '../../../domain/types';

const defaultSortSchema = { created_at: 'asc' } as ContactListSortSchema;

export type IContactListDao = IDbCollection<IContactListDC, 'uuid'> & {
  getContactListByQuery: (payload: {
    nameReg?: string;
    created_by?: string;
    sortSchema?: ContactListSortSchema;
  }) => Observable<IContactListDC[]>;
  getDefaultContactList: () => Observable<IContactListDC | null>;
};

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export default class ContactListDao
  extends DbCollection<IContactListDC, 'uuid'>
  implements IContactListDao
{
  constructor() {
    super({ collectionName: CollectionName.ContactList, idKey: 'uuid' });
  }

  private mapContactListDocsByDefault(docs: IContactListDC[]): IContactListDC[] {
    let defaultContactList: IContactListDC | undefined = undefined;

    const result: IContactListDC[] = [];

    docs.forEach((doc) => {
      if (doc.special_type === 'all_contacts') {
        defaultContactList = doc;
      } else {
        result.push(doc);
      }
    });

    if (defaultContactList) {
      result.unshift(defaultContactList);
    }

    return result;
  }

  getContactListByQuery = ({
    nameReg = '',
    created_by,
    sortSchema,
  }: {
    nameReg?: string;
    created_by?: string;
    sortSchema?: ContactListSortSchema;
  }): Observable<IContactListDC[]> => {
    const nameSelector = nameReg ? { name: { $regex: nameReg, $options: 'i' } } : {};
    const createdBySelector = created_by ? { created_by } : {};

    return this.findAll({
      selector: {
        $or: [
          {
            special_type: {
              $regex: 'all_contacts',
              $options: 'i',
            },
          },
          {
            ...nameSelector,
            ...createdBySelector,
          },
        ],
      },
      sort: [sortSchema || defaultSortSchema],
    });
  };

  getDefaultContactList = (): Observable<IContactListDC | null> => {
    return this.findOne({
      selector: {
        special_type: {
          $regex: 'all_contacts',
          $options: 'i',
        },
      },
    });
  };

  override findAll = (
    params: MangoQuery<IContactListDC> = {},
  ): Observable<IContactListDC[]> => {
    /**
     * Override default findAll method to return all contact lists with default contact list first
     */

    const defaultQuery: MangoQuery<IContactListDC> = {
      sort: [{ created_at: 'asc' }],
    };

    const query = mergeDeepRight(defaultQuery, params);

    return super.findAll(query).pipe(map(this.mapContactListDocsByDefault));
  };
}
