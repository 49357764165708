import { type Container } from 'inversify';

import { ANALYTICS_TYPES } from '@/ioc/types';

import {
  AmplitudeRepository,
  FacebookPixelRepository,
  GoogleTagManagerRepository,
  IFacebookPixelRepository,
  IGoogleTagManagerRepository,
} from '../data';
import { type IAmplitudeRepository } from '../data/AmplitudeRepository';
import {
  CustomerIORepository,
  type ICustomerIORepository,
} from '../data/CustomerIORepository';
import { AnalyticsUseCase } from '../domain';

export const bindAnalyticsModule = (container: Container): void => {
  container
    .bind<IAmplitudeRepository>(ANALYTICS_TYPES.AmplitudeRepository)
    .to(AmplitudeRepository)
    .inSingletonScope();

  container
    .bind<IGoogleTagManagerRepository>(ANALYTICS_TYPES.GoogleTagManagerRepository)
    .to(GoogleTagManagerRepository)
    .inSingletonScope();

  container
    .bind<ICustomerIORepository>(ANALYTICS_TYPES.CustomerIORepository)
    .to(CustomerIORepository)
    .inSingletonScope();

  container
    .bind<IFacebookPixelRepository>(ANALYTICS_TYPES.FacebookPixelRepository)
    .to(FacebookPixelRepository)
    .inSingletonScope();

  container.bind<AnalyticsUseCase>(ANALYTICS_TYPES.AnalyticsUseCase).to(AnalyticsUseCase);
};
