import { BaseSyntheticEvent, ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router';
import { Base64 } from 'js-base64';
import * as yup from 'yup';

import { ROUTES } from '@/router/routes';

import { useInjection } from '@/ioc/ioc.react';
import { LOGIN_TYPES } from '@/ioc/types';

import {
  InvalidCredential,
  InvalidEmailError,
  InvalidWorkEmailError,
  UserNotFoundError,
  WrongPasswordError,
} from '@/features/common/auth';
import { ISignInUseCase } from '@/features/signin';
import { useAnalytics } from '@/features/system/analytics';
import { sentryCaptureException } from '@/features/system/sentry';

import { EmailValidationSchema, useFormWithSchema } from '@/utils/validation';

import GoogleIcon from '@/assets/icons/Google.svg?react';
import MicrosoftIcon from '@/assets/icons/Microsoft.svg?react';

import styles from './styles.module.scss';

const LoginFormSchema = yup.object({
  email: EmailValidationSchema,
  password: yup.string().required('passwordRequired'),
});

type LoginFormType = yup.InferType<typeof LoginFormSchema>;

enum AuthIdentityProvider {
  password = 'password',
  google = 'google.com',
  microsoft = 'microsoft.com',
}

const providers = [
  {
    name: AuthIdentityProvider.google,
    formattedName: 'Google',
    icon: GoogleIcon,
  },
  {
    name: AuthIdentityProvider.microsoft,
    formattedName: 'Microsoft',
    icon: MicrosoftIcon,
  },
];

export const useLoginViewModel = () => {
  const loginUseCases = useInjection<ISignInUseCase>(LOGIN_TYPES.SignInUseCase);
  const { t } = useTranslation('auth');
  const form = useFormWithSchema(LoginFormSchema, {
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState<{ type: string; text: ReactNode } | undefined>();
  const {
    trackSigninPageView,
    trackSigninError,
    trackSigninButtonClick,
    trackSigninSuccess,
  } = useAnalytics();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      trackSigninPageView();
    }, 500);

    const email = searchParams.get('email');

    if (email) {
      try {
        const emailFromParams = Base64.decode(email);
        form.setValue('email', emailFromParams);
      } catch (err) {
        sentryCaptureException('Invalid email in search params');
      }

      searchParams.delete('email');
      setSearchParams(searchParams);
    }

    return (): void => {
      clearTimeout(timeOut);
    };
  }, []);

  const handleErrorOfProvider = (error: unknown): void => {
    if (error instanceof UserNotFoundError) {
      trackSigninError('wrong email');
      setError({
        type: 'google',
        text: (
          <Trans t={t} i18nKey={'signin.errors.cantFind'}>
            We can’t find this email. Try another email or
            <Link to={ROUTES.SIGN_UP} className={styles.linkCreate}>
              sign up
            </Link>
          </Trans>
        ),
      });
    }

    if (error instanceof InvalidCredential) {
      setError({
        type: 'google',
        text: (
          <Trans t={t} i18nKey={'signin.errors.invalidCredential'}>
            Verify you have logged in to the federated identity provider correctly
          </Trans>
        ),
      });
    }

    if (error instanceof InvalidWorkEmailError) {
      setError({
        type: 'google',
        text: (
          <Trans t={t} i18nKey={'signup.errors.validEmail'}>
            Enter a valid work email
          </Trans>
        ),
      });
    }
  };

  const handleErrorOfLoginAndPasswordProvider = (error: unknown): void => {
    if (error instanceof InvalidEmailError) {
      trackSigninError('wrong email');
      setError({
        type: 'email',
        text: (
          <Trans t={t} i18nKey={'signin.errors.cantFind'}>
            We can’t find this email. Try another email or
            <Link to={ROUTES.SIGN_UP} className={styles.linkCreate}>
              sign up
            </Link>
          </Trans>
        ),
      });
      return;
    }

    if (error instanceof UserNotFoundError) {
      trackSigninError('wrong email');
      setError({
        type: 'email',
        text: (
          <Trans t={t} i18nKey={'signin.errors.cantFind'}>
            We can’t find this email. Try another email or
            <Link to={ROUTES.SIGN_UP} className={styles.linkCreate}>
              sign up
            </Link>
          </Trans>
        ),
      });
      return;
    }

    if (error instanceof WrongPasswordError) {
      trackSigninError('wrong password');

      setError({
        type: 'password',
        text: t('signin.errors.password'),
      });
    }
  };

  const loginWithProvider = async (providerName: AuthIdentityProvider): Promise<void> => {
    trackSigninButtonClick(providerName);

    const login = {
      [AuthIdentityProvider.google]: loginUseCases.loginGoogle.bind(loginUseCases),
      [AuthIdentityProvider.microsoft]: loginUseCases.loginMicrosoft.bind(loginUseCases),
    }[providerName];

    try {
      await login();
      trackSigninSuccess(providerName);
    } catch (error) {
      handleErrorOfProvider(error);
    }
  };

  const loginWithEmailAndPass = (e: BaseSyntheticEvent): void => {
    trackSigninButtonClick(AuthIdentityProvider.password);
    void form.handleSubmit(async ({ email, password }: LoginFormType) => {
      try {
        await loginUseCases.loginWithEmailAndPass(email, password);
        trackSigninSuccess(AuthIdentityProvider.password);
      } catch (error) {
        handleErrorOfLoginAndPasswordProvider(error);
      }
    })(e);
  };

  const email = form.watch('email');
  const encodedEmail = Base64.encode(email);
  const signUpRoute = email ? `${ROUTES.SIGN_UP}?email=${encodedEmail}` : ROUTES.SIGN_UP;
  const forgotPasswordRoute = email
    ? `${ROUTES.FORGOT_PASSWORD}?email=${encodedEmail}`
    : ROUTES.FORGOT_PASSWORD;

  return {
    providers,
    loginWithProvider,
    loginWithEmailAndPass,
    error,
    form,
    signUpRoute,
    forgotPasswordRoute,
  };
};
