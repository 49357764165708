import { useAnalytics } from '@/features/system/analytics';

export enum PaymentFailedReason {
  ServerError = 'Server error',
  CardDetails = 'Card details',
  EmptyFields = 'Empty fields',
}

type UsePaymentDetailsAnalytic = () => {
  paymentSuccess: (params: { name: string }) => void;
  paymentFailed: (name: string, reason: PaymentFailedReason) => void;
  clickSubmitPurchase: () => void;
  pageViewed: () => void;
};

export const usePaymentDetailsAnalytic: UsePaymentDetailsAnalytic = () => {
  const {
    trackPaymentDetailsPageView,
    trackPaymentSuccess,
    trackPaymentFailed,
    trackSubmitPurchaseClick,
  } = useAnalytics();

  return {
    pageViewed: (): void => {
      trackPaymentDetailsPageView();
    },
    paymentSuccess: (params: { name: string }): void => {
      trackPaymentSuccess(`${params.name}`);
    },
    paymentFailed: (name: string, reason: PaymentFailedReason): void => {
      trackPaymentFailed(name, reason);
    },
    clickSubmitPurchase: (): void => {
      trackSubmitPurchaseClick();
    },
  };
};
