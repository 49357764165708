import { FC } from 'react';

import { Modal } from '@/components';

import { DefaultStep } from './components/DefaultStep';
import { OfferStep } from './components/OfferStep';
import { ReasonStep } from './components/ReasonStep';
import { useCancelSubscriptionViewModel } from './useCancelSubscriptionViewModel';

import styles from './styles.module.scss';

const CancelSubscriptionStep: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { step, goToReasonStep, goToOfferStep } = useCancelSubscriptionViewModel();

  switch (step.viewType) {
    case 'reason':
      return <ReasonStep onClose={onClose} onGoToNextStep={goToOfferStep} />;
    case 'offer':
      return <OfferStep reason={step.state.reason} onClose={onClose} />;
    default:
      return <DefaultStep onClose={onClose} onGoToNextStep={goToReasonStep} />;
  }
};

export const CancelSubscription: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      keepMounted={false}
      containerClass={styles.modal}
    >
      <CancelSubscriptionStep onClose={onClose} />
    </Modal>
  );
};
