import { StripeError } from '@stripe/stripe-js';

import { BillingError } from './BillingError';

export class PaymentError extends BillingError {
  constructor(message = 'Payment error') {
    super(message);
  }

  static fromStripeError(error: StripeError): PaymentError {
    return new PaymentError(error.message);
  }

  static paymentProviderLoadingError(): PaymentError {
    return new PaymentError('We have issues with payment provider loading.');
  }

  static minimumAmountError(): PaymentError {
    return new PaymentError('Payment amount is too low. Monimum amount is $0.50');
  }
}
