import * as React from "react";
const SvgIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 226.788, height: 229.323, viewBox: "0 0 226.788 229.323", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "wxf4y4nl1a" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19466", style: {
  fill: "none"
}, d: "M0 0h31.562v18.222H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "156tas908b" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19467", style: {
  fill: "none"
}, d: "M0 0h15.782v18.222H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "reluz6v65c" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19468", style: {
  fill: "none"
}, d: "M0 0h7.162v15.264H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "xyt527vkzd" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19471", style: {
  fill: "none"
}, d: "M0 0h6.853v12.021H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "gsjp4xfdre" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19473", style: {
  fill: "none"
}, d: "M0 0h29.339v16.939H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "kq7vzii8ef" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19474", style: {
  fill: "none"
}, d: "M0 0h10.639v9.136H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "46riyzum0g" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19475", style: {
  fill: "none"
}, d: "M0 0h3.943v4.27H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "wxexxmh7ah" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19476", style: {
  fill: "none"
}, d: "M0 0h18.724v14.48H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "ig6bpmf59i" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19477", style: {
  fill: "none"
}, d: "M0 0h21.858v12.62H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "qevypd0ecj" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19478", style: {
  fill: "none"
}, d: "M0 0h20.893v12.062H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "prmtnt3akk" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19479", style: {
  fill: "none"
}, d: "M0 0h20.688v10.38H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "1rjlvvexol" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19480", style: {
  fill: "none"
}, d: "M0 0h13.673v7.744H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "8ule7agrom" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19481", style: {
  fill: "none"
}, d: "M0 0h2.937v.923H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "et86i61ktn" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19482", style: {
  fill: "none"
}, d: "M0 0h3.848v1.691H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "d5o5zirxvo" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19483", style: {
  fill: "none"
}, d: "M0 0h5.286v3.697H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "sbc3zohfsp" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19484", style: {
  fill: "none"
}, d: "M0 0h1.599v1.687H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "wmcwhdzd0q" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19485", style: {
  fill: "none"
}, d: "M0 0h4.066v1.427H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "r4ql41xbwr" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19486", style: {
  fill: "none"
}, d: "M0 0h2.137v1.522H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "xbr0gv5j9s" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19487", style: {
  fill: "none"
}, d: "M0 0h3.35v3.588H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "suj7tw3jxt" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19504", style: {
  fill: "none"
}, d: "M0 0h3.847v1.691H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "zayorvqk0u" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19522", style: {
  fill: "none"
}, d: "M0 0h20.893v12.063H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "7x0omqkw2v" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19532", style: {
  fill: "none"
}, d: "M0 0h27.178v21.999H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "0ljd1d633w" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19533", style: {
  fill: "none"
}, d: "M0 0h17.627v21.105H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "sq7cww3oex" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19534", style: {
  fill: "none"
}, d: "M0 0h10.077v16.929H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "lgafxvuq8y" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19535", style: {
  fill: "none"
}, d: "M0 0h9.41v16.929H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "9u7qkv0u4z" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19536", style: {
  fill: "none"
}, d: "M0 0h26.504v21.999H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "aqig382kxA" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19537", style: {
  fill: "none"
}, d: "M0 0h4.025v14.65H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "iq6vzsrllB" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19538", style: {
  fill: "none"
}, d: "M0 0h4.024v14.65H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "fw50gfcjjC" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19539", style: {
  fill: "none"
}, d: "M0 0h24.637v20.45H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "4e2sneqysD" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19540", style: {
  fill: "none"
}, d: "M0 0h10.229v10.48H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "h44ff830xE" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19541", style: {
  fill: "none"
}, d: "M0 0h4.012v4.564H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "tzkmzedrzF" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19542", style: {
  fill: "none"
}, d: "M0 0h18.895v14.053H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "2p1dzrwe7G" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19543", style: {
  fill: "none"
}, d: "M0 0h18.355v15.235H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "sv3pp1axqH" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19544", style: {
  fill: "none"
}, d: "M0 0h17.544v14.563H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "zs60z4v15I" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19545", style: {
  fill: "none"
}, d: "M0 0h16.859v12.697H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "kptvxcp54J" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19546", style: {
  fill: "none"
}, d: "M0 0h10.251v11.468H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "e5vxiusv3K" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19547", style: {
  fill: "none"
}, d: "M0 0h2.429v1.226H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "kghotwdhaL" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19548", style: {
  fill: "none"
}, d: "M0 0h3.068v2.286H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "n0k2skt7jM" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19549", style: {
  fill: "none"
}, d: "M0 0h3.616v4.997H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "4xcpgeqvvN" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19550", style: {
  fill: "none"
}, d: "M0 0h1.664v1.795H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "00wn84tutO" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19551", style: {
  fill: "none"
}, d: "M0 0h3.858v1.176H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "o82e8ig69P" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19552", style: {
  fill: "none"
}, d: "M0 0h1.525v1.925H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "sblbg1p4yQ" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19553", style: {
  fill: "none"
}, d: "M0 0h1.957v4.625H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "tby7nkb2vR" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19554", style: {
  fill: "none"
}, d: "M0 0h26.411v15.248H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "95w3hbrhwS" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19555", style: {
  fill: "none"
}, d: "M0 0h13.206v15.248H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "o3puewef1T" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19556", style: {
  fill: "none"
}, d: "M0 0h5.993v12.773H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "qzj5s700gU" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19559", style: {
  fill: "none"
}, d: "M0 0h5.735v10.059H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "1l8ui3b87V" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19561", style: {
  fill: "none"
}, d: "M0 0h24.551v14.175H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "b5o0xbdnyW" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19562", style: {
  fill: "none"
}, d: "M0 0h8.903v7.645H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "0e6tlh3xrX" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19563", style: {
  fill: "none"
}, d: "M0 0h3.3v3.573H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "uhnyelcniY" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19564", style: {
  fill: "none"
}, d: "M0 0h15.669v12.117H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "kiqvpffaiZ" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19565", style: {
  fill: "none"
}, d: "M0 0h18.291v10.56H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "oe6t937yoaa" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19566", style: {
  fill: "none"
}, d: "M0 0h17.484v10.094H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "ydwlu6emsab" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19567", style: {
  fill: "none"
}, d: "M0 0h17.312v8.686H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "s5s2du2pmac" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19568", style: {
  fill: "none"
}, d: "M0 0h11.441v6.48H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "tp1ynklqjad" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19569", style: {
  fill: "none"
}, d: "M0 0h2.458v.772H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "4o7d44z7lae" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19570", style: {
  fill: "none"
}, d: "M0 0h3.219v1.415H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "z9vntq720af" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19571", style: {
  fill: "none"
}, d: "M0 0h4.423v3.093H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "2012gdijnag" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19572", style: {
  fill: "none"
}, d: "M0 0h1.337v1.411H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "ki99t789lah" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19573", style: {
  fill: "none"
}, d: "M0 0h3.402v1.194H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "8qusk53ybai" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19574", style: {
  fill: "none"
}, d: "M0 0h1.788v1.274H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "aye39piwkaj" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19575", style: {
  fill: "none"
}, d: "M0 0h2.803v3.003H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "o1jl5rg6eak" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19576", style: {
  fill: "none"
}, d: "M0 0h26.412v15.248H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "4zpxutq2qal" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19578", style: {
  fill: "none"
}, d: "M0 0h5.994v12.773H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "0gs2fo2ekam" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19582", style: {
  fill: "none"
}, d: "M0 0h5.735v10.06H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "7du574fufan" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19583", style: {
  fill: "none"
}, d: "M0 0h24.552v14.175H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "il2gc45k9ao" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19588", style: {
  fill: "none"
}, d: "M0 0h17.483v10.094H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "3ykp529bmap" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19590", style: {
  fill: "none"
}, d: "M0 0h11.442v6.48H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "60pt4crhgaq" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19592", style: {
  fill: "none"
}, d: "M0 0h3.22v1.415H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "1pr1pjo4aar" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19594", style: {
  fill: "none"
}, d: "M0 0h1.338v1.412H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "n27r1wyzpas" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19595", style: {
  fill: "none"
}, d: "M0 0h3.403v1.194H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "0gl3ce21iat" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19598", style: {
  fill: "none"
}, d: "M0 0h26.411v15.249H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "ratzvo14cau" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19599", style: {
  fill: "none"
}, d: "M0 0h13.206v15.249H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "g46c00bkmav" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19603", style: {
  fill: "none"
}, d: "M0 0h5.734v10.06H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "upnl1u39baw" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19604", style: {
  fill: "none"
}, d: "M0 0h5.734v10.059H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "0htxzyyhyax" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19612", style: {
  fill: "none"
}, d: "M0 0h11.441v6.481H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "r4mjs5k4eay" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19615", style: {
  fill: "none"
}, d: "M0 0h4.423v3.094H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "8uozfx4tcaz" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19616", style: {
  fill: "none"
}, d: "M0 0h1.338v1.411H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "7xfpzhvrpaA" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19640", style: {
  fill: "none"
}, d: "M0 0h1.789v1.274H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "c9vbyi1lgaB" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19655", style: {
  fill: "none"
}, d: "M0 0h17.312v8.687H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "tdcxx7etvaC" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19731", style: {
  fill: "none"
}, d: "M0 0h8.045v10.207H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "sbh2gewt8aD" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19732", style: {
  fill: "none"
}, d: "M0 0h4.563v6.872H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "9wd0dmhihaE" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19733", style: {
  fill: "#fff"
}, d: "M0 0h50.503v50.518H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "sj490sut2aF" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19734", style: {
  fill: "none"
}, d: "M0 0h162.2v150.68H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "1mz3b6mwaaG" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19735", style: {
  fill: "none"
}, d: "M0 0h24.394v24.393H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "dpv4f659iaH" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19736", style: {
  fill: "none"
}, d: "M0 0h8.521v22.448H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "0qpsc7edcaI" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19737", style: {
  fill: "#cb3f00"
}, d: "M0 0h144.857v132.658H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "uyebow8edaJ" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19738", style: {
  fill: "none"
}, d: "M0 0h34.223v40.766H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "kyfc84jruaK" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19739", style: {
  fill: "#cb3f00"
}, d: "M0 0h30.429v18.083H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "asde3jxt1aL" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19740", style: {
  fill: "none"
}, d: "M0 0h38.838v54.352H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "6upjlrd0taM" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19741", style: {
  fill: "#cb3f00"
}, d: "M0 0h29.384v42.538H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "srtlmmuz1aN" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19742", style: {
  fill: "none"
}, d: "M0 0h45.556v39.234H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "l613kc7vcaO" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19743", style: {
  fill: "none"
}, d: "M0 0h46.338v34.423H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "18gp241wxaP" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19744", style: {
  fill: "none"
}, d: "M0 0h44.089v53.705H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "qjn547yq5aQ" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19745", style: {
  fill: "none"
}, d: "M0 0h3.585v16.516H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "vf7feewj8aR" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19748", style: {
  fill: "none"
}, d: "M0 0h28.237v16.563H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "2ypua9jgpaS" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19747", style: {
  fill: "#fff"
}, d: "M0 0h32.762v38.169H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "tg44tk2ufaT" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Rectangle 19749", style: {
  fill: "none"
}, d: "M0 0h5.187v4.437H0z" }))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56234" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56235" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57091" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33278", d: "M33.213 308.774c-44.283-25.567-44.284-67.02 0-92.586s116.08-25.567 160.363 0 44.284 67.02 0 92.586-116.08 25.566-160.363 0", transform: "translate(0 -98.626)", style: {
  fill: "rgba(0,0,0,.29)"
} }))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33279", d: "M47.729 369.492c7.235 4.177 7.235 10.95 0 15.127s-18.966 4.177-26.2 0-7.235-10.95 0-15.127 18.966-4.178 26.2 0", transform: "translate(-8.074 -183.552)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33280", d: "M88.651 389.632c7.235 4.178 7.235 10.95 0 15.127s-18.966 4.178-26.2 0-7.235-10.95 0-15.127 18.966-4.177 26.2 0", transform: "translate(-28.596 -193.652)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33281", d: "M95.723 326.323 86.85 321.2c-23.2-13.4-23.2-35.114 0-48.51l39.839-23c23.2-13.4 60.82-13.4 84.022 0l8.873 5.123c23.2 13.4 23.2 35.114 0 48.51l-39.839 23c-23.2 13.4-60.82 13.4-84.022 0", transform: "translate(-34.828 -120.005)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33282", d: "M399.26 352.452c6.372 3.68 6.372 9.644 0 13.324s-16.7 3.679-23.077 0-6.372-9.644 0-13.324 16.7-3.679 23.077 0", transform: "translate(-186.256 -175.194)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33283", d: "M346.895 387.661c6.372 3.679 6.372 9.644 0 13.324s-16.7 3.679-23.077 0-6.372-9.644 0-13.324 16.705-3.679 23.077 0", transform: "translate(-159.995 -192.851)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33284", d: "M21.514 373.6a10.173 10.173 0 0 1 3.77-3.486c6.163-3.558 16.155-3.558 22.317 0a10.18 10.18 0 0 1 3.77 3.486h.853v3.224h-.011c-.113 2.242-1.648 4.464-4.611 6.175-6.162 3.558-16.154 3.558-22.317 0-2.964-1.711-4.509-3.933-4.623-6.175V373.6z", transform: "translate(-10.362 -184.099)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56240", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56239" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56238", style: {
  clipPath: "url(#wxf4y4nl1a)"
}, transform: "translate(10.3 183.35)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33285", d: "M.853 6.15a10.173 10.173 0 0 1 3.77-3.486c6.163-3.558 16.155-3.558 22.317 0a10.18 10.18 0 0 1 3.77 3.486h.853v3.224h-.011c-.113 2.242-1.648 4.464-4.611 6.175-6.162 3.558-16.154 3.558-22.317 0C1.66 13.838.115 11.616.001 9.374V6.15z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56243", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56242" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56241", style: {
  clipPath: "url(#156tas908b)"
}, transform: "translate(26.08 183.35)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33286", d: "M0 0a22.811 22.811 0 0 1 11.16 2.669 10.18 10.18 0 0 1 3.77 3.486h.855v3.224h-.011c-.114 2.242-1.648 4.464-4.611 6.175a22.806 22.806 0 0 1-11.16 2.668z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56246", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56245" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56244", style: {
  clipPath: "url(#reluz6v65c)"
}, transform: "translate(34.699 184.83)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33287", d: "M0 0a17.232 17.232 0 0 1 2.54 1.189 10.18 10.18 0 0 1 3.77 3.486h.852v3.224h-.011c-.114 2.242-1.648 4.464-4.611 6.175A17.285 17.285 0 0 1 0 15.263z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56249", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56248" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56247", style: {
  clipPath: "url(#reluz6v65c)"
}, transform: "translate(10.3 184.83)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33288", d: "M27.823 370.417a17.309 17.309 0 0 0-2.54 1.189 10.18 10.18 0 0 0-3.77 3.486h-.852v3.224c.114 2.242 1.659 4.464 4.622 6.175a17.256 17.256 0 0 0 2.54 1.189z", transform: "translate(-20.661 -370.417)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33289", d: "M47.6 364.19c6.163 3.558 6.162 9.327 0 12.885-5.392 3.113-13.716 3.5-19.841 1.168l-.245-.1a17.089 17.089 0 0 1-2.231-1.072c-5.139-2.967-5.993-7.471-2.56-10.949a12 12 0 0 1 2.558-1.934c6.163-3.558 16.155-3.558 22.318 0", transform: "translate(-10.362 -181.126)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56252", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56251" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56250", style: {
  clipPath: "url(#wxf4y4nl1a)"
}, transform: "translate(10.3 180.396)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33290", d: "M26.939 2.668c6.163 3.558 6.162 9.327 0 12.885-5.392 3.113-13.716 3.5-19.841 1.168l-.245-.1a17.089 17.089 0 0 1-2.231-1.072C-.517 12.582-1.371 8.078 2.062 4.6A12 12 0 0 1 4.62 2.666c6.163-3.558 16.155-3.558 22.318 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56255", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56254" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56253", style: {
  clipPath: "url(#xyt527vkzd)"
}, transform: "translate(10.3 185)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33291", d: "M22.724 370.758a6.9 6.9 0 0 0-1.651 5.621c.546 3.131 4.931 5.767 6.442 6.4a17.09 17.09 0 0 1-2.231-1.072c-5.139-2.968-5.994-7.471-2.56-10.949", transform: "translate(-20.662 -370.758)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56258", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56257" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56256", style: {
  clipPath: "url(#xyt527vkzd)"
}, transform: "translate(35.008 181.992)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33292", d: "M75.017 376.746a6.9 6.9 0 0 0 1.651-5.621c-.546-3.131-4.931-5.767-6.442-6.4a17.1 17.1 0 0 1 2.231 1.072c5.14 2.967 5.994 7.471 2.56 10.949", transform: "translate(-70.226 -364.724)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33293", d: "M27.2 377.242c-5.729-3.308-5.729-8.67 0-11.978s15.017-3.308 20.746 0 5.729 8.67 0 11.978-15.017 3.308-20.746 0", transform: "translate(-11.486 -181.759)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56261", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56260" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56259", style: {
  clipPath: "url(#gsjp4xfdre)"
}, transform: "translate(11.417 181.025)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33294", d: "M27.2 377.242c-5.729-3.308-5.729-8.67 0-11.978s15.017-3.308 20.746 0 5.729 8.67 0 11.978-15.017 3.308-20.746 0", transform: "translate(-22.903 -362.784)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56264", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56263" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56262", style: {
  clipPath: "url(#kq7vzii8ef)"
}, transform: "translate(11.416 183.141)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33295", d: "M23.568 375.912c-1.179-2.18-.795-4.582 1.152-6.62a9.859 9.859 0 0 1 1.243-1.089 12.6 12.6 0 0 1 1.188-.783c.234-.137.474-.268.722-.392-.61 1-.386 2.078 3.069 2.28 6.761.4-1.807 6.857-1.807 6.857s-4.358-2.1-5.567-.253", transform: "translate(-22.901 -367.029)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56267", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56266" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56265", style: {
  clipPath: "url(#46riyzum0g)"
}, transform: "translate(11.523 185.976)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33296", d: "M24.373 376.977c-1.014-.114-1.517-1.157-1.123-2.331a2.678 2.678 0 0 1 2.551-1.919c1.014.114 1.517 1.157 1.123 2.331a2.679 2.679 0 0 1-2.551 1.919", transform: "translate(-23.116 -372.717)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33297", d: "M45.631 379.852c.333-2.51 11.308-9.462 11.308-9.462s2.434-.977 1.626-2.678a12.718 12.718 0 0 1 1.173.759q.381.275.713.565c3.267 2.83 3.271 6.546 0 9.378-.221.192-.46.381-.712.565a12.649 12.649 0 0 1-1.088.708c-.372.218-.761.42-1.163.609a18.115 18.115 0 0 1-2.018.797 21.551 21.551 0 0 1-3.275.787c-.244.039-.491.075-.736.107a25.276 25.276 0 0 1-3.274.205 24.844 24.844 0 0 1-4.006-.328 1.759 1.759 0 0 0 1.452-2.012", transform: "translate(-22.155 -184.231)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56270", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56269" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56268", style: {
  clipPath: "url(#wxexxmh7ah)"
}, transform: "translate(22.024 183.482)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33298", d: "M45.631 379.852c.333-2.51 11.308-9.462 11.308-9.462s2.434-.977 1.626-2.678a12.718 12.718 0 0 1 1.173.759q.381.275.713.565c3.267 2.83 3.271 6.546 0 9.378-.221.192-.46.381-.712.565a12.649 12.649 0 0 1-1.088.708c-.372.218-.761.42-1.163.609a18.115 18.115 0 0 1-2.018.797 21.551 21.551 0 0 1-3.275.787c-.244.039-.491.075-.736.107a25.276 25.276 0 0 1-3.274.205 24.844 24.844 0 0 1-4.006-.328 1.759 1.759 0 0 0 1.452-2.012", transform: "translate(-44.179 -367.712)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33299", d: "M49.073 368.964c4.094 2.364 4.26 6.135.5 8.614-.16.1-.327.209-.5.31a17.077 17.077 0 0 1-15.454 0c-4.268-2.464-4.27-6.459 0-8.923.173-.1.354-.2.536-.289 4.294-2.172 10.827-2.075 14.921.289", transform: "translate(-15.254 -183.931)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56273", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56272" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56271", style: {
  clipPath: "url(#ig6bpmf59i)"
}, transform: "translate(15.162 183.184)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33300", d: "M18.657 1.849c4.094 2.364 4.26 6.135.5 8.614-.16.1-.327.209-.5.31a17.077 17.077 0 0 1-15.454 0c-4.268-2.464-4.27-6.459 0-8.923.173-.1.354-.2.536-.289C8.033-.611 14.566-.514 18.66 1.85" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33301", d: "M49.206 369.44c4.08 2.355 4.08 6.174 0 8.529a16.326 16.326 0 0 1-14.773 0c-4.08-2.355-4.08-6.174 0-8.529a16.326 16.326 0 0 1 14.773 0", transform: "translate(-15.734 -184.211)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56276", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56275" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56274", style: {
  clipPath: "url(#qevypd0ecj)"
}, transform: "translate(15.639 183.462)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33302", d: "M17.833 1.766c4.08 2.355 4.08 6.174 0 8.529a16.326 16.326 0 0 1-14.773 0c-4.08-2.355-4.08-6.174 0-8.529a16.326 16.326 0 0 1 14.773 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33303", d: "M49.309 372.815a16.326 16.326 0 0 0-14.773 0 3.46 3.46 0 0 0 0 6.847 16.326 16.326 0 0 0 14.773 0 3.46 3.46 0 0 0 0-6.847", transform: "translate(-15.836 -185.904)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56279", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56278" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56277", style: {
  clipPath: "url(#prmtnt3akk)"
}, transform: "translate(15.742 185.145)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33304", d: "M17.731 1.767a16.326 16.326 0 0 0-14.773 0 3.46 3.46 0 0 0 0 6.847 16.326 16.326 0 0 0 14.773 0 3.46 3.46 0 0 0 0-6.847" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33305", d: "M52.05 377.684v-1.247c0-.511-.354-1.008-1.115-1.453-.008-.007-.018-.012-.026-.017a5.356 5.356 0 0 0-4.143-.379h-.011c-.549.139-1.07.317-1.546.48a15.26 15.26 0 0 1-1.66.514 2.935 2.935 0 0 1-.789.075 1.678 1.678 0 0 1-.653-.173l.052-.036c.051-.034.106-.068.164-.1a3.712 3.712 0 0 1 .437-.217 3.03 3.03 0 0 1 2.323-.053l1.339-1.351v-1.24a6.369 6.369 0 0 0-3.662-.133 7.534 7.534 0 0 0-1.164.4l-1.339-.773-1.6.923v1.24l.547.316a1.323 1.323 0 0 0-.235.723v1.237c0 .512.367 1.006 1.138 1.451a4.671 4.671 0 0 0 2.207.636c.146.007.3 0 .441 0A8.746 8.746 0 0 0 45.13 378q.236-.079.462-.159l-1.348 1.352v1.24a6.573 6.573 0 0 0 5.166-.224l1.323.764 1.6-.923v-1.24l-.549-.317a1.448 1.448 0 0 0 .27-.806m-3.37-.107a3.333 3.333 0 0 1-.371.185 3.437 3.437 0 0 1-2.614.039c1.251-.441 2.3-.827 3.187-.354a3.648 3.648 0 0 1-.2.13", transform: "translate(-19.386 -186.374)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33306", d: "M46.767 374.589h-.011c-.549.139-1.07.317-1.546.48a15.222 15.222 0 0 1-1.659.514 2.932 2.932 0 0 1-.789.075 1.684 1.684 0 0 1-.76-.227c-.514-.3-.589-.732.159-1.222.051-.034.107-.068.165-.1a3.713 3.713 0 0 1 .437-.217 3.029 3.029 0 0 1 2.323-.053l1.339-1.351a6.369 6.369 0 0 0-3.662-.133 7.515 7.515 0 0 0-1.164.4l-1.339-.773-1.6.923 1.324.765-.012.01c-1.343 1.021-1.371 2.08.141 2.953a4.677 4.677 0 0 0 2.207.636c.147.007.3 0 .442 0a8.75 8.75 0 0 0 2.371-.512c1.533-.513 2.776-1.118 3.81-.521.692.4.5.9-.259 1.341a3.384 3.384 0 0 1-.371.185 3.434 3.434 0 0 1-2.662.019l-1.4 1.408a6.571 6.571 0 0 0 5.165-.224l1.323.764 1.6-.923-1.323-.764c1.33-1.028 1.483-2.149-.07-3.058a.25.25 0 0 0-.026-.017 5.355 5.355 0 0 0-4.143-.379", transform: "translate(-19.386 -186.374)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56282", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56281" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56280", style: {
  clipPath: "url(#1rjlvvexol)"
}, transform: "translate(19.271 185.612)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33307", d: "M46.767 374.589h-.011c-.549.139-1.07.317-1.546.48a15.222 15.222 0 0 1-1.659.514 2.932 2.932 0 0 1-.789.075 1.684 1.684 0 0 1-.76-.227c-.514-.3-.589-.732.159-1.222.051-.034.107-.068.165-.1a3.713 3.713 0 0 1 .437-.217 3.029 3.029 0 0 1 2.323-.053l1.339-1.351a6.369 6.369 0 0 0-3.662-.133 7.515 7.515 0 0 0-1.164.4l-1.339-.773-1.6.923 1.324.765-.012.01c-1.343 1.021-1.371 2.08.141 2.953a4.677 4.677 0 0 0 2.207.636c.147.007.3 0 .442 0a8.75 8.75 0 0 0 2.371-.512c1.533-.513 2.776-1.118 3.81-.521.692.4.5.9-.259 1.341a3.384 3.384 0 0 1-.371.185 3.434 3.434 0 0 1-2.662.019l-1.4 1.408a6.571 6.571 0 0 0 5.165-.224l1.323.764 1.6-.923-1.323-.764c1.33-1.028 1.483-2.149-.07-3.058a.25.25 0 0 0-.026-.017 5.355 5.355 0 0 0-4.143-.379", transform: "translate(-38.657 -371.986)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56285", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56284" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56283", style: {
  clipPath: "url(#8ule7agrom)"
}, transform: "translate(19.272 185.612)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33308", d: "m38.659 372.909 1.614-.731 1.324.582-1.339-.773z", transform: "translate(-38.659 -371.987)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56288", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56287" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56286", style: {
  clipPath: "url(#et86i61ktn)"
}, transform: "translate(23.187 185.776)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33309", d: "M46.514 372.572a7.467 7.467 0 0 1 3.436.116L49.022 374l1.339-1.351a6.377 6.377 0 0 0-3.847-.082", transform: "translate(-46.514 -372.314)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56291", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56290" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56289", style: {
  clipPath: "url(#d5o5zirxvo)"
}, transform: "translate(27.381 187.973)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33310", d: "m59.069 377.343.026.016c1.553.91 1.4 2.03.07 3.059 1.178-1.284.966-2.178-.315-2.918a6.042 6.042 0 0 0-3.925-.536 5.356 5.356 0 0 1 4.143.379", transform: "translate(-54.926 -376.722)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56294", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56293" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56292", style: {
  clipPath: "url(#sbc3zohfsp)"
}, transform: "translate(31.345 191.67)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33311", d: "m63.153 384.137 1.014.779-1.29.907 1.6-.923z", transform: "translate(-62.878 -384.137)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56297", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56296" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56295", style: {
  clipPath: "url(#wmcwhdzd0q)"
}, transform: "translate(24.857 191.389)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33312", d: "M53.929 383.575a3.439 3.439 0 0 1-2.547.222L49.863 385l1.4-1.408a3.434 3.434 0 0 0 2.662-.019", transform: "translate(-49.863 -383.575)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56300", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56299" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56298", style: {
  clipPath: "url(#r4ql41xbwr)"
}, transform: "translate(22.024 187.838)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33313", d: "M46.318 377.825a2.076 2.076 0 0 1-1.914-.181c-.462-.4-.166-.824.524-1.193-.748.491-.673.925-.159 1.222a1.678 1.678 0 0 0 .76.227 2.932 2.932 0 0 0 .789-.075", transform: "translate(-44.181 -376.451)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56303", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56302" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56301", style: {
  clipPath: "url(#xbr0gv5j9s)"
}, transform: "translate(19.582 187.31)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33314", d: "M40.281 375.392c-.113.1-1.075 1-.651 1.894a3.914 3.914 0 0 0 3 1.693 4.671 4.671 0 0 1-2.207-.636c-1.511-.872-1.484-1.932-.141-2.953", transform: "translate(-39.281 -375.392)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33315", d: "M29.877 366.548a10.18 10.18 0 0 1 3.77-3.486c6.163-3.558 16.155-3.558 22.317 0a10.18 10.18 0 0 1 3.77 3.486h.852v3.224h-.011c-.114 2.242-1.648 4.464-4.611 6.175-6.163 3.558-16.155 3.558-22.317 0-2.964-1.711-4.509-3.933-4.622-6.175v-3.224z", transform: "translate(-14.556 -180.56)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56306", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56305" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56304", style: {
  clipPath: "url(#wxf4y4nl1a)"
}, transform: "translate(14.469 179.833)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33316", d: "M.852 6.155a10.18 10.18 0 0 1 3.77-3.486c6.163-3.558 16.155-3.558 22.317 0a10.18 10.18 0 0 1 3.77 3.486h.852v3.224h-.011c-.114 2.242-1.648 4.464-4.611 6.175-6.163 3.558-16.155 3.558-22.317 0C1.658 13.843.113 11.621 0 9.379V6.155z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56309", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56308" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56307", style: {
  clipPath: "url(#156tas908b)"
}, transform: "translate(30.249 179.833)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33317", d: "M0 0a22.812 22.812 0 0 1 11.16 2.669 10.18 10.18 0 0 1 3.77 3.486h.852v3.224h-.011c-.114 2.242-1.648 4.464-4.611 6.175A22.813 22.813 0 0 1 0 18.223z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56312", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56311" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56310", style: {
  clipPath: "url(#reluz6v65c)"
}, transform: "translate(38.868 181.312)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33318", d: "M0 0a17.262 17.262 0 0 1 2.54 1.189 10.18 10.18 0 0 1 3.77 3.486h.852v3.224h-.011c-.114 2.242-1.648 4.464-4.611 6.175A17.309 17.309 0 0 1 0 15.263z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56315", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56314" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56313", style: {
  clipPath: "url(#reluz6v65c)"
}, transform: "translate(14.469 181.312)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33319", d: "M36.187 363.36a17.316 17.316 0 0 0-2.54 1.189 10.18 10.18 0 0 0-3.77 3.486h-.852v3.224c.114 2.242 1.659 4.464 4.622 6.175a17.256 17.256 0 0 0 2.54 1.189z", transform: "translate(-29.025 -363.36)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33320", d: "M55.964 357.134c6.163 3.558 6.162 9.327 0 12.885-5.392 3.113-13.716 3.5-19.841 1.168l-.245-.1a17.1 17.1 0 0 1-2.231-1.072c-5.139-2.967-5.993-7.471-2.56-10.949a12.023 12.023 0 0 1 2.558-1.934c6.163-3.558 16.155-3.558 22.318 0", transform: "translate(-14.556 -177.588)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56318", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56317" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56316", style: {
  clipPath: "url(#wxf4y4nl1a)"
}, transform: "translate(14.469 176.878)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33321", d: "M26.939 2.668c6.163 3.558 6.162 9.327 0 12.885-5.392 3.113-13.716 3.5-19.841 1.168l-.245-.1a17.1 17.1 0 0 1-2.231-1.072C-.517 12.582-1.371 8.078 2.062 4.6A12.023 12.023 0 0 1 4.62 2.666c6.163-3.558 16.155-3.558 22.318 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56321", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56320" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56319", style: {
  clipPath: "url(#xyt527vkzd)"
}, transform: "translate(14.469 181.482)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33322", d: "M31.087 363.7a6.9 6.9 0 0 0-1.651 5.621c.546 3.131 4.931 5.767 6.442 6.4a17.1 17.1 0 0 1-2.231-1.072c-5.139-2.967-5.994-7.471-2.56-10.949", transform: "translate(-29.025 -363.702)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56324", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56323" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56322", style: {
  clipPath: "url(#xyt527vkzd)"
}, transform: "translate(39.177 178.474)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33323", d: "M83.38 369.69a6.9 6.9 0 0 0 1.651-5.621c-.546-3.131-4.931-5.767-6.442-6.4a17.033 17.033 0 0 1 2.231 1.073c5.139 2.967 5.993 7.471 2.56 10.949", transform: "translate(-78.589 -357.668)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33324", d: "M35.563 370.186c-5.729-3.308-5.729-8.67 0-11.978s15.017-3.308 20.746 0 5.729 8.67 0 11.978-15.017 3.308-20.746 0", transform: "translate(-15.68 -178.221)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56327", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56326" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56325", style: {
  clipPath: "url(#gsjp4xfdre)"
}, transform: "translate(15.586 177.507)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33325", d: "M35.563 370.186c-5.729-3.308-5.729-8.67 0-11.978s15.017-3.308 20.746 0 5.729 8.67 0 11.978-15.017 3.308-20.746 0", transform: "translate(-31.266 -355.728)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56330", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56329" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56328", style: {
  clipPath: "url(#kq7vzii8ef)"
}, transform: "translate(15.585 179.623)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33326", d: "M31.931 368.855c-1.179-2.18-.795-4.582 1.152-6.62a9.829 9.829 0 0 1 1.243-1.089 12.562 12.562 0 0 1 1.187-.783c.235-.137.475-.268.723-.392-.61 1-.386 2.078 3.069 2.28 6.761.4-1.807 6.856-1.807 6.856s-4.358-2.1-5.567-.253", transform: "translate(-31.264 -359.972)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56333", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56332" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56331", style: {
  clipPath: "url(#46riyzum0g)"
}, transform: "translate(15.692 182.459)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33327", d: "M32.737 369.921c-1.014-.114-1.517-1.157-1.123-2.331a2.678 2.678 0 0 1 2.551-1.919c1.014.114 1.517 1.157 1.123 2.331a2.679 2.679 0 0 1-2.551 1.919", transform: "translate(-31.479 -365.661)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33328", d: "M53.995 372.8c.333-2.51 11.308-9.462 11.308-9.462s2.434-.977 1.626-2.678a12.743 12.743 0 0 1 1.173.759q.381.275.713.565c3.267 2.83 3.271 6.546 0 9.378-.221.193-.46.382-.712.566a12.64 12.64 0 0 1-1.088.708c-.372.218-.761.42-1.163.609a18.115 18.115 0 0 1-2.018.797 21.552 21.552 0 0 1-3.274.787c-.244.039-.491.075-.737.107a25.259 25.259 0 0 1-3.274.205 24.844 24.844 0 0 1-4.006-.328 1.759 1.759 0 0 0 1.452-2.012", transform: "translate(-26.35 -180.692)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56336", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56335" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56334", style: {
  clipPath: "url(#wxexxmh7ah)"
}, transform: "translate(26.193 179.964)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33329", d: "M53.995 372.8c.333-2.51 11.308-9.462 11.308-9.462s2.434-.977 1.626-2.678a12.743 12.743 0 0 1 1.173.759q.381.275.713.565c3.267 2.83 3.271 6.546 0 9.378-.221.193-.46.382-.712.566a12.64 12.64 0 0 1-1.088.708c-.372.218-.761.42-1.163.609a18.115 18.115 0 0 1-2.018.797 21.552 21.552 0 0 1-3.274.787c-.244.039-.491.075-.737.107a25.259 25.259 0 0 1-3.274.205 24.844 24.844 0 0 1-4.006-.328 1.759 1.759 0 0 0 1.452-2.012", transform: "translate(-52.543 -360.656)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33330", d: "M57.436 361.908c4.094 2.364 4.26 6.135.5 8.614-.16.1-.327.209-.5.31a17.077 17.077 0 0 1-15.454 0c-4.268-2.464-4.27-6.459 0-8.923.174-.1.354-.2.536-.289 4.294-2.172 10.827-2.075 14.921.289", transform: "translate(-19.447 -180.393)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56339", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56338" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56337", style: {
  clipPath: "url(#ig6bpmf59i)"
}, transform: "translate(19.331 179.666)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33331", d: "M18.657 1.849c4.094 2.364 4.26 6.135.5 8.614-.16.1-.327.209-.5.31a17.077 17.077 0 0 1-15.454 0c-4.268-2.464-4.27-6.459 0-8.923.174-.1.354-.2.536-.289C8.033-.611 14.566-.514 18.66 1.85" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33332", d: "M57.569 362.384c4.08 2.355 4.08 6.174 0 8.529a16.326 16.326 0 0 1-14.773 0c-4.08-2.355-4.08-6.174 0-8.529a16.326 16.326 0 0 1 14.773 0", transform: "translate(-19.927 -180.673)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56342", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56341" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56340", style: {
  clipPath: "url(#qevypd0ecj)"
}, transform: "translate(19.809 179.945)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33333", d: "M17.833 1.767c4.08 2.355 4.08 6.174 0 8.529a16.326 16.326 0 0 1-14.773 0c-4.08-2.355-4.08-6.174 0-8.529a16.326 16.326 0 0 1 14.773 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33334", d: "M57.672 365.759a16.326 16.326 0 0 0-14.773 0 3.46 3.46 0 0 0 0 6.847 16.326 16.326 0 0 0 14.773 0 3.46 3.46 0 0 0 0-6.847", transform: "translate(-20.031 -182.365)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56345", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56344" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56343", style: {
  clipPath: "url(#prmtnt3akk)"
}, transform: "translate(19.911 181.627)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33335", d: "M17.73 1.767a16.326 16.326 0 0 0-14.773 0 3.46 3.46 0 0 0 0 6.847 16.326 16.326 0 0 0 14.773 0 3.46 3.46 0 0 0 0-6.847" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33336", d: "M60.414 370.628v-1.247c0-.511-.354-1.007-1.115-1.453a.267.267 0 0 0-.026-.017 5.356 5.356 0 0 0-4.143-.379h-.01c-.549.139-1.07.317-1.546.48a15.255 15.255 0 0 1-1.66.514 2.934 2.934 0 0 1-.789.075 1.676 1.676 0 0 1-.653-.173l.052-.036c.051-.034.106-.068.164-.1a3.716 3.716 0 0 1 .437-.217 3.027 3.027 0 0 1 2.323-.052l1.339-1.351v-1.24a6.369 6.369 0 0 0-3.662-.133 7.533 7.533 0 0 0-1.164.4l-1.339-.773-1.6.923v1.24l.547.316a1.323 1.323 0 0 0-.235.723v1.237c0 .512.367 1.006 1.138 1.451a4.671 4.671 0 0 0 2.207.636c.146.006.3 0 .441 0a8.745 8.745 0 0 0 2.371-.512c.157-.052.311-.106.462-.159l-1.348 1.352v1.24a6.573 6.573 0 0 0 5.166-.224l1.323.764 1.6-.923v-1.24l-.549-.317a1.448 1.448 0 0 0 .27-.806m-3.37-.107a3.349 3.349 0 0 1-.371.185 3.437 3.437 0 0 1-2.614.039c1.251-.441 2.3-.828 3.187-.353a3.647 3.647 0 0 1-.2.129", transform: "translate(-23.581 -182.836)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33337", d: "M55.13 367.533h-.011c-.549.139-1.07.317-1.546.48a15.218 15.218 0 0 1-1.659.514 2.93 2.93 0 0 1-.789.075 1.683 1.683 0 0 1-.76-.227c-.514-.3-.589-.732.159-1.222.051-.034.106-.068.165-.1a3.674 3.674 0 0 1 .437-.217 3.029 3.029 0 0 1 2.323-.053l1.339-1.351a6.369 6.369 0 0 0-3.662-.133 7.57 7.57 0 0 0-1.164.4l-1.339-.773-1.6.923 1.324.765-.012.01c-1.343 1.021-1.371 2.08.141 2.953a4.671 4.671 0 0 0 2.207.636c.147.006.3 0 .442 0a8.75 8.75 0 0 0 2.371-.512c1.533-.513 2.776-1.117 3.81-.521.692.4.5.9-.26 1.341a3.349 3.349 0 0 1-.371.185 3.434 3.434 0 0 1-2.662.019l-1.4 1.408a6.572 6.572 0 0 0 5.165-.224l1.323.764 1.6-.923-1.323-.763c1.33-1.029 1.483-2.15-.07-3.059a.267.267 0 0 0-.026-.017 5.355 5.355 0 0 0-4.143-.379", transform: "translate(-23.58 -182.836)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56348", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56347" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56346", style: {
  clipPath: "url(#1rjlvvexol)"
}, transform: "translate(23.44 182.094)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33338", d: "M55.13 367.533h-.011c-.549.139-1.07.317-1.546.48a15.218 15.218 0 0 1-1.659.514 2.93 2.93 0 0 1-.789.075 1.683 1.683 0 0 1-.76-.227c-.514-.3-.589-.732.159-1.222.051-.034.106-.068.165-.1a3.674 3.674 0 0 1 .437-.217 3.029 3.029 0 0 1 2.323-.053l1.339-1.351a6.369 6.369 0 0 0-3.662-.133 7.57 7.57 0 0 0-1.164.4l-1.339-.773-1.6.923 1.324.765-.012.01c-1.343 1.021-1.371 2.08.141 2.953a4.671 4.671 0 0 0 2.207.636c.147.006.3 0 .442 0a8.75 8.75 0 0 0 2.371-.512c1.533-.513 2.776-1.117 3.81-.521.692.4.5.9-.26 1.341a3.349 3.349 0 0 1-.371.185 3.434 3.434 0 0 1-2.662.019l-1.4 1.408a6.572 6.572 0 0 0 5.165-.224l1.323.764 1.6-.923-1.323-.763c1.33-1.029 1.483-2.15-.07-3.059a.267.267 0 0 0-.026-.017 5.355 5.355 0 0 0-4.143-.379", transform: "translate(-47.02 -364.93)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56351", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56350" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56349", style: {
  clipPath: "url(#8ule7agrom)"
}, transform: "translate(23.441 182.095)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33339", d: "m47.022 365.853 1.614-.731 1.324.582-1.339-.773z", transform: "translate(-47.022 -364.93)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56354", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56353" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56352", style: {
  clipPath: "url(#suj7tw3jxt)"
}, transform: "translate(27.357 182.258)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33340", d: "M54.877 365.516a7.468 7.468 0 0 1 3.436.116l-.927 1.317 1.339-1.351a6.377 6.377 0 0 0-3.847-.082", transform: "translate(-54.877 -365.258)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56357", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56356" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56355", style: {
  clipPath: "url(#d5o5zirxvo)"
}, transform: "translate(31.55 184.455)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33341", d: "M67.433 370.287a.267.267 0 0 1 .026.017c1.553.909 1.4 2.029.07 3.058 1.178-1.284.965-2.178-.315-2.918a6.042 6.042 0 0 0-3.925-.536 5.354 5.354 0 0 1 4.143.379", transform: "translate(-63.29 -369.665)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56360", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56359" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56358", style: {
  clipPath: "url(#sbc3zohfsp)"
}, transform: "translate(35.514 188.152)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33342", d: "m71.517 377.081 1.014.779-1.29.907 1.6-.923z", transform: "translate(-71.241 -377.081)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56363", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56362" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56361", style: {
  clipPath: "url(#wmcwhdzd0q)"
}, transform: "translate(29.026 187.872)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33343", d: "M62.292 376.519a3.439 3.439 0 0 1-2.547.222l-1.518 1.2 1.4-1.408a3.434 3.434 0 0 0 2.662-.019", transform: "translate(-58.226 -376.519)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56366", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56365" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56364", style: {
  clipPath: "url(#r4ql41xbwr)"
}, transform: "translate(26.194 184.32)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33344", d: "M54.682 370.769a2.076 2.076 0 0 1-1.914-.181c-.463-.4-.166-.824.523-1.193-.748.491-.672.925-.158 1.222a1.681 1.681 0 0 0 .76.227 2.933 2.933 0 0 0 .789-.075", transform: "translate(-52.545 -369.395)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56369", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56368" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56367", style: {
  clipPath: "url(#xbr0gv5j9s)"
}, transform: "translate(23.751 183.792)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33345", d: "M48.645 368.335c-.113.1-1.075 1-.651 1.894a3.914 3.914 0 0 0 3 1.693 4.672 4.672 0 0 1-2.207-.636c-1.511-.873-1.484-1.932-.141-2.953", transform: "translate(-47.644 -368.335)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33346", d: "M18.593 359.188a10.181 10.181 0 0 1 3.77-3.486c6.163-3.558 16.155-3.558 22.317 0a10.181 10.181 0 0 1 3.77 3.486h.85v3.224h-.011c-.113 2.242-1.648 4.464-4.611 6.175-6.163 3.558-16.155 3.558-22.318 0-2.963-1.711-4.509-3.933-4.622-6.175v-3.224z", transform: "translate(-8.897 -176.869)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56372", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56371" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56370", style: {
  clipPath: "url(#wxf4y4nl1a)"
}, transform: "translate(8.844 176.164)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33347", d: "M.852 6.155a10.181 10.181 0 0 1 3.77-3.486c6.163-3.558 16.155-3.558 22.317 0a10.181 10.181 0 0 1 3.77 3.486h.85v3.224h-.011c-.113 2.242-1.648 4.464-4.611 6.175-6.163 3.558-16.155 3.558-22.318 0C1.656 13.843.11 11.621-.003 9.379V6.155z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56375", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56374" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56373", style: {
  clipPath: "url(#156tas908b)"
}, transform: "translate(24.623 176.164)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33348", d: "M.005 0a22.813 22.813 0 0 1 11.16 2.669 10.178 10.178 0 0 1 3.769 3.486h.853v3.224h-.011c-.114 2.242-1.648 4.464-4.611 6.175a22.813 22.813 0 0 1-11.16 2.669z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56378", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56377" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56376", style: {
  clipPath: "url(#reluz6v65c)"
}, transform: "translate(33.243 177.643)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33349", d: "M0 0a17.253 17.253 0 0 1 2.54 1.189 10.178 10.178 0 0 1 3.774 3.487h.852V7.9h-.01c-.114 2.242-1.648 4.464-4.611 6.175a17.306 17.306 0 0 1-2.54 1.189z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56381", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56380" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56379", style: {
  clipPath: "url(#reluz6v65c)"
}, transform: "translate(8.844 177.643)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33350", d: "M24.9 356a17.3 17.3 0 0 0-2.54 1.189 10.18 10.18 0 0 0-3.77 3.486h-.85v3.225c.114 2.242 1.659 4.464 4.622 6.175a17.253 17.253 0 0 0 2.54 1.189z", transform: "translate(-17.74 -356)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33351", d: "M44.68 349.774c6.163 3.558 6.162 9.327 0 12.885-5.392 3.113-13.716 3.5-19.841 1.168l-.245-.1a17.1 17.1 0 0 1-2.231-1.072c-5.139-2.967-5.993-7.471-2.56-10.949a12.015 12.015 0 0 1 2.559-1.934c6.162-3.558 16.154-3.558 22.317 0", transform: "translate(-8.897 -173.897)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56384", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56383" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56382", style: {
  clipPath: "url(#wxf4y4nl1a)"
}, transform: "translate(8.844 173.209)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33352", d: "M26.94 2.668c6.163 3.558 6.162 9.327 0 12.885-5.392 3.113-13.716 3.5-19.841 1.168l-.245-.1a17.1 17.1 0 0 1-2.231-1.072C-.516 12.582-1.37 8.078 2.063 4.6a12.015 12.015 0 0 1 2.559-1.934c6.162-3.558 16.154-3.558 22.317 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56387", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56386" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56385", style: {
  clipPath: "url(#xyt527vkzd)"
}, transform: "translate(8.844 177.813)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33353", d: "M19.8 356.342a6.9 6.9 0 0 0-1.651 5.621c.546 3.131 4.931 5.767 6.442 6.4a17.1 17.1 0 0 1-2.231-1.072c-5.139-2.967-5.993-7.471-2.56-10.949", transform: "translate(-17.741 -356.342)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56390", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56389" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56388", style: {
  clipPath: "url(#xyt527vkzd)"
}, transform: "translate(33.552 174.805)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33354", d: "M72.1 362.33a6.9 6.9 0 0 0 1.651-5.621c-.546-3.131-4.931-5.767-6.442-6.4a17.122 17.122 0 0 1 2.231 1.072c5.139 2.968 5.993 7.472 2.56 10.949", transform: "translate(-67.305 -350.308)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33355", d: "M24.279 362.826c-5.729-3.308-5.729-8.67 0-11.978s15.017-3.308 20.746 0 5.729 8.67 0 11.978-15.017 3.308-20.746 0", transform: "translate(-10.021 -174.53)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56393", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56392" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56391", style: {
  clipPath: "url(#gsjp4xfdre)"
}, transform: "translate(9.961 173.838)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33356", d: "M24.279 362.826c-5.729-3.308-5.729-8.67 0-11.978s15.017-3.308 20.746 0 5.729 8.67 0 11.978-15.017 3.308-20.746 0", transform: "translate(-19.982 -348.367)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56396", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56395" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56394", style: {
  clipPath: "url(#kq7vzii8ef)"
}, transform: "translate(9.96 175.954)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33357", d: "M20.647 361.495c-1.179-2.18-.795-4.582 1.152-6.62a9.822 9.822 0 0 1 1.243-1.089 12.55 12.55 0 0 1 1.187-.786c.235-.137.475-.268.723-.392-.61 1-.386 2.078 3.069 2.28 6.761.4-1.807 6.856-1.807 6.856s-4.358-2.1-5.567-.253", transform: "translate(-19.98 -352.612)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56399", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56398" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56397", style: {
  clipPath: "url(#46riyzum0g)"
}, transform: "translate(10.067 178.789)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33358", d: "M21.452 362.561c-1.014-.114-1.517-1.157-1.123-2.331a2.678 2.678 0 0 1 2.551-1.919c1.014.114 1.517 1.157 1.123 2.331a2.679 2.679 0 0 1-2.551 1.919", transform: "translate(-20.195 -358.3)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33359", d: "M42.71 365.436c.333-2.51 11.308-9.462 11.308-9.462s2.434-.977 1.626-2.678a12.744 12.744 0 0 1 1.173.759q.381.275.713.565c3.267 2.83 3.271 6.546 0 9.378-.221.192-.46.381-.712.565a12.534 12.534 0 0 1-1.088.708c-.372.218-.761.42-1.163.609a18.115 18.115 0 0 1-2.018.797 21.556 21.556 0 0 1-3.274.787c-.244.039-.491.075-.737.107a25.276 25.276 0 0 1-3.274.205 24.844 24.844 0 0 1-4.006-.329 1.759 1.759 0 0 0 1.452-2.012", transform: "translate(-20.691 -177.001)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56402", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56401" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56400", style: {
  clipPath: "url(#wxexxmh7ah)"
}, transform: "translate(20.568 176.295)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33360", d: "M42.71 365.436c.333-2.51 11.308-9.462 11.308-9.462s2.434-.977 1.626-2.678a12.744 12.744 0 0 1 1.173.759q.381.275.713.565c3.267 2.83 3.271 6.546 0 9.378-.221.192-.46.381-.712.565a12.534 12.534 0 0 1-1.088.708c-.372.218-.761.42-1.163.609a18.115 18.115 0 0 1-2.018.797 21.556 21.556 0 0 1-3.274.787c-.244.039-.491.075-.737.107a25.276 25.276 0 0 1-3.274.205 24.844 24.844 0 0 1-4.006-.329 1.759 1.759 0 0 0 1.452-2.012", transform: "translate(-41.258 -353.296)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33361", d: "M46.152 354.547c4.094 2.364 4.26 6.135.5 8.614-.16.1-.327.209-.5.31a17.077 17.077 0 0 1-15.454 0c-4.268-2.464-4.27-6.459 0-8.923.174-.1.354-.2.536-.289 4.294-2.172 10.827-2.075 14.921.289", transform: "translate(-13.789 -176.701)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56405", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56404" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56403", style: {
  clipPath: "url(#ig6bpmf59i)"
}, transform: "translate(13.706 175.997)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33362", d: "M18.657 1.848c4.094 2.364 4.26 6.135.5 8.614-.16.1-.327.209-.5.31a17.077 17.077 0 0 1-15.454 0c-4.268-2.464-4.27-6.459 0-8.923.174-.1.354-.2.536-.289C8.033-.612 14.566-.515 18.66 1.849" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33363", d: "M46.285 355.024c4.08 2.355 4.08 6.174 0 8.529a16.326 16.326 0 0 1-14.773 0c-4.08-2.355-4.08-6.174 0-8.529a16.326 16.326 0 0 1 14.773 0", transform: "translate(-14.269 -176.982)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56408", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56407" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56406", style: {
  clipPath: "url(#zayorvqk0u)"
}, transform: "translate(14.183 176.276)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33364", d: "M17.833 1.767c4.08 2.355 4.08 6.174 0 8.529a16.326 16.326 0 0 1-14.773 0c-4.08-2.355-4.08-6.174 0-8.529a16.326 16.326 0 0 1 14.773 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33365", d: "M46.388 358.4a16.326 16.326 0 0 0-14.773 0 3.46 3.46 0 0 0 0 6.847 16.326 16.326 0 0 0 14.773 0 3.46 3.46 0 0 0 0-6.847", transform: "translate(-14.372 -178.674)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56411", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56410" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56409", style: {
  clipPath: "url(#prmtnt3akk)"
}, transform: "translate(14.286 177.958)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33366", d: "M17.731 1.768a16.326 16.326 0 0 0-14.773 0 3.46 3.46 0 0 0 0 6.847 16.326 16.326 0 0 0 14.773 0 3.46 3.46 0 0 0 0-6.847" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33367", d: "M49.129 363.268v-1.247c0-.511-.354-1.007-1.115-1.453a.267.267 0 0 0-.026-.017 5.356 5.356 0 0 0-4.143-.379h-.011c-.549.139-1.07.317-1.546.48a15.255 15.255 0 0 1-1.66.514 2.934 2.934 0 0 1-.789.075 1.676 1.676 0 0 1-.653-.173l.052-.036c.051-.034.106-.068.164-.1a3.712 3.712 0 0 1 .437-.217 3.03 3.03 0 0 1 2.323-.053l1.339-1.351v-1.24a6.369 6.369 0 0 0-3.662-.133 7.533 7.533 0 0 0-1.164.4l-1.339-.773-1.6.923v1.24l.547.317a1.323 1.323 0 0 0-.235.723v1.237c0 .512.367 1.006 1.138 1.451a4.672 4.672 0 0 0 2.207.636c.146.006.3 0 .441 0a8.747 8.747 0 0 0 2.371-.512q.236-.079.462-.159l-1.348 1.352v1.24a6.573 6.573 0 0 0 5.166-.224l1.323.764 1.6-.923v-1.24l-.549-.317a1.449 1.449 0 0 0 .27-.806m-3.37-.107a3.339 3.339 0 0 1-.371.185 3.439 3.439 0 0 1-2.614.039c1.251-.441 2.3-.828 3.187-.354a3.648 3.648 0 0 1-.2.13", transform: "translate(-17.922 -179.144)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33368", d: "M43.846 360.172h-.011c-.549.139-1.07.317-1.546.48a15.211 15.211 0 0 1-1.659.514 2.93 2.93 0 0 1-.789.075 1.683 1.683 0 0 1-.76-.227c-.514-.3-.589-.732.159-1.222.051-.034.106-.068.165-.1a3.669 3.669 0 0 1 .437-.217 3.026 3.026 0 0 1 2.323-.052l1.339-1.351a6.367 6.367 0 0 0-3.662-.133 7.569 7.569 0 0 0-1.165.4l-1.339-.773-1.6.923 1.324.764-.012.01c-1.343 1.021-1.371 2.08.141 2.953a4.67 4.67 0 0 0 2.207.636c.147.006.3.005.442 0a8.75 8.75 0 0 0 2.371-.512c1.533-.513 2.776-1.117 3.81-.521.692.4.5.9-.26 1.341a3.333 3.333 0 0 1-.371.185 3.434 3.434 0 0 1-2.662.019l-1.4 1.408a6.571 6.571 0 0 0 5.165-.224l1.323.764 1.6-.923-1.323-.764c1.33-1.029 1.483-2.15-.07-3.059a.256.256 0 0 0-.026-.017 5.356 5.356 0 0 0-4.143-.379", transform: "translate(-17.922 -179.144)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56414", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56413" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56412", style: {
  clipPath: "url(#1rjlvvexol)"
}, transform: "translate(17.814 178.425)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33369", d: "M43.846 360.172h-.011c-.549.139-1.07.317-1.546.48a15.211 15.211 0 0 1-1.659.514 2.93 2.93 0 0 1-.789.075 1.683 1.683 0 0 1-.76-.227c-.514-.3-.589-.732.159-1.222.051-.034.106-.068.165-.1a3.669 3.669 0 0 1 .437-.217 3.026 3.026 0 0 1 2.323-.052l1.339-1.351a6.367 6.367 0 0 0-3.662-.133 7.569 7.569 0 0 0-1.165.4l-1.339-.773-1.6.923 1.324.764-.012.01c-1.343 1.021-1.371 2.08.141 2.953a4.67 4.67 0 0 0 2.207.636c.147.006.3.005.442 0a8.75 8.75 0 0 0 2.371-.512c1.533-.513 2.776-1.117 3.81-.521.692.4.5.9-.26 1.341a3.333 3.333 0 0 1-.371.185 3.434 3.434 0 0 1-2.662.019l-1.4 1.408a6.571 6.571 0 0 0 5.165-.224l1.323.764 1.6-.923-1.323-.764c1.33-1.029 1.483-2.15-.07-3.059a.256.256 0 0 0-.026-.017 5.356 5.356 0 0 0-4.143-.379", transform: "translate(-35.736 -357.569)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56417", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56416" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56415", style: {
  clipPath: "url(#8ule7agrom)"
}, transform: "translate(17.815 178.425)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33370", d: "m35.738 358.493 1.614-.731 1.324.582-1.339-.773z", transform: "translate(-35.738 -357.57)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56420", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56419" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56418", style: {
  clipPath: "url(#suj7tw3jxt)"
}, transform: "translate(21.731 178.589)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33371", d: "M43.593 358.156a7.467 7.467 0 0 1 3.436.116l-.928 1.317 1.339-1.351a6.377 6.377 0 0 0-3.847-.082", transform: "translate(-43.593 -357.898)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56423", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56422" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56421", style: {
  clipPath: "url(#d5o5zirxvo)"
}, transform: "translate(25.925 180.786)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33372", d: "m56.148 362.927.026.016c1.553.91 1.4 2.03.07 3.059 1.178-1.284.966-2.178-.315-2.918a6.042 6.042 0 0 0-3.925-.536 5.354 5.354 0 0 1 4.143.379", transform: "translate(-52.005 -362.305)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56426", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56425" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56424", style: {
  clipPath: "url(#sbc3zohfsp)"
}, transform: "translate(29.888 184.483)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33373", d: "m60.232 369.721 1.014.779-1.29.907 1.6-.923z", transform: "translate(-59.957 -369.721)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56429", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56428" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56427", style: {
  clipPath: "url(#wmcwhdzd0q)"
}, transform: "translate(23.4 184.203)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33374", d: "M51.008 369.159a3.439 3.439 0 0 1-2.547.222l-1.518 1.2 1.4-1.408a3.434 3.434 0 0 0 2.662-.019", transform: "translate(-46.942 -369.159)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56432", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56431" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56430", style: {
  clipPath: "url(#r4ql41xbwr)"
}, transform: "translate(20.568 180.651)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33375", d: "M43.4 363.408a2.076 2.076 0 0 1-1.914-.181c-.462-.4-.166-.824.524-1.193-.748.491-.673.925-.159 1.222a1.683 1.683 0 0 0 .76.227 2.93 2.93 0 0 0 .789-.075", transform: "translate(-41.26 -362.034)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56435", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56434" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56433", style: {
  clipPath: "url(#xbr0gv5j9s)"
}, transform: "translate(18.126 180.123)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33376", d: "M37.36 360.975c-.113.1-1.075 1-.651 1.894a3.914 3.914 0 0 0 3 1.693 4.672 4.672 0 0 1-2.207-.636c-1.511-.873-1.484-1.932-.141-2.953", transform: "translate(-36.36 -360.975)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33377", d: "m16.176 359.124.4-3.019.4 3.019 5.229.23-5.229.23-.4 3.018-.4-3.018-5.229-.23z", transform: "translate(-5.49 -178.41)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33378", d: "M66.38 384.616a11.043 11.043 0 0 1 4.456-2.831c6.414-2.252 14.469.494 17.99 6.134a10.341 10.341 0 0 1 1.622 4.9l.687.234-1.311 3.576h-.009a10.089 10.089 0 0 1-6.227 5.581c-6.414 2.253-14.469-.494-17.99-6.134a9.583 9.583 0 0 1-1.216-8.119l1.31-3.576z", transform: "translate(-32.074 -190.893)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56438", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56437" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56436", style: {
  clipPath: "url(#7x0omqkw2v)"
}, transform: "translate(31.883 190.105)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33379", d: "M2.423 3.618A11.043 11.043 0 0 1 6.879.787c6.414-2.252 14.469.494 17.99 6.134a10.341 10.341 0 0 1 1.622 4.9l.687.234-1.311 3.576h-.009a10.089 10.089 0 0 1-6.227 5.581c-6.414 2.253-14.469-.494-17.99-6.134A9.583 9.583 0 0 1 .425 6.959l1.31-3.576z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56441", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56440" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56439", style: {
  clipPath: "url(#0ljd1d633w)"
}, transform: "translate(41.434 190.999)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33380", d: "M7.407 0a14.972 14.972 0 0 1 7.911 6.027 10.347 10.347 0 0 1 1.622 4.9l.687.234-1.311 3.576h-.008a10.091 10.091 0 0 1-6.225 5.581A15.569 15.569 0 0 1 0 20.208z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56444", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56443" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56442", style: {
  clipPath: "url(#sq7cww3oex)"
}, transform: "translate(48.984 195.009)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33381", d: "M6.204 0a12.983 12.983 0 0 1 1.564 2.017 10.344 10.344 0 0 1 1.622 4.9l.687.234-1.311 3.576h-.009a10.091 10.091 0 0 1-6.227 5.581 13.782 13.782 0 0 1-2.531.621z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56447", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56446" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56445", style: {
  clipPath: "url(#lgafxvuq8y)"
}, transform: "translate(31.883 190.271)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33382", d: "M73.368 381.332a13.819 13.819 0 0 0-2.531.621 11.032 11.032 0 0 0-4.456 2.831l-.687-.234-1.311 3.576a9.584 9.584 0 0 0 1.216 8.119 12.939 12.939 0 0 0 1.564 2.017z", transform: "translate(-63.957 -381.332)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33383", d: "M91.24 381.346c3.521 5.64 1.176 12.039-5.237 14.291a16.117 16.117 0 0 1-16.469-4.158l-.159-.172a12.953 12.953 0 0 1-1.362-1.8c-2.937-4.7-1.794-9.934 2.387-12.848a11.789 11.789 0 0 1 2.848-1.442c6.414-2.252 14.469.494 17.991 6.134", transform: "translate(-33.286 -187.597)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56450", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56449" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56448", style: {
  clipPath: "url(#9u7qkv0u4z)"
}, transform: "translate(33.088 186.828)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33384", d: "M24.866 6.921c3.521 5.64 1.176 12.039-5.237 14.291A16.117 16.117 0 0 1 3.16 17.054l-.159-.172a12.953 12.953 0 0 1-1.362-1.8c-2.937-4.7-1.794-9.934 2.387-12.848A11.789 11.789 0 0 1 6.874.792c6.414-2.252 14.469.494 17.991 6.134" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56453", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56452" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56451", style: {
  clipPath: "url(#aqig382kxA)"
}, transform: "translate(33.088 189.058)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33385", d: "M70.4 378.9a10.352 10.352 0 0 0-3.615 5.781c-.833 3.623 1.63 7.752 2.591 8.869a12.924 12.924 0 0 1-1.362-1.8c-2.937-4.7-1.794-9.934 2.387-12.848", transform: "translate(-66.374 -378.899)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56456", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56455" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56454", style: {
  clipPath: "url(#iq6vzsrllB)"
}, transform: "translate(55.567 191.946)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33386", d: "M111.466 399.343a10.354 10.354 0 0 0 3.615-5.781c.833-3.623-1.63-7.752-2.591-8.869a12.924 12.924 0 0 1 1.362 1.8c2.937 4.7 1.794 9.934-2.387 12.848", transform: "translate(-111.466 -384.693)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33387", d: "M69.788 389.97c-3.274-5.243-1.094-11.191 4.869-13.285s13.45.459 16.723 5.7 1.094 11.191-4.869 13.285-13.45-.459-16.723-5.7", transform: "translate(-34.235 -188.364)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56459", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56458" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56457", style: {
  clipPath: "url(#fw50gfcjjC)"
}, transform: "translate(34.031 187.59)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33388", d: "M69.788 389.97c-3.274-5.243-1.094-11.191 4.869-13.285s13.45.459 16.723 5.7 1.094 11.191-4.869 13.285-13.45-.459-16.723-5.7", transform: "translate(-68.266 -375.953)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56462", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56461" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56460", style: {
  clipPath: "url(#4e2sneqysD)"
}, transform: "translate(34.031 188.102)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33389", d: "M68.268 385.651a8.339 8.339 0 0 1 3.619-7.025 10.321 10.321 0 0 1 1.444-.866 11.648 11.648 0 0 1 1.276-.542c.245-.087.491-.166.742-.236-.9.945-1.156 2.2 1.547 3.372 5.29 2.3-4.244 7.108-4.244 7.108s-2.658-3.531-4.384-1.811", transform: "translate(-68.265 -376.982)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56465", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56464" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56463", style: {
  clipPath: "url(#h44ff830xE)"
}, transform: "translate(34.755 190.538)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33390", d: "M70.286 386.311c-.772-.4-.753-1.7.042-2.894s2.065-1.832 2.837-1.427.753 1.7-.042 2.894-2.065 1.832-2.837 1.427", transform: "translate(-69.719 -381.868)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33391", d: "M81.758 398.688C83.047 396 94.72 391.3 94.72 391.3s2.359-.414 2.4-2.523a10.785 10.785 0 0 1 .638 1.165q.2.409.345.822a8.389 8.389 0 0 1-3.813 10.4c-.256.154-.525.3-.8.432a11.5 11.5 0 0 1-1.165.486 12.073 12.073 0 0 1-1.184.356 13.398 13.398 0 0 1-1.951.329 14.577 14.577 0 0 1-2.96-.027 15.201 15.201 0 0 1-.637-.084 15.735 15.735 0 0 1-2.723-.672 15.307 15.307 0 0 1-3.1-1.465 2.035 2.035 0 0 0 1.988-1.832", transform: "translate(-40.004 -194.796)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56468", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56467" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56466", style: {
  clipPath: "url(#tzkmzedrzF)"
}, transform: "translate(39.766 193.983)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33392", d: "M81.758 398.688C83.047 396 94.72 391.3 94.72 391.3s2.359-.414 2.4-2.523a10.785 10.785 0 0 1 .638 1.165q.2.409.345.822a8.389 8.389 0 0 1-3.813 10.4c-.256.154-.525.3-.8.432a11.5 11.5 0 0 1-1.165.486 12.073 12.073 0 0 1-1.184.356 13.398 13.398 0 0 1-1.951.329 14.577 14.577 0 0 1-2.96-.027 15.201 15.201 0 0 1-.637-.084 15.735 15.735 0 0 1-2.723-.672 15.307 15.307 0 0 1-3.1-1.465 2.035 2.035 0 0 0 1.988-1.832", transform: "translate(-79.77 -388.779)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33393", d: "M91.8 385.979a6.409 6.409 0 0 1-3.1 9.69 8.975 8.975 0 0 1-.53.206 11.013 11.013 0 0 1-12.458-4.247c-2.439-3.906-.817-8.338 3.626-9.9.181-.064.366-.122.55-.173a11.04 11.04 0 0 1 11.91 4.422", transform: "translate(-37.399 -190.988)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56471", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56470" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56469", style: {
  clipPath: "url(#2p1dzrwe7G)"
}, transform: "translate(37.176 190.198)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33394", d: "M17.224 4.793a6.409 6.409 0 0 1-3.1 9.69 8.975 8.975 0 0 1-.53.206 11.013 11.013 0 0 1-12.458-4.247C-1.303 6.536.319 2.104 4.762.542c.181-.064.366-.122.55-.173a11.04 11.04 0 0 1 11.91 4.422" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33395", d: "M91.839 386.438c2.331 3.734.779 7.969-3.467 9.46a10.529 10.529 0 0 1-11.909-4.06c-2.331-3.734-.779-7.969 3.467-9.46a10.528 10.528 0 0 1 11.909 4.06", transform: "translate(-37.802 -191.324)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56474", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56473" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56472", style: {
  clipPath: "url(#sv3pp1axqH)"
}, transform: "translate(37.577 190.533)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33396", d: "M16.46 4.581c2.331 3.734.779 7.969-3.467 9.46a10.529 10.529 0 0 1-11.909-4.06C-1.247 6.247.305 2.012 4.551.521a10.528 10.528 0 0 1 11.909 4.06" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33397", d: "M91.156 390.181a10.528 10.528 0 0 0-11.909-4.06 6.884 6.884 0 0 0-3.776 2.984 6.5 6.5 0 0 0 .992 4.61 10.529 10.529 0 0 0 11.909 4.06 6.879 6.879 0 0 0 3.775-2.985 6.494 6.494 0 0 0-.992-4.61", transform: "translate(-37.802 -193.201)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56477", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56476" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56475", style: {
  clipPath: "url(#zs60z4v15I)"
}, transform: "translate(37.578 192.399)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33398", d: "M15.776 4.581A10.528 10.528 0 0 0 3.867.521 6.884 6.884 0 0 0 .091 3.505a6.5 6.5 0 0 0 .992 4.61 10.529 10.529 0 0 0 11.909 4.06 6.879 6.879 0 0 0 3.775-2.985 6.494 6.494 0 0 0-.992-4.61" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33399", d: "M91.934 394.513c.082-.226.422-1.146.507-1.383a2.169 2.169 0 0 0-.309-1.918.176.176 0 0 0-.014-.025 3.414 3.414 0 0 0-3.185-1.559h-.01a13.687 13.687 0 0 0-1.441.108 10.721 10.721 0 0 1-1.546.114 1.836 1.836 0 0 1-.667-.134 1.11 1.11 0 0 1-.456-.372l.057-.026c.055-.024.113-.046.173-.067a3.052 3.052 0 0 1 .441-.121 1.928 1.928 0 0 1 1.893.58L89 388.579l.5-1.375a3.986 3.986 0 0 0-2.9-1.154 5.5 5.5 0 0 0-1.1.127l-.765-1.226-1.664.584-.5 1.375.313.5a2.035 2.035 0 0 0-.484.737l-.5 1.373a2.153 2.153 0 0 0 .328 1.922 3.132 3.132 0 0 0 1.521 1.311 3.051 3.051 0 0 0 .356.12 5.835 5.835 0 0 0 2.119.084c.148-.015.293-.032.437-.049l-1.637 1.129-.5 1.375a4.218 4.218 0 0 0 4.255 1.171l.756 1.21 1.664-.584.5-1.375-.314-.5a2.223 2.223 0 0 0 .545-.82m-2.674-1.045a2.73 2.73 0 0 1-.374.1 2.185 2.185 0 0 1-2.123-.675c1.188-.146 2.188-.287 2.713.484-.068.031-.14.061-.216.088", transform: "translate(-41.02 -192.876)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33400", d: "M89.439 389.628h-.009a13.84 13.84 0 0 0-1.442.108 10.705 10.705 0 0 1-1.546.114 1.836 1.836 0 0 1-.667-.134 1.139 1.139 0 0 1-.52-.461c-.294-.471-.177-.974.625-1.312.055-.024.113-.046.173-.067a3.029 3.029 0 0 1 .441-.121 1.929 1.929 0 0 1 1.894.58l1.629-1.131a3.984 3.984 0 0 0-2.9-1.154 5.507 5.507 0 0 0-1.1.127l-.765-1.226-1.664.584.757 1.212-.014.008c-1.5.763-1.951 1.93-1.087 3.314a3.129 3.129 0 0 0 1.52 1.312 3.221 3.221 0 0 0 .357.12 5.837 5.837 0 0 0 2.12.084c1.445-.148 2.692-.476 3.282.469.4.634.036 1.139-.754 1.416a2.735 2.735 0 0 1-.375.1 2.183 2.183 0 0 1-2.154-.71l-1.7 1.176a4.217 4.217 0 0 0 4.255 1.171l.756 1.211 1.664-.584-.756-1.211c1.49-.776 2.069-1.976 1.187-3.412 0-.009-.01-.018-.014-.025a3.416 3.416 0 0 0-3.186-1.559", transform: "translate(-41.526 -192.876)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56480", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56479" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56478", style: {
  clipPath: "url(#kptvxcp54J)"
}, transform: "translate(41.278 192.075)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33401", d: "M89.439 389.628h-.009a13.84 13.84 0 0 0-1.442.108 10.705 10.705 0 0 1-1.546.114 1.836 1.836 0 0 1-.667-.134 1.139 1.139 0 0 1-.52-.461c-.294-.471-.177-.974.625-1.312.055-.024.113-.046.173-.067a3.029 3.029 0 0 1 .441-.121 1.929 1.929 0 0 1 1.894.58l1.629-1.131a3.984 3.984 0 0 0-2.9-1.154 5.507 5.507 0 0 0-1.1.127l-.765-1.226-1.664.584.757 1.212-.014.008c-1.5.763-1.951 1.93-1.087 3.314a3.129 3.129 0 0 0 1.52 1.312 3.221 3.221 0 0 0 .357.12 5.837 5.837 0 0 0 2.12.084c1.445-.148 2.692-.476 3.282.469.4.634.036 1.139-.754 1.416a2.735 2.735 0 0 1-.375.1 2.183 2.183 0 0 1-2.154-.71l-1.7 1.176a4.217 4.217 0 0 0 4.255 1.171l.756 1.211 1.664-.584-.756-1.211c1.49-.776 2.069-1.976 1.187-3.412 0-.009-.01-.018-.014-.025a3.416 3.416 0 0 0-3.186-1.559", transform: "translate(-82.805 -384.951)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56483", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56482" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56481", style: {
  clipPath: "url(#e5vxiusv3K)"
}, transform: "translate(42.059 192.076)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33402", d: "m84.369 385.536 1.6-.367.831 1.009-.766-1.226z", transform: "translate(-84.369 -384.952)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56486", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56485" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56484", style: {
  clipPath: "url(#kghotwdhaL)"
}, transform: "translate(45.419 193.172)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33403", d: "M91.111 387.159a4.643 4.643 0 0 1 2.722 1.073l-1.283 1.206 1.629-1.13a3.994 3.994 0 0 0-3.068-1.149", transform: "translate(-91.111 -387.152)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56489", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56488" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56487", style: {
  clipPath: "url(#n0k2skt7jM)"
}, transform: "translate(47.913 196.752)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33404", d: "M99.3 395.892a.159.159 0 0 1 .014.026c.882 1.435.3 2.636-1.186 3.412 1.471-1.1 1.664-2.151.932-3.323a3.845 3.845 0 0 0-2.946-1.674 3.416 3.416 0 0 1 3.186 1.559", transform: "translate(-96.112 -394.333)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56492", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56491" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56490", style: {
  clipPath: "url(#4xcpgeqvvN)"
}, transform: "translate(49.018 201.749)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33405", d: "m99.239 404.356.5 1.143-1.408.652 1.664-.584z", transform: "translate(-98.331 -404.356)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56495", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56494" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56493", style: {
  clipPath: "url(#00wn84tutO)"
}, transform: "translate(44.008 199.986)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33406", d: "M92.137 401.53a2.2 2.2 0 0 1-2.144-.454l-1.714.924 1.7-1.176a2.183 2.183 0 0 0 2.154.71", transform: "translate(-88.279 -400.82)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56498", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56497" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56496", style: {
  clipPath: "url(#o82e8ig69P)"
}, transform: "translate(43.388 195.067)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33407", d: "M88.56 392.858a1.348 1.348 0 0 1-1.47-.727c-.211-.568.2-.959.907-1.179-.8.338-.918.841-.625 1.312a1.138 1.138 0 0 0 .52.461 1.844 1.844 0 0 0 .667.134", transform: "translate(-87.035 -390.952)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56501", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56500" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56499", style: {
  clipPath: "url(#sblbg1p4yQ)"
}, transform: "translate(41.278 193.879)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33408", d: "M84.326 388.57a2.647 2.647 0 0 0-1.294 1.922 3.2 3.2 0 0 0 1.729 2.7 3.136 3.136 0 0 1-1.521-1.312c-.864-1.383-.411-2.55 1.087-3.314", transform: "translate(-82.804 -388.57)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33409", d: "m67.393 381.974 1.548-3.239-.906 3.458 4.122 1.692-4.309-1.185-1.548 3.241.906-3.458-4.122-1.692z", transform: "translate(-31.636 -189.758)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33410", d: "M380.865 355.93a8.521 8.521 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.521 8.521 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0-2.48-1.432-3.773-3.291-3.868-5.167v-2.7z", transform: "translate(-190.643 -175.739)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56504", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56503" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56502", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(189.509 175.041)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33411", d: "M.713 5.15a8.521 8.521 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.521 8.521 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0C1.389 11.585.096 9.726.001 7.85v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56507", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56506" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56505", style: {
  clipPath: "url(#95w3hbrhwS)"
}, transform: "translate(202.713 175.041)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33412", d: "M0 0a19.093 19.093 0 0 1 9.339 2.233 8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.01c-.095 1.876-1.378 3.735-3.858 5.167A19.087 19.087 0 0 1 0 15.25z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56510", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56509" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56508", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(209.927 176.278)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33413", d: "M0 0a14.437 14.437 0 0 1 2.126 1A8.519 8.519 0 0 1 5.28 3.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167a14.438 14.438 0 0 1-2.126 1z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56513", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56512" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Mask Group 56511", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(189.509 176.278)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33414", d: "M5.993 0a14.507 14.507 0 0 0-2.126 1A8.522 8.522 0 0 0 .713 3.917H0v2.7c.095 1.876 1.388 3.736 3.867 5.168a14.508 14.508 0 0 0 2.126 1z" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33415", d: "M402.7 348.053c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.478 2.931-16.6.978l-.2-.08a14.276 14.276 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.057 10.057 0 0 1 2.141-1.618c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-190.643 -173.252)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56516", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56515" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56514", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(189.509 172.568)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33416", d: "M22.548 2.233c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.478 2.931-16.6.978l-.2-.08a14.276 14.276 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162A10.057 10.057 0 0 1 3.88 2.233c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56519", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56518" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56517", style: {
  clipPath: "url(#qzj5s700gU)"
}, transform: "translate(189.509 176.421)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33417", d: "M381.877 353.549a5.773 5.773 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.39 5.355a14.275 14.275 0 0 1-1.866-.9c-4.3-2.483-5.016-6.252-2.143-9.162", transform: "translate(-380.152 -353.549)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56522", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56521" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56520", style: {
  clipPath: "url(#qzj5s700gU)"
}, transform: "translate(210.185 173.904)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33418", d: "M425.638 358.559a5.773 5.773 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.391-5.355a14.278 14.278 0 0 1 1.867.9c4.3 2.483 5.016 6.252 2.143 9.162", transform: "translate(-421.628 -348.5)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33419", d: "M385.623 358.975c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-191.583 -173.782)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56525", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56524" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56523", style: {
  clipPath: "url(#1l8ui3b87V)"
}, transform: "translate(190.444 173.094)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33420", d: "M385.623 358.975c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-382.028 -346.876)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56528", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56527" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56526", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(190.443 174.865)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33421", d: "M382.584 357.861a4.538 4.538 0 0 1 .964-5.539 8.268 8.268 0 0 1 1.04-.911 10.524 10.524 0 0 1 .994-.656c.2-.114.4-.224.6-.328-.51.84-.323 1.739 2.568 1.908 5.658.331-1.512 5.737-1.512 5.737s-3.647-1.761-4.658-.212", transform: "translate(-382.026 -350.428)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56531", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56530" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56529", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(190.533 177.237)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33422", d: "M383.259 358.752c-.849-.1-1.27-.968-.94-1.95a2.241 2.241 0 0 1 2.135-1.606c.849.1 1.27.968.94 1.95a2.242 2.242 0 0 1-2.135 1.606", transform: "translate(-382.206 -355.187)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33423", d: "M401.047 361.159c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.561 10.561 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848-.184.162-.384.32-.6.473a10.514 10.514 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.012 18.012 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.133 21.133 0 0 1-2.74.172 20.774 20.774 0 0 1-3.351-.275 1.471 1.471 0 0 0 1.214-1.684", transform: "translate(-200.513 -175.85)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56534", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56533" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56532", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(199.32 175.15)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33424", d: "M401.047 361.159c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.561 10.561 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848-.184.162-.384.32-.6.473a10.514 10.514 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.012 18.012 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.133 21.133 0 0 1-2.74.172 20.774 20.774 0 0 1-3.351-.275 1.471 1.471 0 0 0 1.214-1.684", transform: "translate(-399.833 -351)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33425", d: "M403.927 352.047c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242", transform: "translate(-194.737 -175.599)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56537", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56536" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56535", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(193.578 174.901)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33426", d: "M15.613 1.547c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33427", d: "M404.039 352.446c3.413 1.971 3.413 5.167 0 7.138a13.663 13.663 0 0 1-12.363 0c-3.413-1.971-3.413-5.167 0-7.138a13.663 13.663 0 0 1 12.363 0", transform: "translate(-195.138 -175.833)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56540", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56539" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56538", style: {
  clipPath: "url(#oe6t937yoaa)"
}, transform: "translate(193.977 175.134)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33428", d: "M14.924 1.478c3.413 1.971 3.413 5.167 0 7.138a13.663 13.663 0 0 1-12.363 0c-3.413-1.971-3.413-5.167 0-7.138a13.663 13.663 0 0 1 12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33429", d: "M404.125 355.27a13.663 13.663 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.663 13.663 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73", transform: "translate(-195.225 -177.25)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56543", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56542" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56541", style: {
  clipPath: "url(#ydwlu6emsab)"
}, transform: "translate(194.063 176.542)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33430", d: "M14.837 1.478a13.663 13.663 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.663 13.663 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33431", d: "M406.419 359.344v-1.043c0-.428-.3-.843-.933-1.215a.175.175 0 0 0-.022-.014 4.481 4.481 0 0 0-3.467-.318h-.009c-.459.116-.9.265-1.294.4a12.765 12.765 0 0 1-1.388.43 2.455 2.455 0 0 1-.66.063 1.4 1.4 0 0 1-.547-.145l.043-.03a2.19 2.19 0 0 1 .138-.085 3.08 3.08 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131V355a5.335 5.335 0 0 0-3.064-.111 6.345 6.345 0 0 0-.974.337l-1.121-.647-1.337.772v1.037l.458.265a1.105 1.105 0 0 0-.2.6v1.036c0 .428.308.841.953 1.214a3.908 3.908 0 0 0 1.846.532c.122.005.247 0 .369 0a7.31 7.31 0 0 0 1.984-.429c.132-.044.26-.088.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772v-1.037l-.459-.265a1.211 1.211 0 0 0 .226-.674m-2.821-.089a2.783 2.783 0 0 1-.311.155 2.875 2.875 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.178 2.178 0 0 1-.17.108", transform: "translate(-198.195 -177.643)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33432", d: "M402 356.755h-.009c-.46.116-.9.265-1.294.4a12.847 12.847 0 0 1-1.388.43 2.433 2.433 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.43-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3 3 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.114-1.124a5.331 5.331 0 0 0-3.064-.111 6.345 6.345 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.907 3.907 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.335.419.755-.217 1.122a2.783 2.783 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-198.195 -177.643)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56546", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56545" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56544", style: {
  clipPath: "url(#s5s2du2pmac)"
}, transform: "translate(197.016 176.933)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33433", d: "M402 356.755h-.009c-.46.116-.9.265-1.294.4a12.847 12.847 0 0 1-1.388.43 2.433 2.433 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.43-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3 3 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.114-1.124a5.331 5.331 0 0 0-3.064-.111 6.345 6.345 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.907 3.907 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.335.419.755-.217 1.122a2.783 2.783 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-395.211 -354.576)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56549", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56548" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56547", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(197.016 176.933)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33434", d: "m395.212 355.349 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-395.212 -354.577)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56552", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56551" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56550", style: {
  clipPath: "url(#4o7d44z7lae)"
}, transform: "translate(200.294 177.069)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33435", d: "M401.786 355.066a6.254 6.254 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.337 5.337 0 0 0-3.219-.069", transform: "translate(-401.786 -354.85)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56555", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56554" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56553", style: {
  clipPath: "url(#z9vntq720af)"
}, transform: "translate(203.803 178.909)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33436", d: "M412.292 359.059a.179.179 0 0 1 .022.014c1.3.761 1.171 1.7.059 2.559.986-1.074.808-1.823-.263-2.441a5.055 5.055 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-408.825 -358.539)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56558", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56557" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56556", style: {
  clipPath: "url(#2012gdijnag)"
}, transform: "translate(207.12 182.002)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33437", d: "m415.71 364.744.848.652-1.079.759 1.338-.772z", transform: "translate(-415.479 -364.744)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56561", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56560" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56559", style: {
  clipPath: "url(#ki99t789lah)"
}, transform: "translate(201.69 181.767)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33438", d: "M407.99 364.274a2.875 2.875 0 0 1-2.132.186l-1.271 1.008 1.175-1.178a2.872 2.872 0 0 0 2.227-.016", transform: "translate(-404.588 -364.274)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56564", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56563" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56562", style: {
  clipPath: "url(#8qusk53ybai)"
}, transform: "translate(199.321 178.795)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33439", d: "M401.622 359.462a1.737 1.737 0 0 1-1.6-.152c-.386-.332-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.408 1.408 0 0 0 .636.19 2.458 2.458 0 0 0 .66-.063", transform: "translate(-399.834 -358.312)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56567", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56566" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56565", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(197.276 178.354)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33440", d: "M396.571 357.426c-.095.084-.9.835-.544 1.585a3.276 3.276 0 0 0 2.51 1.417 3.908 3.908 0 0 1-1.846-.532c-1.265-.73-1.242-1.616-.118-2.471", transform: "translate(-395.733 -357.426)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33441", d: "M376.9 348.606a8.522 8.522 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.526 8.526 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.379 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0-2.48-1.432-3.773-3.291-3.868-5.167v-2.7z", transform: "translate(-188.654 -172.067)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56570", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56569" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56568", style: {
  clipPath: "url(#o1jl5rg6eak)"
}, transform: "translate(187.531 171.39)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33442", d: "M.715 5.15A8.522 8.522 0 0 1 3.87 2.233c5.157-2.977 13.518-2.977 18.675 0A8.526 8.526 0 0 1 25.7 5.15h.713v2.7h-.009c-.095 1.876-1.379 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0C1.391 11.585.098 9.726.003 7.85v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56573", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56572" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56571", style: {
  clipPath: "url(#95w3hbrhwS)"
}, transform: "translate(200.736 171.39)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33443", d: "M0 0a19.093 19.093 0 0 1 9.339 2.233 8.516 8.516 0 0 1 3.154 2.917h.714v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167A19.088 19.088 0 0 1 .001 15.25z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56576", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56575" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56574", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(207.949 172.627)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33444", d: "M0 0a14.43 14.43 0 0 1 2.125 1A8.517 8.517 0 0 1 5.28 3.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.859 5.167a14.432 14.432 0 0 1-2.125 1z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56579", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56578" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56577", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(187.531 172.627)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33445", d: "M382.178 345.939a14.435 14.435 0 0 0-2.126 1 8.519 8.519 0 0 0-3.154 2.917h-.713v2.7c.095 1.876 1.388 3.736 3.867 5.168a14.436 14.436 0 0 0 2.126 1z", transform: "translate(-376.185 -345.939)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33446", d: "M398.728 340.729c5.157 2.978 5.157 7.805 0 10.782-4.512 2.605-11.477 2.931-16.6.978l-.2-.08a14.277 14.277 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.058 10.058 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-188.653 -169.579)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56582", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56581" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56580", style: {
  clipPath: "url(#o1jl5rg6eak)"
}, transform: "translate(187.531 168.917)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33447", d: "M22.544 2.233c5.157 2.978 5.157 7.805 0 10.782-4.512 2.605-11.477 2.931-16.6.978l-.2-.08a14.277 14.277 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.058 10.058 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56585", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56584" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56583", style: {
  clipPath: "url(#qzj5s700gU)"
}, transform: "translate(187.531 172.77)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33448", d: "M377.91 346.225a5.773 5.773 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.391 5.355a14.272 14.272 0 0 1-1.867-.9c-4.3-2.483-5.016-6.252-2.143-9.162", transform: "translate(-376.184 -346.225)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56588", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56587" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56586", style: {
  clipPath: "url(#0gs2fo2ekam)"
}, transform: "translate(208.207 170.252)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33449", d: "M421.669 351.235a5.772 5.772 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.391-5.355a14.246 14.246 0 0 1 1.867.9c4.3 2.483 5.015 6.252 2.143 9.162", transform: "translate(-417.66 -341.176)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33450", d: "M381.655 351.651c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.567 2.768-17.361 0", transform: "translate(-189.594 -170.109)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56591", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56590" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56589", style: {
  clipPath: "url(#7du574fufan)"
}, transform: "translate(188.466 169.443)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33451", d: "M381.655 351.651c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.567 2.768-17.361 0", transform: "translate(-378.06 -339.552)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56594", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56593" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56592", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(188.466 171.214)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33452", d: "M378.616 350.537a4.538 4.538 0 0 1 .964-5.537 8.269 8.269 0 0 1 1.04-.911 10.432 10.432 0 0 1 .994-.655c.2-.115.4-.224.6-.328-.51.84-.323 1.739 2.568 1.908 5.658.331-1.512 5.737-1.512 5.737s-3.647-1.761-4.658-.212", transform: "translate(-378.059 -343.104)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56597", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56596" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56595", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(188.555 173.587)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33453", d: "M379.291 351.428c-.849-.1-1.27-.969-.94-1.951a2.241 2.241 0 0 1 2.135-1.606c.849.1 1.27.968.94 1.95a2.242 2.242 0 0 1-2.135 1.606", transform: "translate(-378.238 -347.863)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33454", d: "M397.079 353.835c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.622 10.622 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848-.184.162-.384.32-.6.473a10.524 10.524 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.008 18.008 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.133 21.133 0 0 1-2.74.172 20.775 20.775 0 0 1-3.352-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-198.523 -172.177)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56600", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56599" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56598", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(197.342 171.499)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33455", d: "M397.079 353.835c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.622 10.622 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848-.184.162-.384.32-.6.473a10.524 10.524 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.008 18.008 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.133 21.133 0 0 1-2.74.172 20.775 20.775 0 0 1-3.352-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-395.865 -343.676)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33456", d: "M399.959 344.723c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.4 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242", transform: "translate(-192.747 -171.926)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56603", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56602" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56601", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(191.566 171.207)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33457", d: "M15.612 1.547c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.4 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33458", d: "M400.071 345.122c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0", transform: "translate(-193.149 -172.161)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56606", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56605" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56604", style: {
  clipPath: "url(#il2gc45k9ao)"
}, transform: "translate(192 171.483)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33459", d: "M14.923 1.478c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33460", d: "M400.157 347.946a13.662 13.662 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73", transform: "translate(-193.235 -173.577)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56609", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56608" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56607", style: {
  clipPath: "url(#ydwlu6emsab)"
}, transform: "translate(192.085 172.891)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33461", d: "M14.837 1.479a13.662 13.662 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33462", d: "M402.451 352.02v-1.043c0-.428-.3-.843-.933-1.215a.181.181 0 0 0-.022-.014 4.481 4.481 0 0 0-3.467-.318h-.009c-.459.116-.9.265-1.294.4a12.736 12.736 0 0 1-1.389.43 2.457 2.457 0 0 1-.661.063 1.412 1.412 0 0 1-.546-.145l.043-.03a2.19 2.19 0 0 1 .138-.085 3.164 3.164 0 0 1 .366-.182 2.534 2.534 0 0 1 1.943-.044l1.121-1.131v-1.038a5.335 5.335 0 0 0-3.064-.111 6.37 6.37 0 0 0-.975.337l-1.121-.647-1.337.773v1.037l.458.265a1.11 1.11 0 0 0-.2.6v1.036c0 .429.308.841.953 1.214a3.906 3.906 0 0 0 1.846.532c.122.005.247 0 .369 0a7.29 7.29 0 0 0 1.984-.429c.132-.044.26-.088.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.322-.187l1.107.639 1.338-.772v-1.037l-.459-.265a1.211 1.211 0 0 0 .226-.674m-2.821-.089a2.781 2.781 0 0 1-.311.155 2.875 2.875 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.384 2.384 0 0 1-.17.108", transform: "translate(-196.205 -173.97)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33463", d: "M398.03 349.431h-.009c-.459.116-.9.265-1.294.4a12.842 12.842 0 0 1-1.388.43 2.436 2.436 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.022a2.19 2.19 0 0 1 .138-.085 3 3 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.342 6.342 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.907 3.907 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.187-.436.58.335.419.755-.217 1.122a2.775 2.775 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.338-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-196.205 -173.97)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56612", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56611" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56610", style: {
  clipPath: "url(#3ykp529bmap)"
}, transform: "translate(195.038 173.282)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33464", d: "M398.03 349.431h-.009c-.459.116-.9.265-1.294.4a12.842 12.842 0 0 1-1.388.43 2.436 2.436 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.022a2.19 2.19 0 0 1 .138-.085 3 3 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.342 6.342 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.907 3.907 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.187-.436.58.335.419.755-.217 1.122a2.775 2.775 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.338-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-391.243 -347.252)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56615", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56614" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56613", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(195.039 173.282)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33465", d: "m391.245 348.024 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-391.245 -347.252)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56618", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56617" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56616", style: {
  clipPath: "url(#60pt4crhgaq)"
}, transform: "translate(198.315 173.419)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33466", d: "M397.818 347.742a6.252 6.252 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.339 5.339 0 0 0-3.22-.069", transform: "translate(-397.818 -347.526)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56621", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56620" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56619", style: {
  clipPath: "url(#z9vntq720af)"
}, transform: "translate(201.825 175.257)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33467", d: "M408.324 351.735a.179.179 0 0 1 .022.014c1.3.761 1.171 1.7.059 2.559.986-1.074.808-1.823-.263-2.441a5.055 5.055 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-404.857 -351.215)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56624", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56623" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56622", style: {
  clipPath: "url(#1pr1pjo4aar)"
}, transform: "translate(205.142 178.351)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33468", d: "m411.742 357.42.848.652-1.079.759 1.338-.772z", transform: "translate(-411.511 -357.42)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56627", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56626" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56625", style: {
  clipPath: "url(#n27r1wyzpas)"
}, transform: "translate(199.712 178.116)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33469", d: "M404.023 356.95a2.878 2.878 0 0 1-2.132.186l-1.271 1.008 1.174-1.178a2.873 2.873 0 0 0 2.228-.016", transform: "translate(-400.621 -356.95)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56630", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56629" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56628", style: {
  clipPath: "url(#8qusk53ybai)"
}, transform: "translate(197.343 175.144)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33470", d: "M397.654 352.138a1.737 1.737 0 0 1-1.6-.152c-.386-.332-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.407 1.407 0 0 0 .636.19 2.455 2.455 0 0 0 .66-.063", transform: "translate(-395.866 -350.988)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56633", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56632" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56631", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(195.298 174.703)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33471", d: "M392.6 350.1c-.095.084-.9.835-.544 1.585a3.276 3.276 0 0 0 2.51 1.417 3.913 3.913 0 0 1-1.847-.531c-1.265-.73-1.242-1.617-.118-2.471", transform: "translate(-391.765 -350.102)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33472", d: "M378.9 338.933a8.522 8.522 0 0 1 3.155-2.917c5.157-2.978 13.518-2.978 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0-2.48-1.432-3.773-3.291-3.868-5.167v-2.7z", transform: "translate(-189.659 -167.215)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56636", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56635" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56634", style: {
  clipPath: "url(#0gl3ce21iat)"
}, transform: "translate(188.53 166.567)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33473", d: "M.711 5.151a8.522 8.522 0 0 1 3.155-2.917c5.157-2.978 13.518-2.978 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7H26.4c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0C1.387 11.586.094 9.727-.001 7.851v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56639", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56638" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56637", style: {
  clipPath: "url(#ratzvo14cau)"
}, transform: "translate(201.735 166.567)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33474", d: "M0 0a19.093 19.093 0 0 1 9.339 2.233 8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167A19.087 19.087 0 0 1 .001 15.25z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56642", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56641" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56640", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(208.948 167.805)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33475", d: "M0 0a14.44 14.44 0 0 1 2.126 1A8.514 8.514 0 0 1 5.28 3.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167a14.489 14.489 0 0 1-2.126 1z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56645", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56644" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56643", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(188.53 167.805)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33476", d: "M384.182 336.265a14.465 14.465 0 0 0-2.126 1 8.515 8.515 0 0 0-3.154 2.917h-.714v2.7c.1 1.876 1.388 3.736 3.868 5.168a14.463 14.463 0 0 0 2.126 1z", transform: "translate(-378.188 -336.265)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33477", d: "M400.732 331.055c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.477 2.931-16.6.978l-.2-.08a14.286 14.286 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.074 10.074 0 0 1 2.141-1.618c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-189.658 -164.727)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56648", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56647" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56646", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(188.53 164.094)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33478", d: "M22.543 2.233c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.477 2.931-16.6.978l-.2-.08a14.286 14.286 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.074 10.074 0 0 1 2.141-1.618c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56651", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56650" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56649", style: {
  clipPath: "url(#g46c00bkmav)"
}, transform: "translate(188.53 167.947)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33479", d: "M379.914 336.551a5.773 5.773 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.39 5.355a14.279 14.279 0 0 1-1.866-.9c-4.3-2.483-5.016-6.252-2.143-9.162", transform: "translate(-378.189 -336.551)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56654", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56653" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56652", style: {
  clipPath: "url(#upnl1u39baw)"
}, transform: "translate(209.207 165.43)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33480", d: "M423.674 341.561a5.773 5.773 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.391-5.355a14.247 14.247 0 0 1 1.867.9c4.3 2.483 5.016 6.252 2.143 9.162", transform: "translate(-419.664 -331.502)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33481", d: "M383.66 341.977c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-190.599 -165.257)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56657", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56656" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56655", style: {
  clipPath: "url(#7du574fufan)"
}, transform: "translate(189.465 164.621)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33482", d: "M383.66 341.977c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-380.064 -329.878)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56660", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56659" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56658", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(189.465 166.391)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33483", d: "M380.62 340.863a4.538 4.538 0 0 1 .964-5.539 8.231 8.231 0 0 1 1.04-.911 10.543 10.543 0 0 1 .994-.656c.2-.114.4-.224.6-.328-.51.839-.323 1.739 2.568 1.908 5.658.331-1.512 5.737-1.512 5.737s-3.647-1.761-4.658-.212", transform: "translate(-380.063 -333.43)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56663", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56662" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56661", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(189.554 168.764)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33484", d: "M381.295 341.755c-.849-.1-1.27-.968-.94-1.95a2.241 2.241 0 0 1 2.135-1.606c.849.1 1.27.968.94 1.95a2.242 2.242 0 0 1-2.135 1.606", transform: "translate(-380.242 -338.19)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33485", d: "M399.083 344.161c.279-2.1 9.463-7.918 9.463-7.918s2.036-.817 1.36-2.241a10.545 10.545 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.478 0 7.848-.184.161-.384.32-.6.473a10.519 10.519 0 0 1-.911.593 13.396 13.396 0 0 1-1.801.866 15.65 15.65 0 0 1-.861.31 18.017 18.017 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.127 21.127 0 0 1-2.74.172 20.771 20.771 0 0 1-3.351-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-199.528 -167.325)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56666", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56665" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56664", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(198.341 166.677)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33486", d: "M399.083 344.161c.279-2.1 9.463-7.918 9.463-7.918s2.036-.817 1.36-2.241a10.545 10.545 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.478 0 7.848-.184.161-.384.32-.6.473a10.519 10.519 0 0 1-.911.593 13.396 13.396 0 0 1-1.801.866 15.65 15.65 0 0 1-.861.31 18.017 18.017 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.127 21.127 0 0 1-2.74.172 20.771 20.771 0 0 1-3.351-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-397.869 -334.002)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33487", d: "M401.964 335.049c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242", transform: "translate(-193.752 -167.075)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56669", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56668" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56667", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(192.599 166.427)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33488", d: "M15.613 1.547c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33489", d: "M402.075 335.448c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0", transform: "translate(-194.154 -167.309)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56672", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56671" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56670", style: {
  clipPath: "url(#il2gc45k9ao)"
}, transform: "translate(192.999 166.661)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33490", d: "M14.923 1.478c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33491", d: "M402.161 338.272a13.662 13.662 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73", transform: "translate(-194.24 -168.725)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56675", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56674" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56673", style: {
  clipPath: "url(#ydwlu6emsab)"
}, transform: "translate(193.084 168.068)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33492", d: "M14.837 1.478a13.662 13.662 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33493", d: "M404.456 342.347v-1.043c0-.428-.3-.843-.933-1.215a.177.177 0 0 0-.022-.014 4.481 4.481 0 0 0-3.467-.318h-.009c-.459.116-.9.265-1.294.4a12.768 12.768 0 0 1-1.388.43 2.456 2.456 0 0 1-.66.063 1.4 1.4 0 0 1-.547-.145l.043-.03a2.19 2.19 0 0 1 .138-.085 3.161 3.161 0 0 1 .366-.182 2.535 2.535 0 0 1 1.944-.044l1.121-1.131V338a5.335 5.335 0 0 0-3.064-.111 6.286 6.286 0 0 0-.974.337l-1.121-.647-1.338.772v1.037l.458.265a1.11 1.11 0 0 0-.2.605v1.035c0 .429.308.841.953 1.214a3.908 3.908 0 0 0 1.846.532h.369a7.292 7.292 0 0 0 1.984-.429c.132-.044.26-.088.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772v-1.037l-.459-.265a1.211 1.211 0 0 0 .226-.674m-2.821-.089a2.767 2.767 0 0 1-.311.155 2.875 2.875 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.384 2.384 0 0 1-.17.108", transform: "translate(-197.211 -169.119)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33494", d: "M400.034 339.757h-.009c-.459.116-.9.265-1.294.4a12.829 12.829 0 0 1-1.388.43 2.43 2.43 0 0 1-.66.062 1.407 1.407 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3.012 3.012 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.116-1.126a5.331 5.331 0 0 0-3.064-.111 6.343 6.343 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.912 3.912 0 0 0 1.847.531c.122.005.247 0 .369 0a7.319 7.319 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.187-.436.58.335.419.755-.217 1.122a2.783 2.783 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-197.21 -169.119)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56678", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56677" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56676", style: {
  clipPath: "url(#0htxzyyhyax)"
}, transform: "translate(196.037 168.459)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33495", d: "M400.034 339.757h-.009c-.459.116-.9.265-1.294.4a12.829 12.829 0 0 1-1.388.43 2.43 2.43 0 0 1-.66.062 1.407 1.407 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3.012 3.012 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.116-1.126a5.331 5.331 0 0 0-3.064-.111 6.343 6.343 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.912 3.912 0 0 0 1.847.531c.122.005.247 0 .369 0a7.319 7.319 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.187-.436.58.335.419.755-.217 1.122a2.783 2.783 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-393.247 -337.578)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56681", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56680" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56679", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(196.038 168.46)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33496", d: "m393.249 338.351 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-393.249 -337.579)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56684", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56683" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56682", style: {
  clipPath: "url(#60pt4crhgaq)"
}, transform: "translate(199.314 168.596)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33497", d: "M399.822 338.069a6.253 6.253 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.339 5.339 0 0 0-3.22-.069", transform: "translate(-399.822 -337.853)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56687", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56686" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56685", style: {
  clipPath: "url(#r4mjs5k4eay)"
}, transform: "translate(202.824 170.435)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33498", d: "M410.328 342.061a.175.175 0 0 1 .022.014c1.3.761 1.172 1.7.059 2.559.986-1.074.808-1.823-.263-2.441a5.055 5.055 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-406.861 -341.541)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56690", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56689" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56688", style: {
  clipPath: "url(#8uozfx4tcaz)"
}, transform: "translate(206.141 173.529)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33499", d: "m413.746 347.747.848.652-1.079.759 1.338-.772z", transform: "translate(-413.515 -347.747)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56693", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56692" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56691", style: {
  clipPath: "url(#n27r1wyzpas)"
}, transform: "translate(200.711 173.294)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33500", d: "M406.027 347.276a2.875 2.875 0 0 1-2.132.186l-1.271 1.008 1.174-1.178a2.873 2.873 0 0 0 2.228-.016", transform: "translate(-402.625 -347.276)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56696", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56695" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56694", style: {
  clipPath: "url(#8qusk53ybai)"
}, transform: "translate(198.342 170.322)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33501", d: "M399.658 342.465a1.737 1.737 0 0 1-1.6-.152c-.386-.333-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.407 1.407 0 0 0 .636.19 2.456 2.456 0 0 0 .66-.063", transform: "translate(-397.87 -341.315)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56699", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56698" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56697", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(196.298 169.88)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33502", d: "M394.607 340.428c-.095.084-.9.835-.544 1.585a3.276 3.276 0 0 0 2.51 1.417 3.913 3.913 0 0 1-1.847-.531c-1.265-.73-1.242-1.617-.118-2.471", transform: "translate(-393.77 -340.428)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33503", d: "M372.759 332.746a8.521 8.521 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.676 0-2.48-1.432-3.773-3.291-3.867-5.167v-2.7z", transform: "translate(-186.578 -164.113)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56702", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56701" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56700", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(185.468 163.483)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33504", d: "M.713 5.15a8.521 8.521 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.676 0C1.388 11.585.095 9.726.001 7.85v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56705", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56704" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56703", style: {
  clipPath: "url(#95w3hbrhwS)"
}, transform: "translate(198.672 163.483)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33505", d: "M0 .004a19.092 19.092 0 0 1 9.339 2.233 8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.1 1.875-1.379 3.735-3.858 5.167a19.087 19.087 0 0 1-9.339 2.233z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56708", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56707" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56706", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(205.886 164.721)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33506", d: "M-.004 0a14.436 14.436 0 0 1 2.126 1 8.515 8.515 0 0 1 3.154 2.917h.72v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167a14.478 14.478 0 0 1-2.126 1z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56711", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56710" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56709", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(185.468 164.721)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33507", d: "M378.039 330.079a14.463 14.463 0 0 0-2.126 1 8.519 8.519 0 0 0-3.154 2.917h-.713v2.7c.095 1.876 1.388 3.736 3.867 5.168a14.455 14.455 0 0 0 2.126 1z", transform: "translate(-372.046 -330.079)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33508", d: "M394.59 324.869c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.478 2.931-16.6.978l-.2-.08a14.276 14.276 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.073 10.073 0 0 1 2.141-1.618c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-186.578 -161.625)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56714", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56713" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56712", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(185.468 161.011)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33509", d: "M22.544 2.233c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.478 2.931-16.6.978l-.2-.08a14.276 14.276 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.073 10.073 0 0 1 2.141-1.618c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56717", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56716" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56715", style: {
  clipPath: "url(#upnl1u39baw)"
}, transform: "translate(185.468 164.864)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33510", d: "M373.771 330.365a5.773 5.773 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.39 5.355a14.274 14.274 0 0 1-1.866-.9c-4.3-2.483-5.016-6.252-2.143-9.162", transform: "translate(-372.046 -330.365)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56720", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56719" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56718", style: {
  clipPath: "url(#qzj5s700gU)"
}, transform: "translate(206.144 162.347)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33511", d: "M417.531 335.375a5.773 5.773 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.391-5.355a14.28 14.28 0 0 1 1.867.9c4.3 2.483 5.016 6.252 2.143 9.162", transform: "translate(-413.522 -325.316)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33512", d: "M377.517 335.791c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-187.519 -162.155)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56723", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56722" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56721", style: {
  clipPath: "url(#1l8ui3b87V)"
}, transform: "translate(186.403 161.537)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33513", d: "M377.517 335.791c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-373.922 -323.692)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56726", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56725" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56724", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(186.402 163.308)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33514", d: "M374.479 334.677a4.539 4.539 0 0 1 .964-5.539 8.265 8.265 0 0 1 1.04-.911 10.437 10.437 0 0 1 .994-.655c.2-.115.4-.224.6-.328-.51.84-.323 1.739 2.568 1.908 5.658.331-1.512 5.737-1.512 5.737s-3.647-1.761-4.658-.212", transform: "translate(-373.92 -327.244)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56729", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56728" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56727", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(186.492 165.68)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33515", d: "M375.153 335.569c-.849-.1-1.27-.969-.94-1.95a2.241 2.241 0 0 1 2.135-1.606c.849.1 1.27.968.94 1.95a2.241 2.241 0 0 1-2.135 1.606", transform: "translate(-374.1 -332.004)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33516", d: "M392.941 337.975c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.548 10.548 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.478 0 7.848-.184.161-.384.32-.6.473a10.519 10.519 0 0 1-.911.593 13.396 13.396 0 0 1-1.801.866 15.65 15.65 0 0 1-.861.31 18.013 18.013 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.132 21.132 0 0 1-2.74.172 20.771 20.771 0 0 1-3.352-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-196.448 -164.223)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56732", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56731" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56730", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(195.279 163.593)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33517", d: "M392.941 337.975c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.548 10.548 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.478 0 7.848-.184.161-.384.32-.6.473a10.519 10.519 0 0 1-.911.593 13.396 13.396 0 0 1-1.801.866 15.65 15.65 0 0 1-.861.31 18.013 18.013 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.132 21.132 0 0 1-2.74.172 20.771 20.771 0 0 1-3.352-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-391.727 -327.816)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33518", d: "M395.821 328.863c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242", transform: "translate(-190.671 -163.973)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56735", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56734" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56733", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(189.537 163.344)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33519", d: "M15.613 1.547c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33520", d: "M395.932 329.262c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0", transform: "translate(-191.073 -164.207)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56738", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56737" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56736", style: {
  clipPath: "url(#il2gc45k9ao)"
}, transform: "translate(189.936 163.577)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33521", d: "M14.923 1.478c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33522", d: "M396.018 332.086a13.662 13.662 0 0 0-12.362 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.362 0 2.9 2.9 0 0 0 0-5.73", transform: "translate(-191.159 -165.623)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56741", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56740" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56739", style: {
  clipPath: "url(#ydwlu6emsab)"
}, transform: "translate(190.022 164.985)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33523", d: "M14.837 1.478a13.662 13.662 0 0 0-12.362 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.362 0 2.9 2.9 0 0 0 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33524", d: "M398.313 336.161v-1.043c0-.428-.3-.843-.933-1.215a.181.181 0 0 0-.022-.014 4.482 4.482 0 0 0-3.467-.318h-.009c-.459.116-.9.265-1.294.4a12.762 12.762 0 0 1-1.388.43 2.454 2.454 0 0 1-.66.063 1.4 1.4 0 0 1-.547-.145l.043-.03a2.19 2.19 0 0 1 .138-.085 3.156 3.156 0 0 1 .366-.182 2.535 2.535 0 0 1 1.944-.044l1.121-1.131v-1.038a5.335 5.335 0 0 0-3.064-.111 6.339 6.339 0 0 0-.974.337l-1.121-.647-1.337.772v1.04l.458.265a1.109 1.109 0 0 0-.2.6v1.036c0 .429.308.841.953 1.214a3.908 3.908 0 0 0 1.846.532h.369a7.289 7.289 0 0 0 1.984-.429c.132-.044.26-.088.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772V337.1l-.459-.265a1.211 1.211 0 0 0 .226-.674m-2.821-.089a2.778 2.778 0 0 1-.311.155 2.874 2.874 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.384 2.384 0 0 1-.17.108", transform: "translate(-194.13 -166.017)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33525", d: "M393.892 333.57h-.009c-.46.116-.9.265-1.294.4a12.832 12.832 0 0 1-1.388.43 2.438 2.438 0 0 1-.66.063 1.408 1.408 0 0 1-.636-.19c-.43-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3 3 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.339 6.339 0 0 0-.974.337l-1.121-.647-1.338.772 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.912 3.912 0 0 0 1.847.531c.122.005.247 0 .369 0a7.316 7.316 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.334.419.755-.217 1.122a2.773 2.773 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-194.13 -166.016)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56744", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56743" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56742", style: {
  clipPath: "url(#0htxzyyhyax)"
}, transform: "translate(192.975 165.376)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33526", d: "M393.892 333.57h-.009c-.46.116-.9.265-1.294.4a12.832 12.832 0 0 1-1.388.43 2.438 2.438 0 0 1-.66.063 1.408 1.408 0 0 1-.636-.19c-.43-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3 3 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.339 6.339 0 0 0-.974.337l-1.121-.647-1.338.772 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.912 3.912 0 0 0 1.847.531c.122.005.247 0 .369 0a7.316 7.316 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.334.419.755-.217 1.122a2.773 2.773 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-387.105 -331.392)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56747", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56746" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56745", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(192.976 165.376)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33527", d: "m387.106 332.165 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-387.106 -331.393)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56750", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56749" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56748", style: {
  clipPath: "url(#60pt4crhgaq)"
}, transform: "translate(196.252 165.513)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33528", d: "M393.68 331.882a6.252 6.252 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.337 5.337 0 0 0-3.219-.069", transform: "translate(-393.68 -331.667)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56753", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56752" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56751", style: {
  clipPath: "url(#r4mjs5k4eay)"
}, transform: "translate(199.762 167.351)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33529", d: "M404.186 335.875a.178.178 0 0 1 .022.014c1.3.761 1.172 1.7.059 2.559.986-1.074.808-1.823-.263-2.441a5.055 5.055 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-400.719 -335.355)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56756", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56755" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56754", style: {
  clipPath: "url(#8uozfx4tcaz)"
}, transform: "translate(203.079 170.445)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33530", d: "m407.6 341.561.848.652-1.079.759 1.338-.772z", transform: "translate(-407.373 -341.561)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56759", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56758" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56757", style: {
  clipPath: "url(#ki99t789lah)"
}, transform: "translate(197.649 170.21)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33531", d: "M399.884 341.09a2.875 2.875 0 0 1-2.132.186l-1.271 1.008 1.175-1.178a2.872 2.872 0 0 0 2.227-.016", transform: "translate(-396.482 -341.09)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56762", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56761" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56760", style: {
  clipPath: "url(#7xfpzhvrpaA)"
}, transform: "translate(195.279 167.238)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33532", d: "M393.516 336.278a1.738 1.738 0 0 1-1.6-.152c-.386-.333-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.408 1.408 0 0 0 .636.19 2.457 2.457 0 0 0 .66-.063", transform: "translate(-391.727 -335.128)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56765", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56764" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56763", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(193.235 166.796)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33533", d: "M388.465 334.242c-.095.084-.9.834-.544 1.585a3.276 3.276 0 0 0 2.51 1.417 3.913 3.913 0 0 1-1.846-.531c-1.265-.731-1.242-1.617-.118-2.471", transform: "translate(-387.627 -334.242)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33534", d: "M378.376 324.14a8.518 8.518 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.526 8.526 0 0 1 3.155 2.917h.713v2.7h-.01c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0-2.48-1.432-3.773-3.291-3.868-5.167v-2.7z", transform: "translate(-189.395 -159.797)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56768", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56767" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56766", style: {
  clipPath: "url(#o1jl5rg6eak)"
}, transform: "translate(188.268 159.193)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33535", d: "M.713 5.15a8.518 8.518 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.526 8.526 0 0 1 3.155 2.917h.713v2.7h-.01c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0C1.388 11.585.095 9.726 0 7.85v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56771", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56770" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56769", style: {
  clipPath: "url(#95w3hbrhwS)"
}, transform: "translate(201.473 159.193)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33536", d: "M0 0a19.087 19.087 0 0 1 9.339 2.233 8.511 8.511 0 0 1 3.154 2.917h.714v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167A19.088 19.088 0 0 1 .001 15.25z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56774", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56773" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56772", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(208.686 160.431)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33537", d: "M0 0a14.433 14.433 0 0 1 2.125 1A8.517 8.517 0 0 1 5.28 3.917h.713v2.7H5.98c-.1 1.876-1.379 3.735-3.859 5.167a14.432 14.432 0 0 1-2.125 1z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56777", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56776" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56775", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(188.268 160.431)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33538", d: "M383.656 321.473a14.462 14.462 0 0 0-2.126 1 8.519 8.519 0 0 0-3.154 2.917h-.713v2.7c.095 1.876 1.388 3.736 3.867 5.168a14.461 14.461 0 0 0 2.126 1z", transform: "translate(-377.663 -321.473)", style: {
  fill: "#ffba00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33539", d: "M400.206 316.263c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.477 2.931-16.6.978l-.2-.08a14.28 14.28 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.058 10.058 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-189.394 -157.309)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56780", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56779" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56778", style: {
  clipPath: "url(#o1jl5rg6eak)"
}, transform: "translate(188.268 156.72)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33540", d: "M22.544 2.233c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.477 2.931-16.6.978l-.2-.08a14.28 14.28 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.058 10.058 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56783", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56782" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56781", style: {
  clipPath: "url(#qzj5s700gU)"
}, transform: "translate(188.268 160.573)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33541", d: "M379.388 321.758a5.774 5.774 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.391 5.355a14.343 14.343 0 0 1-1.867-.9c-4.3-2.482-5.016-6.252-2.143-9.162", transform: "translate(-377.662 -321.758)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56786", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56785" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56784", style: {
  clipPath: "url(#0gs2fo2ekam)"
}, transform: "translate(208.944 158.056)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33542", d: "M423.148 326.769a5.773 5.773 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.39-5.355a14.276 14.276 0 0 1 1.866.9c4.3 2.483 5.016 6.252 2.143 9.162", transform: "translate(-419.139 -316.71)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33543", d: "M400.493 327.185c4.794-2.768 4.794-7.255 0-10.023s-12.566-2.768-17.361 0-4.794 7.255 0 10.023 12.566 2.768 17.361 0", transform: "translate(-190.335 -157.839)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56789", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56788" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56787", style: {
  clipPath: "url(#7du574fufan)"
}, transform: "translate(189.202 157.246)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33544", d: "M400.493 327.185c4.794-2.768 4.794-7.255 0-10.023s-12.566-2.768-17.361 0-4.794 7.255 0 10.023 12.566 2.768 17.361 0", transform: "translate(-379.536 -315.086)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56792", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56791" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56790", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(204.851 159.017)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33545", d: "M419.273 326.071a4.538 4.538 0 0 0-.964-5.539 8.262 8.262 0 0 0-1.04-.911 10.427 10.427 0 0 0-.994-.655c-.2-.115-.4-.224-.6-.328.51.839.323 1.739-2.568 1.908-5.658.331 1.512 5.737 1.512 5.737s3.647-1.76 4.658-.212", transform: "translate(-410.928 -318.638)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56795", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56794" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56793", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(210.365 161.39)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33546", d: "M424.236 326.962c.849-.1 1.27-.968.94-1.95a2.241 2.241 0 0 0-2.135-1.606c-.849.1-1.27.968-.94 1.951a2.241 2.241 0 0 0 2.135 1.606", transform: "translate(-421.989 -323.397)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33547", d: "M394 329.368c-.279-2.1-9.463-7.918-9.463-7.918s-2.037-.817-1.36-2.241a10.6 10.6 0 0 0-.983.635q-.319.23-.6.473c-2.734 2.367-2.738 5.478 0 7.848.184.162.384.32.6.473a10.524 10.524 0 0 0 .911.593 13.396 13.396 0 0 0 1.801.866c.282.111.568.214.861.31a18.017 18.017 0 0 0 2.74.659c.2.033.411.063.617.089a21.128 21.128 0 0 0 2.74.172 20.771 20.771 0 0 0 3.351-.275 1.471 1.471 0 0 1-1.215-1.684", transform: "translate(-190.341 -159.907)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56798", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56797" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56796", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(189.209 159.303)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33548", d: "M394 329.368c-.279-2.1-9.463-7.918-9.463-7.918s-2.037-.817-1.36-2.241a10.6 10.6 0 0 0-.983.635q-.319.23-.6.473c-2.734 2.367-2.738 5.478 0 7.848.184.162.384.32.6.473a10.524 10.524 0 0 0 .911.593 13.396 13.396 0 0 0 1.801.866c.282.111.568.214.861.31a18.017 18.017 0 0 0 2.74.659c.2.033.411.063.617.089a21.128 21.128 0 0 0 2.74.172 20.771 20.771 0 0 0 3.351-.275 1.471 1.471 0 0 1-1.215-1.684", transform: "translate(-379.55 -319.21)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33549", d: "M388.487 320.257c-3.426 1.978-3.565 5.134-.418 7.207.134.088.274.175.42.26a14.3 14.3 0 0 0 12.932 0c3.571-2.062 3.573-5.405 0-7.467-.146-.085-.3-.165-.449-.242a14.4 14.4 0 0 0-12.486.242", transform: "translate(-193.48 -159.656)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56801", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56800" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56799", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(192.328 159.053)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33550", d: "M2.679 1.547C-.747 3.525-.886 6.681 2.261 8.754c.134.088.274.175.42.26a14.3 14.3 0 0 0 12.932 0c3.571-2.062 3.573-5.405 0-7.467-.146-.085-.3-.165-.449-.242a14.4 14.4 0 0 0-12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33551", d: "M389.187 320.656c-3.414 1.971-3.414 5.167 0 7.138a13.663 13.663 0 0 0 12.363 0c3.414-1.971 3.414-5.167 0-7.138a13.662 13.662 0 0 0-12.363 0", transform: "translate(-193.89 -159.891)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56804", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56803" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56802", style: {
  clipPath: "url(#oe6t937yoaa)"
}, transform: "translate(192.737 159.286)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33552", d: "M2.56 1.479c-3.414 1.971-3.414 5.167 0 7.138a13.663 13.663 0 0 0 12.363 0c3.414-1.971 3.414-5.167 0-7.138a13.662 13.662 0 0 0-12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33553", d: "M389.274 323.48a13.662 13.662 0 0 1 12.363 0 2.9 2.9 0 0 1 0 5.73 13.662 13.662 0 0 1-12.363 0 2.9 2.9 0 0 1 0-5.73", transform: "translate(-193.977 -161.307)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56807", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56806" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56805", style: {
  clipPath: "url(#c9vbyi1lgaB)"
}, transform: "translate(192.823 160.694)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33554", d: "M2.475 1.479a13.662 13.662 0 0 1 12.363 0 2.9 2.9 0 0 1 0 5.73 13.662 13.662 0 0 1-12.363 0 2.9 2.9 0 0 1 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33555", d: "M403.929 327.554v-1.043c0-.428-.3-.843-.933-1.215a.179.179 0 0 0-.022-.014 4.482 4.482 0 0 0-3.467-.318h-.007c-.459.116-.9.265-1.294.4a12.758 12.758 0 0 1-1.388.43 2.454 2.454 0 0 1-.66.063 1.4 1.4 0 0 1-.547-.145l.043-.03c.043-.028.089-.057.138-.085a3.089 3.089 0 0 1 .366-.182 2.535 2.535 0 0 1 1.944-.044l1.121-1.131v-1.038a5.335 5.335 0 0 0-3.064-.111 6.366 6.366 0 0 0-.975.337l-1.121-.647-1.338.772v1.047l.458.265a1.11 1.11 0 0 0-.2.6v1.036c0 .428.308.841.953 1.214a3.906 3.906 0 0 0 1.846.532h.369a7.31 7.31 0 0 0 1.984-.429c.132-.044.26-.088.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.322-.187l1.107.639 1.337-.772v-1.037l-.459-.265a1.211 1.211 0 0 0 .226-.674m-2.821-.089a2.776 2.776 0 0 1-.311.155 2.875 2.875 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.384 2.384 0 0 1-.17.108", transform: "translate(-196.946 -161.701)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33556", d: "M399.508 324.965h-.008c-.459.116-.9.265-1.294.4a12.834 12.834 0 0 1-1.388.43 2.433 2.433 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3.012 3.012 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.342 6.342 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.908 3.908 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.335.419.755-.217 1.122a2.775 2.775 0 0 1-.311.155 2.871 2.871 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-196.946 -161.701)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56810", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56809" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56808", style: {
  clipPath: "url(#3ykp529bmap)"
}, transform: "translate(195.775 161.085)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33557", d: "M399.508 324.965h-.008c-.459.116-.9.265-1.294.4a12.834 12.834 0 0 1-1.388.43 2.433 2.433 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3.012 3.012 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.342 6.342 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.908 3.908 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.335.419.755-.217 1.122a2.775 2.775 0 0 1-.311.155 2.871 2.871 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-392.721 -322.786)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56813", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56812" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56811", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(195.776 161.085)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33558", d: "m392.723 323.558 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-392.723 -322.786)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56816", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56815" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56814", style: {
  clipPath: "url(#60pt4crhgaq)"
}, transform: "translate(199.052 161.222)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33559", d: "M399.3 323.276a6.252 6.252 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.338 5.338 0 0 0-3.22-.069", transform: "translate(-399.296 -323.06)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56819", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56818" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56817", style: {
  clipPath: "url(#z9vntq720af)"
}, transform: "translate(202.561 163.061)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33560", d: "M409.8 327.269a.172.172 0 0 1 .022.014c1.3.761 1.171 1.7.059 2.559.986-1.074.808-1.823-.263-2.441a5.055 5.055 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-406.335 -326.749)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56822", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56821" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56820", style: {
  clipPath: "url(#8uozfx4tcaz)"
}, transform: "translate(205.878 166.154)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33561", d: "m413.22 332.954.848.652-1.079.759 1.337-.772z", transform: "translate(-412.989 -332.954)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56825", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56824" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56823", style: {
  clipPath: "url(#n27r1wyzpas)"
}, transform: "translate(200.449 165.92)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33562", d: "M405.5 332.484a2.875 2.875 0 0 1-2.132.186l-1.271 1.008 1.174-1.178a2.873 2.873 0 0 0 2.228-.016", transform: "translate(-402.099 -332.484)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56828", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56827" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56826", style: {
  clipPath: "url(#8qusk53ybai)"
}, transform: "translate(198.079 162.948)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33563", d: "M399.132 327.672a1.737 1.737 0 0 1-1.6-.152c-.386-.332-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.407 1.407 0 0 0 .636.19 2.454 2.454 0 0 0 .66-.063", transform: "translate(-397.344 -326.522)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56831", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56830" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56829", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(196.035 162.506)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33564", d: "M394.081 325.636c-.095.084-.9.835-.544 1.585a3.276 3.276 0 0 0 2.51 1.417 3.908 3.908 0 0 1-1.847-.532c-1.265-.73-1.242-1.616-.118-2.471", transform: "translate(-393.243 -325.636)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33565", d: "m422.052 323.88.333-2.526.333 2.526 4.375.192-4.375.192-.333 2.526-.333-2.526-4.375-.192z", transform: "translate(-209.461 -160.982)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33566", d: "M326.523 390.869a8.522 8.522 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0-2.48-1.432-3.773-3.291-3.868-5.167v-2.7z", transform: "translate(-163.391 -193.261)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56834", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56833" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56832", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(162.353 192.553)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33567", d: "M.713 5.15a8.522 8.522 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0C1.389 11.585.096 9.726.001 7.85v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56837", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56836" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56835", style: {
  clipPath: "url(#95w3hbrhwS)"
}, transform: "translate(175.384 192.567)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33568", d: "M.002 0a19.092 19.092 0 0 1 9.339 2.233 8.516 8.516 0 0 1 3.154 2.917h.714v2.7h-.007c-.1 1.876-1.379 3.735-3.858 5.167A19.087 19.087 0 0 1 .005 15.25z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56840", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56839" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56838", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(182.836 193.696)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33569", d: "M0-.002a14.43 14.43 0 0 1 2.125 1A8.518 8.518 0 0 1 5.28 3.915h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.859 5.167A14.428 14.428 0 0 1 0 12.777z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56843", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56842" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56841", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(162.419 193.738)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33570", d: "M5.991-.002a14.463 14.463 0 0 0-2.126 1A8.519 8.519 0 0 0 .711 3.915h-.714v2.7c.1 1.876 1.388 3.736 3.868 5.168a14.461 14.461 0 0 0 2.126.995z" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33571", d: "M348.353 382.992c5.157 2.978 5.157 7.805 0 10.782-4.512 2.605-11.477 2.931-16.6.978l-.2-.08a14.273 14.273 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.071 10.071 0 0 1 2.141-1.618c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-163.391 -190.773)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56846", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56845" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56844", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(162.441 189.985)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33572", d: "M22.543 2.233c5.157 2.978 5.157 7.805 0 10.782-4.512 2.605-11.477 2.931-16.6.978l-.2-.08a14.273 14.273 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.071 10.071 0 0 1 2.141-1.618c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56849", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56848" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56847", style: {
  clipPath: "url(#g46c00bkmav)"
}, transform: "translate(162.419 193.838)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33573", d: "M327.535 388.487a5.773 5.773 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.391 5.355a14.245 14.245 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.143-9.162", transform: "translate(-325.81 -388.487)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56852", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56851" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56850", style: {
  clipPath: "url(#0gs2fo2ekam)"
}, transform: "translate(183.095 191.321)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33574", d: "M371.294 393.5a5.773 5.773 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.391-5.355a14.25 14.25 0 0 1 1.867.9c4.3 2.483 5.016 6.252 2.143 9.162", transform: "translate(-367.286 -383.439)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33575", d: "M331.281 393.914c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-164.332 -191.303)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56855", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56854" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56853", style: {
  clipPath: "url(#7du574fufan)"
}, transform: "translate(163.354 190.512)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33576", d: "M331.281 393.914c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-327.685 -381.815)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56858", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56857" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56856", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(163.353 192.282)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33577", d: "M328.241 392.8a4.538 4.538 0 0 1 .964-5.539 8.231 8.231 0 0 1 1.04-.911 10.419 10.419 0 0 1 .994-.655c.2-.115.4-.224.605-.328-.51.839-.323 1.739 2.568 1.908 5.658.331-1.512 5.737-1.512 5.737s-3.647-1.76-4.658-.212", transform: "translate(-327.684 -385.367)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56861", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56860" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56859", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(163.442 194.655)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33578", d: "M328.916 393.691c-.849-.1-1.27-.969-.94-1.951a2.241 2.241 0 0 1 2.135-1.606c.849.1 1.27.968.94 1.95a2.242 2.242 0 0 1-2.135 1.606", transform: "translate(-327.863 -390.126)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33579", d: "M346.7 396.1c.279-2.1 9.463-7.918 9.463-7.918s2.036-.817 1.36-2.241a10.452 10.452 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848a7.83 7.83 0 0 1-.6.473 10.533 10.533 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.013 18.013 0 0 1-2.74.659c-.2.032-.411.063-.617.089a21.127 21.127 0 0 1-2.74.172 20.767 20.767 0 0 1-3.352-.275 1.471 1.471 0 0 0 1.217-1.684", transform: "translate(-173.26 -193.371)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56864", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56863" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56862", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(172.256 192.569)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33580", d: "M346.7 396.1c.279-2.1 9.463-7.918 9.463-7.918s2.036-.817 1.36-2.241a10.452 10.452 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848a7.83 7.83 0 0 1-.6.473 10.533 10.533 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.013 18.013 0 0 1-2.74.659c-.2.032-.411.063-.617.089a21.127 21.127 0 0 1-2.74.172 20.767 20.767 0 0 1-3.352-.275 1.471 1.471 0 0 0 1.217-1.684", transform: "translate(-345.49 -385.938)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33581", d: "M349.585 386.986c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242", transform: "translate(-167.485 -193.121)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56867", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56866" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56865", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(166.488 192.318)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33582", d: "M15.613 1.547c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.405 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33583", d: "M349.7 387.385c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0", transform: "translate(-167.886 -193.355)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56870", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56869" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56868", style: {
  clipPath: "url(#il2gc45k9ao)"
}, transform: "translate(166.887 192.551)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33584", d: "M14.927 1.479c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0C-.85 6.646-.85 3.45 2.564 1.479a13.662 13.662 0 0 1 12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33585", d: "M349.782 390.209a13.662 13.662 0 0 0-12.362 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.362 0 2.9 2.9 0 0 0 0-5.73", transform: "translate(-167.972 -194.771)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56873", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56872" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56871", style: {
  clipPath: "url(#ydwlu6emsab)"
}, transform: "translate(166.973 193.959)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33586", d: "M14.837 1.479a13.662 13.662 0 0 0-12.362 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.362 0 2.9 2.9 0 0 0 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33587", d: "M352.077 394.283v-1.043c0-.428-.3-.843-.933-1.216l-.022-.014a4.483 4.483 0 0 0-3.467-.317h-.009c-.459.116-.9.265-1.294.4a12.756 12.756 0 0 1-1.388.43 2.435 2.435 0 0 1-.66.062 1.4 1.4 0 0 1-.547-.145l.043-.03a2.19 2.19 0 0 1 .138-.085 3.083 3.083 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131v-1.038a5.335 5.335 0 0 0-3.064-.111 6.34 6.34 0 0 0-.974.337l-1.121-.647-1.338.773v1.037l.458.265a1.1 1.1 0 0 0-.2.6v1.035c0 .429.308.842.953 1.214a3.908 3.908 0 0 0 1.846.532h.369a7.312 7.312 0 0 0 1.984-.429c.132-.044.26-.088.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772v-1.037l-.459-.265a1.211 1.211 0 0 0 .226-.674m-2.821-.089a2.765 2.765 0 0 1-.311.155 2.877 2.877 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.384 2.384 0 0 1-.17.108", transform: "translate(-170.943 -195.164)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33588", d: "M347.655 391.693h-.009c-.459.116-.9.265-1.294.4a12.8 12.8 0 0 1-1.388.43 2.435 2.435 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.022a2.19 2.19 0 0 1 .138-.085 3.005 3.005 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.343 6.343 0 0 0-.974.337l-1.121-.647-1.338.773 1.109.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.913 3.913 0 0 0 1.847.532c.122.005.247 0 .369 0a7.319 7.319 0 0 0 1.984-.429c1.283-.429 2.323-.935 3.187-.436.58.334.419.755-.217 1.122a2.775 2.775 0 0 1-.311.155 2.871 2.871 0 0 1-2.227.016l-1.175 1.179a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-170.943 -195.165)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56876", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56875" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56874", style: {
  clipPath: "url(#s5s2du2pmac)"
}, transform: "translate(169.926 194.35)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33589", d: "M347.655 391.693h-.009c-.459.116-.9.265-1.294.4a12.8 12.8 0 0 1-1.388.43 2.435 2.435 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.022a2.19 2.19 0 0 1 .138-.085 3.005 3.005 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.343 6.343 0 0 0-.974.337l-1.121-.647-1.338.773 1.109.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.913 3.913 0 0 0 1.847.532c.122.005.247 0 .369 0a7.319 7.319 0 0 0 1.984-.429c1.283-.429 2.323-.935 3.187-.436.58.334.419.755-.217 1.122a2.775 2.775 0 0 1-.311.155 2.871 2.871 0 0 1-2.227.016l-1.175 1.179a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-340.868 -389.515)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56879", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56878" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56877", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(169.927 194.35)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33590", d: "m340.87 390.287 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-340.87 -389.515)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56882", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56881" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56880", style: {
  clipPath: "url(#60pt4crhgaq)"
}, transform: "translate(173.203 194.487)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33591", d: "M347.443 390.005a6.252 6.252 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.339 5.339 0 0 0-3.22-.069", transform: "translate(-347.443 -389.789)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56885", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56884" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56883", style: {
  clipPath: "url(#z9vntq720af)"
}, transform: "translate(176.712 196.326)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33592", d: "m357.95 394 .022.014c1.3.761 1.172 1.7.058 2.559.986-1.074.808-1.823-.263-2.441a5.056 5.056 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-354.483 -393.478)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56888", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56887" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56886", style: {
  clipPath: "url(#8uozfx4tcaz)"
}, transform: "translate(180.029 199.419)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33593", d: "m361.367 399.683.848.652-1.079.759 1.338-.772z", transform: "translate(-361.136 -399.683)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56891", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56890" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56889", style: {
  clipPath: "url(#n27r1wyzpas)"
}, transform: "translate(174.6 199.185)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33594", d: "M353.648 399.213a2.875 2.875 0 0 1-2.132.186l-1.271 1.008 1.175-1.178a2.872 2.872 0 0 0 2.227-.016", transform: "translate(-350.246 -399.213)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56894", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56893" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56892", style: {
  clipPath: "url(#7xfpzhvrpaA)"
}, transform: "translate(172.23 196.213)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33595", d: "M347.28 394.4a1.738 1.738 0 0 1-1.6-.152c-.386-.333-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.408 1.408 0 0 0 .636.19 2.453 2.453 0 0 0 .66-.063", transform: "translate(-345.491 -393.251)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56897", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56896" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56895", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(170.186 195.771)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33596", d: "M342.228 392.365c-.095.084-.9.835-.544 1.585a3.277 3.277 0 0 0 2.51 1.417 3.915 3.915 0 0 1-1.847-.531c-1.264-.73-1.242-1.617-.118-2.471", transform: "translate(-341.391 -392.365)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33597", d: "M320.38 384.683a8.522 8.522 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.676 0-2.48-1.432-3.773-3.291-3.867-5.167v-2.7z", transform: "translate(-160.311 -190.159)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56900", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56899" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56898", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(159.356 189.374)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33598", d: "M.713 5.15a8.522 8.522 0 0 1 3.155-2.917c5.157-2.977 13.518-2.977 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.676 0C1.388 11.585.095 9.726.001 7.85v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56903", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56902" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56901", style: {
  clipPath: "url(#95w3hbrhwS)"
}, transform: "translate(172.561 189.374)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33599", d: "M0 0a19.093 19.093 0 0 1 9.339 2.233 8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167A19.087 19.087 0 0 1 .001 15.25z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56906", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56905" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56904", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(179.774 190.612)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33600", d: "M0 0a14.44 14.44 0 0 1 2.126 1A8.519 8.519 0 0 1 5.28 3.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167a14.441 14.441 0 0 1-2.126 1z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56909", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56908" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56907", style: {
  clipPath: "url(#o3puewef1T)"
}, transform: "translate(159.356 190.612)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33601", d: "M5.993 0a14.46 14.46 0 0 0-2.126 1A8.523 8.523 0 0 0 .713 3.917H0v2.7c.095 1.876 1.388 3.736 3.867 5.168a14.462 14.462 0 0 0 2.126 1z" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33602", d: "M342.211 376.8c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.478 2.931-16.6.978l-.2-.08a14.284 14.284 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.058 10.058 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-160.311 -187.671)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56912", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56911" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56910", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(159.356 186.901)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33603", d: "M22.544 2.228c5.157 2.978 5.157 7.805 0 10.782-4.513 2.605-11.478 2.931-16.6.978l-.2-.08a14.284 14.284 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.058 10.058 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56915", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56914" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56913", style: {
  clipPath: "url(#0gs2fo2ekam)"
}, transform: "translate(159.356 190.754)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33604", d: "M321.392 382.3a5.773 5.773 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.39 5.355a14.274 14.274 0 0 1-1.866-.9c-4.3-2.483-5.015-6.252-2.143-9.162", transform: "translate(-319.667 -382.301)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56918", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56917" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56916", style: {
  clipPath: "url(#0gs2fo2ekam)"
}, transform: "translate(180.033 188.237)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33605", d: "M365.153 387.311a5.773 5.773 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.391-5.355a14.25 14.25 0 0 1 1.867.9c4.3 2.483 5.016 6.252 2.143 9.162", transform: "translate(-361.143 -377.252)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33606", d: "M325.138 387.727c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-161.251 -188.201)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56921", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56920" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56919", style: {
  clipPath: "url(#1l8ui3b87V)"
}, transform: "translate(160.285 187.4)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33607", d: "M325.138 387.727c-4.794-2.768-4.794-7.255 0-10.023s12.566-2.768 17.361 0 4.794 7.255 0 10.023-12.566 2.768-17.361 0", transform: "translate(-321.543 -375.628)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56924", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56923" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56922", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(160.291 189.198)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33608", d: "M322.1 386.613a4.539 4.539 0 0 1 .964-5.539 8.268 8.268 0 0 1 1.04-.911 10.533 10.533 0 0 1 .994-.656c.2-.114.4-.224.6-.328-.51.84-.323 1.739 2.568 1.908 5.658.331-1.512 5.737-1.512 5.737s-3.647-1.761-4.658-.212", transform: "translate(-321.541 -379.18)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56927", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56926" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56925", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(160.381 191.571)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33609", d: "M322.774 387.5c-.849-.1-1.27-.969-.94-1.951a2.241 2.241 0 0 1 2.135-1.606c.849.1 1.27.968.94 1.95a2.242 2.242 0 0 1-2.135 1.606", transform: "translate(-321.721 -383.94)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33610", d: "M340.562 389.911c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.555 10.555 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848-.184.162-.384.32-.6.473a10.512 10.512 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.016 18.016 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.128 21.128 0 0 1-2.74.172 20.774 20.774 0 0 1-3.351-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-170.18 -190.269)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56930", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56929" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56928", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(169.168 189.485)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33611", d: "M340.562 389.911c.279-2.1 9.463-7.918 9.463-7.918s2.037-.817 1.361-2.241a10.555 10.555 0 0 1 .982.635q.319.23.6.473c2.734 2.367 2.738 5.477 0 7.848-.184.162-.384.32-.6.473a10.512 10.512 0 0 1-.911.593 12.808 12.808 0 0 1-1.801.865c-.282.11-.568.215-.861.311a18.016 18.016 0 0 1-2.74.659c-.2.033-.411.063-.617.089a21.128 21.128 0 0 1-2.74.172 20.774 20.774 0 0 1-3.351-.275 1.471 1.471 0 0 0 1.214-1.683", transform: "translate(-339.348 -379.752)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33612", d: "M343.442 380.8c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.4 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242", transform: "translate(-164.404 -190.018)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56933", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56932" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56931", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(163.581 189.122)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33613", d: "M15.613 1.547c3.426 1.978 3.565 5.134.418 7.208a8.365 8.365 0 0 1-.42.259 14.291 14.291 0 0 1-12.932 0c-3.571-2.062-3.573-5.4 0-7.467.146-.084.3-.165.449-.242a14.4 14.4 0 0 1 12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33614", d: "M343.553 381.2c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0", transform: "translate(-164.805 -190.252)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56936", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56935" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56934", style: {
  clipPath: "url(#il2gc45k9ao)"
}, transform: "translate(163.825 189.467)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33615", d: "M14.923 1.48c3.414 1.971 3.414 5.167 0 7.138a13.665 13.665 0 0 1-12.363 0c-3.414-1.971-3.414-5.167 0-7.138a13.662 13.662 0 0 1 12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33616", d: "M343.639 384.022a13.662 13.662 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73", transform: "translate(-164.892 -191.669)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56939", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56938" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56937", style: {
  clipPath: "url(#ydwlu6emsab)"
}, transform: "translate(163.91 190.875)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33617", d: "M14.837 1.478a13.662 13.662 0 0 0-12.363 0 2.9 2.9 0 0 0 0 5.73 13.662 13.662 0 0 0 12.363 0 2.9 2.9 0 0 0 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33618", d: "M345.934 388.1v-1.043c0-.428-.3-.843-.933-1.215a.179.179 0 0 0-.022-.014 4.481 4.481 0 0 0-3.467-.318h-.012c-.459.116-.9.265-1.294.4a12.748 12.748 0 0 1-1.388.43 2.455 2.455 0 0 1-.66.063 1.414 1.414 0 0 1-.547-.145l.043-.03a2.19 2.19 0 0 1 .138-.085 3.083 3.083 0 0 1 .366-.182 2.535 2.535 0 0 1 1.944-.044l1.121-1.131v-1.038a5.334 5.334 0 0 0-3.064-.111 6.336 6.336 0 0 0-.974.337l-1.121-.647-1.337.773v1.037l.458.265a1.11 1.11 0 0 0-.2.6v1.036c0 .429.308.841.953 1.214a3.908 3.908 0 0 0 1.846.532h.369a7.291 7.291 0 0 0 1.984-.429c.132-.044.26-.088.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772v-1.037l-.459-.265a1.211 1.211 0 0 0 .226-.674m-2.821-.089a2.77 2.77 0 0 1-.311.155 2.875 2.875 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.384 2.384 0 0 1-.17.108", transform: "translate(-167.862 -192.062)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33619", d: "M341.513 385.507h-.013c-.46.116-.9.265-1.294.4a12.829 12.829 0 0 1-1.388.43 2.434 2.434 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.43-.248-.493-.612.133-1.022a2.19 2.19 0 0 1 .138-.085 3.006 3.006 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.33 5.33 0 0 0-3.064-.111 6.336 6.336 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.907 3.907 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.335.419.755-.217 1.122a2.77 2.77 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-167.862 -192.062)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56942", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56941" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56940", style: {
  clipPath: "url(#s5s2du2pmac)"
}, transform: "translate(166.864 191.266)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33620", d: "M341.513 385.507h-.013c-.46.116-.9.265-1.294.4a12.829 12.829 0 0 1-1.388.43 2.434 2.434 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.43-.248-.493-.612.133-1.022a2.19 2.19 0 0 1 .138-.085 3.006 3.006 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.33 5.33 0 0 0-3.064-.111 6.336 6.336 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.907 3.907 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.335.419.755-.217 1.122a2.77 2.77 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-334.726 -383.328)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56945", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56944" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56943", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(166.864 191.266)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33621", d: "m334.727 384.1 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-334.727 -383.329)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56948", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56947" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56946", style: {
  clipPath: "url(#4o7d44z7lae)"
}, transform: "translate(170.141 191.403)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33622", d: "M341.3 383.819a6.253 6.253 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.338 5.338 0 0 0-3.219-.069", transform: "translate(-341.301 -383.603)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56951", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56950" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56949", style: {
  clipPath: "url(#z9vntq720af)"
}, transform: "translate(173.65 193.242)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33623", d: "M351.807 387.812a.182.182 0 0 1 .022.014c1.3.761 1.172 1.7.059 2.559.986-1.074.808-1.823-.263-2.441a5.055 5.055 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-348.34 -387.292)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56954", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56953" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56952", style: {
  clipPath: "url(#8uozfx4tcaz)"
}, transform: "translate(176.967 196.335)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33624", d: "m355.225 393.5.848.652-1.079.759 1.338-.772z", transform: "translate(-354.994 -393.497)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56957", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56956" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56955", style: {
  clipPath: "url(#ki99t789lah)"
}, transform: "translate(171.538 196.101)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33625", d: "M347.505 393.026a2.878 2.878 0 0 1-2.132.186l-1.273 1.008 1.175-1.178a2.872 2.872 0 0 0 2.227-.016", transform: "translate(-344.103 -393.026)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56960", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56959" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56958", style: {
  clipPath: "url(#7xfpzhvrpaA)"
}, transform: "translate(169.168 193.129)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33626", d: "M341.137 388.215a1.737 1.737 0 0 1-1.6-.152c-.386-.333-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.407 1.407 0 0 0 .636.19 2.456 2.456 0 0 0 .66-.063", transform: "translate(-339.348 -387.065)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56963", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56962" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56961", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(167.124 192.687)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33627", d: "M336.086 386.178c-.095.084-.9.835-.544 1.585a3.276 3.276 0 0 0 2.51 1.417 3.913 3.913 0 0 1-1.847-.531c-1.265-.73-1.242-1.617-.118-2.471", transform: "translate(-335.248 -386.178)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33628", d: "M326 376.076a8.522 8.522 0 0 1 3.155-2.917c5.157-2.978 13.518-2.978 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0-2.48-1.432-3.773-3.291-3.868-5.167v-2.7z", transform: "translate(-163.127 -185.842)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56966", style: {
  opacity: 0.25
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56965" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56964", style: {
  clipPath: "url(#o1jl5rg6eak)"
}, transform: "translate(162.155 185.083)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33629", d: "M.716 5.15a8.522 8.522 0 0 1 3.155-2.917c5.157-2.978 13.518-2.978 18.675 0a8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.095 1.876-1.378 3.735-3.858 5.167-5.157 2.978-13.518 2.978-18.675 0C1.392 11.585.099 9.726.004 7.85v-2.7z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56969", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56968" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56967", style: {
  clipPath: "url(#95w3hbrhwS)"
}, transform: "translate(175.361 185.084)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33630", d: "M0 0a19.093 19.093 0 0 1 9.339 2.233 8.522 8.522 0 0 1 3.155 2.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.858 5.167A19.087 19.087 0 0 1 .001 15.25z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56972", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56971" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56970", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(182.574 186.321)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33631", d: "M0 0a14.406 14.406 0 0 1 2.125 1A8.517 8.517 0 0 1 5.28 3.917h.713v2.7h-.009c-.1 1.876-1.379 3.735-3.859 5.167a14.407 14.407 0 0 1-2.125 1z" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56975", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56974" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Mask Group 56973", style: {
  clipPath: "url(#4zpxutq2qal)"
}, transform: "translate(162.16 186.364)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33632", d: "M5.993 0a14.462 14.462 0 0 0-2.126 1A8.519 8.519 0 0 0 .716 3.912H.003v2.7c.095 1.876 1.388 3.736 3.867 5.168a14.463 14.463 0 0 0 2.126 1z" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33633", d: "M347.827 368.2c5.157 2.978 5.157 7.805 0 10.782-4.512 2.605-11.477 2.931-16.6.978l-.2-.08a14.271 14.271 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.056 10.056 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0", transform: "translate(-163.127 -183.355)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56978", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56977" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56976", style: {
  clipPath: "url(#tby7nkb2vR)"
}, transform: "translate(162.157 182.611)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33634", d: "M22.543 2.234c5.157 2.978 5.157 7.805 0 10.782-4.512 2.605-11.477 2.931-16.6.978l-.2-.08a14.271 14.271 0 0 1-1.867-.9c-4.3-2.483-5.015-6.252-2.142-9.162a10.056 10.056 0 0 1 2.141-1.619c5.157-2.977 13.519-2.978 18.676 0" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56981", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56980" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56979", style: {
  clipPath: "url(#g46c00bkmav)"
}, transform: "translate(162.157 186.464)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33635", d: "M327.009 373.7a5.773 5.773 0 0 0-1.381 4.7c.457 2.62 4.126 4.826 5.39 5.355a14.27 14.27 0 0 1-1.866-.9c-4.3-2.483-5.016-6.252-2.143-9.162", transform: "translate(-325.284 -373.695)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56984", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56983" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56982", style: {
  clipPath: "url(#g46c00bkmav)"
}, transform: "translate(182.833 183.947)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33636", d: "M370.769 378.705a5.773 5.773 0 0 0 1.381-4.7c-.457-2.62-4.126-4.826-5.39-5.356a14.272 14.272 0 0 1 1.866.9c4.3 2.483 5.015 6.252 2.143 9.162", transform: "translate(-366.76 -368.646)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33637", d: "M348.114 379.121c4.794-2.768 4.794-7.255 0-10.023s-12.566-2.768-17.361 0-4.794 7.255 0 10.023 12.566 2.768 17.361 0", transform: "translate(-164.067 -183.885)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56987", style: {
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56986" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56985", style: {
  clipPath: "url(#1l8ui3b87V)"
}, transform: "translate(163.091 183.137)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33638", d: "M348.114 379.121c4.794-2.768 4.794-7.255 0-10.023s-12.566-2.768-17.361 0-4.794 7.255 0 10.023 12.566 2.768 17.361 0", transform: "translate(-327.158 -367.022)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56990", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56989" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56988", style: {
  clipPath: "url(#b5o0xbdnyW)"
}, transform: "translate(178.74 184.908)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33639", d: "M366.894 378.007a4.538 4.538 0 0 0-.964-5.539 8.229 8.229 0 0 0-1.04-.911 10.538 10.538 0 0 0-.994-.656c-.2-.114-.4-.224-.6-.328.51.84.323 1.739-2.568 1.908-5.658.331 1.513 5.737 1.513 5.737s3.647-1.761 4.658-.212", transform: "translate(-358.549 -370.574)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56993", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56992" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56991", style: {
  clipPath: "url(#0e6tlh3xrX)"
}, transform: "translate(184.254 187.281)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33640", d: "M371.857 378.9c.849-.1 1.27-.968.94-1.951a2.241 2.241 0 0 0-2.135-1.606c-.849.1-1.27.968-.94 1.95a2.242 2.242 0 0 0 2.135 1.606", transform: "translate(-369.61 -375.334)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33641", d: "M341.625 381.3c-.279-2.1-9.463-7.918-9.463-7.918s-2.037-.817-1.36-2.241a10.461 10.461 0 0 0-.983.635q-.319.23-.6.473c-2.734 2.367-2.737 5.478 0 7.847.184.162.384.32.6.473a10.524 10.524 0 0 0 .911.593 12.808 12.808 0 0 0 1.801.865c.282.11.568.215.861.311a18.012 18.012 0 0 0 2.74.659c.2.033.411.063.617.089a21.128 21.128 0 0 0 2.74.172 20.767 20.767 0 0 0 3.351-.275 1.471 1.471 0 0 1-1.214-1.683", transform: "translate(-164.074 -185.953)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56996", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56995" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56994", style: {
  clipPath: "url(#uhnyelcniY)"
}, transform: "translate(163.097 185.193)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33642", d: "M341.625 381.3c-.279-2.1-9.463-7.918-9.463-7.918s-2.037-.817-1.36-2.241a10.461 10.461 0 0 0-.983.635q-.319.23-.6.473c-2.734 2.367-2.737 5.478 0 7.847.184.162.384.32.6.473a10.524 10.524 0 0 0 .911.593 12.808 12.808 0 0 0 1.801.865c.282.11.568.215.861.311a18.012 18.012 0 0 0 2.74.659c.2.033.411.063.617.089a21.128 21.128 0 0 0 2.74.172 20.767 20.767 0 0 0 3.351-.275 1.471 1.471 0 0 1-1.214-1.683", transform: "translate(-327.171 -371.146)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33643", d: "M336.108 372.193c-3.426 1.978-3.565 5.134-.418 7.208.134.088.274.175.42.259a14.291 14.291 0 0 0 12.932 0c3.571-2.062 3.573-5.405 0-7.467-.146-.084-.3-.165-.449-.242a14.4 14.4 0 0 0-12.486.242", transform: "translate(-167.212 -185.702)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56999", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56998" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 56997", style: {
  clipPath: "url(#kiqvpffaiZ)"
}, transform: "translate(166.217 184.944)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33644", d: "M2.679 1.547C-.747 3.525-.886 6.681 2.261 8.755c.134.088.274.175.42.259a14.291 14.291 0 0 0 12.932 0c3.571-2.062 3.573-5.405 0-7.467-.146-.084-.3-.165-.449-.242a14.4 14.4 0 0 0-12.486.242" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33645", d: "M336.808 372.592c-3.414 1.971-3.414 5.167 0 7.138a13.666 13.666 0 0 0 12.363 0c3.413-1.971 3.413-5.167 0-7.138a13.663 13.663 0 0 0-12.363 0", transform: "translate(-167.623 -185.936)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57002", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57001" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57000", style: {
  clipPath: "url(#oe6t937yoaa)"
}, transform: "translate(166.625 185.177)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33646", d: "M2.56 1.478c-3.414 1.971-3.414 5.167 0 7.138a13.666 13.666 0 0 0 12.363 0c3.413-1.971 3.413-5.167 0-7.138a13.663 13.663 0 0 0-12.363 0" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33647", d: "M336.9 375.416a13.663 13.663 0 0 1 12.363 0 2.9 2.9 0 0 1 0 5.73 13.663 13.663 0 0 1-12.363 0 2.9 2.9 0 0 1 0-5.73", transform: "translate(-167.709 -187.353)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57005", style: {
  opacity: 0.35
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57004" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57003", style: {
  clipPath: "url(#ydwlu6emsab)"
}, transform: "translate(166.711 186.585)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33648", d: "M2.479 1.478a13.663 13.663 0 0 1 12.363 0 2.9 2.9 0 0 1 0 5.73 13.663 13.663 0 0 1-12.363 0 2.9 2.9 0 0 1 0-5.73" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33649", d: "M351.55 379.491v-1.043c0-.428-.3-.843-.933-1.215l-.022-.014a4.48 4.48 0 0 0-3.467-.318h-.009c-.459.116-.9.265-1.294.4a12.743 12.743 0 0 1-1.388.43 2.436 2.436 0 0 1-.66.062 1.393 1.393 0 0 1-.547-.145l.043-.03a2.19 2.19 0 0 1 .138-.085 3.09 3.09 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131v-1.038a5.335 5.335 0 0 0-3.064-.111 6.343 6.343 0 0 0-.974.337l-1.121-.647-1.338.773v1.037l.459.265a1.109 1.109 0 0 0-.2.6v1.036c0 .428.308.841.953 1.214a3.907 3.907 0 0 0 1.846.532h.369a7.309 7.309 0 0 0 1.984-.429q.2-.066.386-.133l-1.128 1.131v1.037a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.338-.772v-1.03l-.46-.265a1.208 1.208 0 0 0 .226-.674m-2.821-.089a2.77 2.77 0 0 1-.311.155 2.877 2.877 0 0 1-2.187.032c1.047-.369 1.922-.692 2.668-.3a2.384 2.384 0 0 1-.17.108", transform: "translate(-170.679 -187.746)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33650", d: "M347.129 376.9h-.009c-.459.116-.9.265-1.294.4a12.854 12.854 0 0 1-1.388.43 2.435 2.435 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3.005 3.005 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.345 6.345 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.906 3.906 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.334.419.755-.217 1.122a2.767 2.767 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-170.679 -187.746)", style: {
  fill: "#ffba00"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57008", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57007" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57006", style: {
  clipPath: "url(#3ykp529bmap)"
}, transform: "translate(169.663 186.976)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33651", d: "M347.129 376.9h-.009c-.459.116-.9.265-1.294.4a12.854 12.854 0 0 1-1.388.43 2.435 2.435 0 0 1-.66.062 1.4 1.4 0 0 1-.636-.19c-.431-.248-.493-.612.133-1.023.043-.028.089-.056.138-.084a3.005 3.005 0 0 1 .366-.182 2.533 2.533 0 0 1 1.944-.044l1.121-1.131a5.331 5.331 0 0 0-3.064-.111 6.345 6.345 0 0 0-.974.337l-1.121-.647-1.338.773 1.108.64-.01.008c-1.124.854-1.147 1.741.118 2.471a3.906 3.906 0 0 0 1.847.531c.122.005.247 0 .369 0a7.3 7.3 0 0 0 1.984-.428c1.283-.429 2.323-.935 3.188-.436.579.334.419.755-.217 1.122a2.767 2.767 0 0 1-.311.155 2.872 2.872 0 0 1-2.227.016l-1.175 1.178a5.5 5.5 0 0 0 4.323-.187l1.107.639 1.337-.772-1.107-.639c1.113-.861 1.241-1.8-.059-2.56l-.022-.014a4.483 4.483 0 0 0-3.467-.317", transform: "translate(-340.342 -374.722)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57011", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57010" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57009", style: {
  clipPath: "url(#tp1ynklqjad)"
}, transform: "translate(169.664 186.976)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33652", d: "m340.344 375.495 1.35-.612 1.108.487-1.121-.647z", transform: "translate(-340.344 -374.723)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57014", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57013" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57012", style: {
  clipPath: "url(#60pt4crhgaq)"
}, transform: "translate(172.941 187.113)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33653", d: "M346.917 375.212a6.253 6.253 0 0 1 2.875.1l-.777 1.1 1.121-1.131a5.339 5.339 0 0 0-3.22-.069", transform: "translate(-346.917 -374.997)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57017", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57016" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57015", style: {
  clipPath: "url(#z9vntq720af)"
}, transform: "translate(176.45 188.952)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33654", d: "M357.423 379.2a.181.181 0 0 1 .022.014c1.3.761 1.171 1.7.059 2.56.986-1.074.808-1.824-.263-2.442a5.055 5.055 0 0 0-3.285-.449 4.481 4.481 0 0 1 3.467.317", transform: "translate(-353.956 -378.685)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57020", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57019" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57018", style: {
  clipPath: "url(#8uozfx4tcaz)"
}, transform: "translate(179.767 192.045)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33655", d: "m360.841 384.891.848.652-1.079.759 1.337-.772z", transform: "translate(-360.61 -384.891)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57023", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57022" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57021", style: {
  clipPath: "url(#n27r1wyzpas)"
}, transform: "translate(174.338 191.81)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33656", d: "M353.122 384.42a2.875 2.875 0 0 1-2.132.186l-1.271 1.009 1.174-1.179a2.873 2.873 0 0 0 2.228-.016", transform: "translate(-349.72 -384.42)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57026", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57025" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57024", style: {
  clipPath: "url(#8qusk53ybai)"
}, transform: "translate(171.968 188.839)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33657", d: "M346.753 379.609a1.737 1.737 0 0 1-1.6-.152c-.386-.333-.138-.689.439-1-.626.41-.563.774-.133 1.023a1.413 1.413 0 0 0 .636.19 2.474 2.474 0 0 0 .66-.063", transform: "translate(-344.965 -378.459)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57029", style: {
  opacity: 0.45
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57028" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57027", style: {
  clipPath: "url(#aye39piwkaj)"
}, transform: "translate(169.923 188.397)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33658", d: "M341.7 377.572c-.095.084-.9.834-.544 1.585a3.275 3.275 0 0 0 2.51 1.417 3.912 3.912 0 0 1-1.847-.531c-1.265-.73-1.242-1.617-.118-2.471", transform: "translate(-340.864 -377.572)", style: {
  fill: "#f5f5f5"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33659", d: "m369.673 375.816.333-2.526.333 2.526 4.375.192-4.375.192-.333 2.526-.333-2.526-4.375-.192z", transform: "translate(-183.194 -187.028)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57092" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33660", d: "M313.151 62.558c8.507-1.347 12.868-5.485 15.753-10.593 2.11-3.736 2.906-10.223 1.957-15.011a26.606 26.606 0 0 0-2.768-7.666c1.506-3.9.55-8.262-1.361-12-2.438-4.769-7.071-8.309-12.674-7.567-.911.121-.244.984.5 1.324 6.424 2.92 8.4 7.79 8.987 12.687a15.01 15.01 0 0 0-1.4-1.015c-4.588-2.921-12.029-3.641-17.059-1.57-5.073 2.089-5.84 7.037-2.364 11 4.7 5.36 13.435 6.8 19.643 3.443a19.929 19.929 0 0 1 .828 6.986 13.625 13.625 0 0 1-6.893 11 14.083 14.083 0 0 1-6.447 1.475 16.292 16.292 0 0 1-6.559-1.515 2.2 2.2 0 0 0-2.791 1.575c-2.37 6.9 8.071 8.17 12.648 7.445m6.773-31.956c-2.5 1.314-6.124 1.966-8.749.957a8.235 8.235 0 0 1-3.861-2.45c-1.489-1.971.629-2.959 2.971-2.947a11.671 11.671 0 0 1 9.639 4.44", transform: "translate(-152.244 -4.825)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57034", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57033" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57032", style: {
  clipPath: "url(#tdcxx7etvaC)"
}, transform: "translate(170.121 24.463)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33661", d: "M5.728 0a25.992 25.992 0 0 1 2.317 5.788 12.479 12.479 0 0 1-7.277 4.419A20 20 0 0 0 0 6.307 11.957 11.957 0 0 0 5.728.002" })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57037", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57036" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57035", style: {
  clipPath: "url(#sbh2gewt8aD)"
}, transform: "translate(166.748 18.906)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33662", d: "M.932 6.875A11.314 11.314 0 0 0 0 5.803a10.085 10.085 0 0 0 2.794-2.37A6.76 6.76 0 0 0 4.555.003 8.286 8.286 0 0 1 .932 6.875" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33663", d: "M195.017 310.59c-.009-.048-.016-.1-.025-.145l-.026-.152h.005c-.486-3.391 1.5-6.9 5.966-9.544 8.13-4.812 21.393-4.955 29.625-.32 4.527 2.549 6.583 6.012 6.17 9.413h.006l-.023.156c-.006.046 8.688-1.22 8.688-1.22l-1.98 2.127a38.678 38.678 0 0 0-9.935 20.611l-1.1 7.361v.009l-.011.077c-.352 2.074-1.889 4.1-4.62 5.72-6.323 3.742-16.638 3.853-23.041.249-2.765-1.557-4.346-3.552-4.742-5.619l-.01-.057c0-.018-.006-.035-.008-.053z", transform: "translate(-99.458 -148.966)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57040", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57039" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57038", style: {
  clipPath: "url(#9wd0dmhihaE)"
}, transform: "translate(94.865 148.08)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33664", d: "M195.017 310.59c-.009-.048-.016-.1-.025-.145l-.026-.152h.005c-.486-3.391 1.5-6.9 5.966-9.544 8.13-4.812 21.393-4.955 29.625-.32 4.527 2.549 6.583 6.012 6.17 9.413h.006l-.023.156c-.006.046 8.688-1.22 8.688-1.22l-1.98 2.127a38.678 38.678 0 0 0-9.935 20.611l-1.1 7.361v.009l-.011.077c-.352 2.074-1.889 4.1-4.62 5.72-6.323 3.742-16.638 3.853-23.041.249-2.765-1.557-4.346-3.552-4.742-5.619l-.01-.057c0-.018-.006-.035-.008-.053z", transform: "translate(-194.9 -297.046)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33665", d: "m282.356 255.508-.025-.145-.026-.152h.005c-.486-3.391 1.556-6.8 5.966-9.544 3.118-1.942 19.443-2.119 28.89-2.221 5.614-.06 8.694-.661 8.282 2.74h.006l-.023.156c-.007.046 6.161-7.752 6.161-7.752a106.178 106.178 0 0 0-9.681 30.849l-2.187 14.355v.009l-.011.077c-.352 2.074-1.889 4.1-4.62 5.72-6.323 3.742-16.639 3.853-23.041.249-2.765-1.557-4.346-3.553-4.742-5.619l-.009-.057c0-.017-.007-.035-.009-.052z", transform: "translate(-143.258 -119.652)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33666", d: "m141.43 280.059.022-.145.022-.152h-.005c.413-3.4-1.7-6.762-6.171-9.413-3.159-1.874-19.484-1.7-28.93-1.6-5.614.06-8.707-.474-8.221 2.918h-.005l.026.155.024.138 6.492 37.181v.009l.013.076c.4 2.066 1.977 4.062 4.742 5.619 6.4 3.6 16.718 3.494 23.041-.249 2.731-1.617 4.268-3.646 4.62-5.72l.008-.057c0-.018.005-.035.008-.053z", transform: "translate(-50.912 -134.749)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33667", d: "m141.43 280.059.022-.145.022-.152h-.005c.413-3.4-1.7-6.762-6.171-9.413-3.159-1.874-19.484-1.7-28.93-1.6-5.614.06-8.707-.474-8.221 2.918h-.005l.026.155.024.138 6.492 37.181v.009l.013.076c.4 2.066 1.977 4.062 4.742 5.619 6.4 3.6 16.718 3.494 23.041-.249 2.731-1.617 4.268-3.646 4.62-5.72l.008-.057c0-.018.005-.035.008-.053z", transform: "translate(-50.912 -134.749)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33668", d: "M124.656 70.671c9.237-4.119 21.834-5.827 32.638-5.943a66.762 66.762 0 0 1 67.48 66.037c.265 24.533-12.557 47.54-30.68 61.505a145.294 145.294 0 0 1-29.514 16.877c-13.615 5.528-22.19 6.12-34.518 6.253a66.762 66.762 0 0 1-67.48-66.037c-.182-16.924 6.1-31.98 16.132-44.027 4.152-4.984 10.777-12.633 16.024-16.506 10.228-7.55 19.658-13.585 29.919-18.16", transform: "translate(-33.1 -32.459)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57043", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57042" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57041", style: {
  opacity: 0.9,
  clipPath: "url(#sj490sut2aF)"
}, transform: "translate(29.479 32.266)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33669", d: "M124.656 70.671c9.237-4.119 21.834-5.827 32.638-5.943a66.762 66.762 0 0 1 67.48 66.037c.265 24.533-12.557 47.54-30.68 61.505a145.294 145.294 0 0 1-29.514 16.877c-13.615 5.528-22.19 6.12-34.518 6.253a66.762 66.762 0 0 1-67.48-66.037c-.182-16.924 6.1-31.98 16.132-44.027 4.152-4.984 10.777-12.633 16.024-16.506 10.228-7.55 19.658-13.585 29.919-18.16", transform: "translate(-62.578 -64.725)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57046", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57045" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57044", style: {
  clipPath: "url(#1mz3b6mwaaG)"
}, transform: "translate(85.074 130.911)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33670", d: "M198.495 274.8a12.2 12.2 0 1 1-12.2-12.2 12.2 12.2 0 0 1 12.2 12.2", transform: "translate(-174.101 -262.606)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57049", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57048" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57047", style: {
  clipPath: "url(#dpv4f659iaH)"
}, transform: "translate(29.479 101.561)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33671", d: "M71.1 214.7a12.2 12.2 0 0 0-6.864-10.971 65.015 65.015 0 0 0-1.654 15.344 67.447 67.447 0 0 0 .454 7.1A12.2 12.2 0 0 0 71.1 214.7", transform: "translate(-62.579 -203.729)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57052", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57051" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57050", style: {
  clipPath: "url(#0qpsc7edcaI)"
}, transform: "translate(46.821 50.287)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33672", d: "M201.5 196.954c25.545-21.108 40.512-67.386 19.584-96.078a66.572 66.572 0 0 1 21.136 48.019c.265 24.533-12.557 47.541-30.68 61.505a145.3 145.3 0 0 1-29.514 16.878c-13.615 5.527-22.19 6.12-34.518 6.253A66.585 66.585 0 0 1 98 212.348c-.212-.231-.427-.464-.637-.695 37.751 26.555 78.588 6.409 104.133-14.7", transform: "translate(-97.367 -100.876)", style: {
  fill: "#cb3f00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33673", d: "m122.836 101-10.245 6.064c-10.341-2.864-22.648-.921-30.386 12.469 0 0-3.027-31.143.856-39.8 0 0 2.124-1.25 2.976-1.763a9.075 9.075 0 0 1 5.916-.618c12.754 2.087 24.355 12.539 30.884 23.649", transform: "translate(-42.379 -38.69)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33674", d: "M109.86 108.682c-13.387-3.282-23.515 1.007-30.386 12.469 0 0-12.192-44.677 6.886-40.488 12.918 2.837 23.5 28.02 23.5 28.02", transform: "translate(-39.648 -40.313)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57055", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57054" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57053", style: {
  clipPath: "url(#uyebow8edaJ)"
}, transform: "translate(35.761 40.072)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33675", d: "M109.86 108.682c-13.387-3.282-23.515 1.007-30.386 12.469 0 0-12.192-44.677 6.886-40.488 12.918 2.837 23.5 28.02 23.5 28.02", transform: "translate(-75.637 -80.385)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57058", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57057" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57056", style: {
  clipPath: "url(#kyfc84jruaK)"
}, transform: "translate(39.782 62.755)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33676", d: "M94.3 127.245c12.358-4.687 19.377 4.255 19.377 4.255-13.387-3.282-23.515 1.007-30.386 12.469A16.428 16.428 0 0 1 94.3 127.245", transform: "translate(-83.247 -125.886)", style: {
  fill: "#cb3f00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33677", d: "M185.9 147.806c11.408 10.156 21.691 23.088 29.331 42.538l9.813-5.767c3.663-3.8 12.942-35.845-5.754-47.547-12.49-7.819-33.39 10.777-33.39 10.777", transform: "translate(-94.946 -67.757)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33678", d: "M185.9 148.692a107.35 107.35 0 0 1 29.331 42.538s20.354-36.118 1.5-51.8c-11.011-9.157-30.829 9.262-30.829 9.262", transform: "translate(-94.946 -68.643)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57061", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57060" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57059", style: {
  clipPath: "url(#asde3jxt1aL)"
}, transform: "translate(90.958 68.235)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33679", d: "M185.9 148.692a107.35 107.35 0 0 1 29.331 42.538s20.354-36.118 1.5-51.8c-11.011-9.157-30.829 9.262-30.829 9.262", transform: "translate(-185.904 -136.878)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57064", style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57063" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57062", style: {
  clipPath: "url(#6upjlrd0taM)"
}, transform: "translate(90.958 80.049)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33680", d: "M185.9 160.578s11.349-.123 21.27 13.163c9.279 12.424 8.06 29.375 8.06 29.375a107.35 107.35 0 0 0-29.33-42.538", transform: "translate(-185.904 -160.578)", style: {
  fill: "#cb3f00"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33681", d: "M71.275 227.413c3.969-2.344 9.639-2.011 15.962 1.549 12.174 6.854 22.158 23.069 22.3 36.217.068 6.276-2.121 10.716-5.751 12.9a1607.843 1607.843 0 0 1-10.38 6.148c-3.966 2.3-9.609 1.954-15.9-1.587-12.174-6.854-22.158-23.068-22.3-36.217-.068-6.27 2.117-10.707 5.741-12.891 1.753-1.057 8.588-5.09 10.327-6.117", transform: "translate(-29.403 -113.296)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57067", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57066" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57065", style: {
  clipPath: "url(#srtlmmuz1aN)"
}, transform: "translate(25.804 112.622)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33682", d: "M60.948 233.53c1.753-1.056 8.588-5.089 10.327-6.116 3.969-2.344 9.639-2.011 15.962 1.549a43.79 43.79 0 0 1 13.525 12.553l-39.936 23.635c-3.436-6.1-5.554-12.73-5.619-18.729-.068-6.271 2.117-10.708 5.741-12.892", transform: "translate(-55.206 -225.918)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57070", style: {
  opacity: 0.1
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57069" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57068", style: {
  clipPath: "url(#l613kc7vcaO)"
}, transform: "translate(31.424 128.22)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33683", d: "m112.818 269.1-38.255 22.531a51.533 51.533 0 0 1-8.083-10.787l39.936-23.635a50.759 50.759 0 0 1 6.4 11.889", transform: "translate(-66.48 -257.208)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33684", d: "M76.994 241.125c-12.174-6.854-21.927-1.752-21.785 11.4s10.126 29.362 22.3 36.216 21.927 1.752 21.785-11.4-10.126-29.362-22.3-36.216", transform: "translate(-29.403 -119.395)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33685", d: "M76.994 241.125c-12.174-6.854-21.927-1.752-21.785 11.4s10.126 29.362 22.3 36.216 21.927 1.752 21.785-11.4-10.126-29.362-22.3-36.216", transform: "translate(-29.403 -119.395)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57073", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57072" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57071", style: {
  clipPath: "url(#18gp241wxaP)"
}, transform: "translate(25.803 118.579)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33686", d: "M76.994 241.125c-12.174-6.854-21.927-1.752-21.785 11.4s10.126 29.362 22.3 36.216 21.927 1.752 21.785-11.4-10.126-29.362-22.3-36.216", transform: "translate(-55.207 -238.078)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33687", d: "M112.342 300.358c1.485.836 2.671-.081 2.65-2.049l-.109-10.031a6.4 6.4 0 0 0-2.727-5.076c-1.485-.836-2.671.081-2.65 2.048l.109 10.032a6.4 6.4 0 0 0 2.727 5.076", transform: "translate(-56.634 -141.868)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57076", style: {
  opacity: 0.15
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57075" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57074", style: {
  clipPath: "url(#qjn547yq5aQ)"
}, transform: "translate(54.774 141.024)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33689", d: "m3.48 5.319.108 10.031a3.6 3.6 0 0 1-.164 1.166 2.53 2.53 0 0 1-.585-.243 6.4 6.4 0 0 1-2.727-5.076L.003 1.165A3.6 3.6 0 0 1 .168-.001a2.534 2.534 0 0 1 .585.242A6.4 6.4 0 0 1 3.48 5.317" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33690", d: "M81.177 282.8c1.485.836 2.671-.081 2.65-2.049l-.109-10.032a6.4 6.4 0 0 0-2.727-5.076c-1.485-.836-2.671.081-2.65 2.048l.109 10.031a6.4 6.4 0 0 0 2.727 5.076", transform: "translate(-41.005 -133.065)", style: {
  fill: "#ff5001"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57079", style: {
  opacity: 0.15
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57078" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57077", style: {
  clipPath: "url(#qjn547yq5aQ)"
}, transform: "translate(39.238 132.239)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33692", d: "m3.476 5.319.108 10.031a3.6 3.6 0 0 1-.164 1.166 2.528 2.528 0 0 1-.585-.243 6.4 6.4 0 0 1-2.727-5.076L-.001 1.165A3.6 3.6 0 0 1 .163-.001a2.536 2.536 0 0 1 .585.242 6.4 6.4 0 0 1 2.727 5.076" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33694", d: "M165.251 231.651c-.731 2.752 2.231.593 4.983 1.324s4.251 4.076 4.983 1.324a5.156 5.156 0 1 0-9.965-2.648", transform: "translate(-84.532 -114.249)", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33695", d: "M90.062 195.27c-1.667 2.309 1.871 1.351 4.179 3.018s2.513 5.327 4.18 3.018a5.156 5.156 0 0 0-8.359-6.037", transform: "translate(-46.666 -96.855)", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33696", d: "m195.4 110.807 23.049-13.642-5.187-2.921-23.05 13.642z", transform: "translate(-97.105 -47.263)", style: {
  fill: "#ba68c8"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57085", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57084" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57083", style: {
  clipPath: "url(#vf7feewj8aR)"
}, transform: "translate(93.103 46.981)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33697", d: "M5.192 16.563 28.241 2.921 23.054 0 .004 13.642z" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33698", d: "m213.257 94.244.032 2.976-20.489 12.126-2.594-1.46z", transform: "translate(-97.105 -47.263)", style: {
  fill: "#cd4000"
} }), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57082", style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57081" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57080", style: {
  clipPath: "url(#2ypua9jgpaS)"
}, transform: "translate(37.13 134.22)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33693", d: "M106.393 302.447c5.455-7.68 1.717-23.6-4.526-33.2 5.285 7.3 8.738 16.038 8.821 23.727.142 13.148-9.612 18.25-21.785 11.4a41.518 41.518 0 0 1-10.976-9.3c7.72 9.052 22.642 15.585 28.466 7.385", transform: "translate(-77.927 -269.242)", style: {
  fill: "#fff"
} })))), /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57088", style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57087" }, /* @__PURE__ */ React.createElement("g", { "data-name": "Group 57086", style: {
  clipPath: "url(#tg44tk2ufaT)"
}, transform: "translate(116.153 46.981)" }, /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33699", d: "m0 0 .032 2.976 2.594 1.461 2.561-1.516z" })))), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33700", d: "m217.635 24.344-.442 17.492-20.093-.221-.622-5.869z", transform: "translate(-100.249 -12.209)", style: {
  fill: "#34b152"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33701", d: "m186.361 39.543-.622-5.869s11.617-.208 19.418-8.422c4.6-4.84-.526-6.161-8.291-4.1s-22.52 8.569-21.775 13.512c.841 5.586 11.27 4.88 11.27 4.88", transform: "translate(-89.511 -10.137)", style: {
  fill: "#084617"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33702", d: "m187.829 76.8-2.77-1.56-9.941-37.459c.279 1.745 5.985 3.948 11.818 3.715 16.039-.64 26.652-15.061 26.652-15.061l-3.88 37.416z", transform: "translate(-89.538 -13.259)", style: {
  fill: "#34b152"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33703", d: "M186.761 93.068c4.638 1.5 9.668.321 13.922 4.2a9.055 9.055 0 0 1 3.036 7l-10.051 5.949-2.77-1.56z", transform: "translate(-95.376 -46.673)", style: {
  fill: "#084617"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33704", d: "M187.909 55.567c-1.181.051-5.964.284-9.727-2.623l.418 1.574a15.565 15.565 0 0 0 9.353 2.053c9.394-.374 17-4.922 21.979-8.989l-2.942 28.195 1.077-.637 3.125-29.947-.959.826c-4.812 4.141-12.591 9.16-22.322 9.548", transform: "translate(-91.074 -22.664)", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33705", d: "M175.119 37.785a2.005 2.005 0 0 0 .822 1.073 7.21 7.21 0 0 0 1.18.711 15.124 15.124 0 0 0 2.591.948 23.833 23.833 0 0 0 5.448.813 29.185 29.185 0 0 0 10.784-1.938 38.539 38.539 0 0 0 9.608-5.36 45.805 45.805 0 0 0 4.232-3.561 42.9 42.9 0 0 0 3.8-4.031 41.406 41.406 0 0 1-7.789 7.916 36.887 36.887 0 0 1-9.684 5.494 28.2 28.2 0 0 1-10.968 1.815 22.641 22.641 0 0 1-5.492-.951 15.316 15.316 0 0 1-2.585-1.044 6.769 6.769 0 0 1-1.168-.763 2.031 2.031 0 0 1-.784-1.121", transform: "translate(-89.538 -13.259)", style: {
  fill: "#084617"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33706", d: "M225.6 66.85a1.975 1.975 0 0 1 .868-.073 1.278 1.278 0 0 1 .61.268 1.2 1.2 0 0 1 .356.5 1.747 1.747 0 0 1 .12.609.535.535 0 0 1-.09.3.56.56 0 0 1-.207.2l-.722.37c-.118.06-.2.076-.26.048a.375.375 0 0 1-.147-.153.7.7 0 0 0-.441-.341 1.427 1.427 0 0 0-.921.231 3.073 3.073 0 0 0-.515.335 2.938 2.938 0 0 0-.435.431 2.21 2.21 0 0 0-.3.486 1.412 1.412 0 0 0-.126.508.681.681 0 0 0 .088.443.44.44 0 0 0 .35.146 2.652 2.652 0 0 0 .637-.072q.391-.083.951-.213a4.038 4.038 0 0 1 1.027-.128 1.192 1.192 0 0 1 .673.2.959.959 0 0 1 .354.555 3.044 3.044 0 0 1 .069.915 3.94 3.94 0 0 1-.2 1.054 5.046 5.046 0 0 1-.49 1.046 5.68 5.68 0 0 1-.748.968 5.989 5.989 0 0 1-.977.826l-.046.909a.642.642 0 0 1-.1.3.515.515 0 0 1-.2.207l-.836.428a.16.16 0 0 1-.19-.006.242.242 0 0 1-.07-.211l.046-.909a2.6 2.6 0 0 1-.856.131 1.446 1.446 0 0 1-.685-.176 1.14 1.14 0 0 1-.452-.492 1.767 1.767 0 0 1-.16-.823.539.539 0 0 1 .09-.3.563.563 0 0 1 .207-.2l.722-.37a.358.358 0 0 1 .264-.057.266.266 0 0 1 .142.16 1.286 1.286 0 0 0 .159.257.468.468 0 0 0 .262.165 1.02 1.02 0 0 0 .427 0 2.468 2.468 0 0 0 .652-.249 4.763 4.763 0 0 0 .6-.366 3.062 3.062 0 0 0 .516-.459 2.63 2.63 0 0 0 .369-.528 1.4 1.4 0 0 0 .16-.583.481.481 0 0 0-.12-.408.606.606 0 0 0-.423-.1 4.291 4.291 0 0 0-.715.118c-.284.069-.613.137-.985.205a3.437 3.437 0 0 1-.884.081.958.958 0 0 1-.95-.777 3.141 3.141 0 0 1-.072-.973 3.815 3.815 0 0 1 .2-1.042 5.523 5.523 0 0 1 1.154-1.938 5.938 5.938 0 0 1 .852-.769l.046-.909a.646.646 0 0 1 .1-.3.519.519 0 0 1 .2-.208l.836-.428a.162.162 0 0 1 .191.007.245.245 0 0 1 .069.211z", transform: "translate(-112.834 -32.933)", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33707", d: "M151.912 39.982a.9.9 0 0 1-1.122.59L142.511 38a.9.9 0 0 1 .532-1.712l8.279 2.57a.9.9 0 0 1 .59 1.122", transform: "translate(-72.869 -18.179)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33708", d: "M148.767 61.071a.9.9 0 0 1-.7.559l-8.573 1.279a.9.9 0 0 1-.264-1.774l8.573-1.279a.9.9 0 0 1 .967 1.215", transform: "translate(-71.154 -30.012)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33709", d: "M180.045 9.315a.9.9 0 0 1-1.73.1l-2.7-8.235a.9.9 0 0 1 1.7-.559l2.7 8.235a.89.89 0 0 1 .026.463", transform: "translate(-89.762)", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { "data-name": "Path 33710", d: "M162.841 20.5a.9.9 0 0 1-1.474.488l-6.475-5.764a.9.9 0 1 1 1.192-1.339l6.475 5.763a.9.9 0 0 1 .282.852", transform: "translate(-79.244 -6.847)", style: {
  fill: "#fff"
} }))));
export default SvgIcon;
