import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useOnboardingUseCase } from '@/features/common/onboarding';
import { useAnalytics } from '@/features/system/analytics';

export const useInstallExtensionPageViewModel = (): {
  countdown: number;
  onInstall: () => Promise<void>;
  onSkip: () => Promise<void>;
} => {
  const navigate = useNavigate();
  const onboardingUseCase = useOnboardingUseCase();
  const [countdown, setCountdown] = useState(5);

  const {
    trackViewInstallExtensionPage,
    trackInstallExtension,
    trackSkipInstallExtension,
  } = useAnalytics();

  const handleInstall = async (): Promise<void> => {
    await onboardingUseCase.completeClickDownloadExtensionStep();
    trackInstallExtension();
    window.open(EXTERNAL_ROUTES.EXTENSION_URL, '_self');
  };

  const handleSkip = async (): Promise<void> => {
    await onboardingUseCase.completeClickDownloadExtensionStep();
    trackSkipInstallExtension();
    navigate(ROUTES.DASHBOARD, { replace: true });
  };

  useEffect(() => {
    trackViewInstallExtensionPage();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countdown === 0) {
        handleInstall();
        clearTimeout(timeout);
        return;
      }

      setCountdown((prev) => prev - 1);
    }, 1_000);

    return (): void => clearTimeout(timeout);
  }, [countdown]);

  return {
    countdown,
    onInstall: handleInstall,
    onSkip: handleSkip,
  };
};
