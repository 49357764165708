import { inject, injectable, injectFromBase } from 'inversify';
import { map } from 'rxjs';

import { SYNC_TYPES } from '@/ioc/types';

import { CollectionName, EnrichmentDocument } from '@/features/system/db';
import { ReplicationService } from '@/features/system/replication';
import { mapBaseSyncEntity } from '@/features/system/replication/data/mappers';
import { IBaseSyncRepository } from '@/features/system/sync';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class EnrichmentReplicationService extends ReplicationService<EnrichmentDocument> {
  @inject(SYNC_TYPES.BaseSyncRepository)
  private syncRepository: IBaseSyncRepository;

  constructor() {
    super({
      collectionName: CollectionName.Enrichment,
      pullStreamFactory: () => {
        return this.syncRepository
          .getEnrichmentEvents()
          .pipe(map(mapBaseSyncEntity<EnrichmentDocument>));
      },
      pushHandlers: {},
    });
  }
}
