import { inject, injectable, injectFromBase } from 'inversify';

import { ACCOUNT_TYPES } from '@/ioc/types';

import { accountSchema, IAccountDC } from '@/features/common/account';
import {
  CollectionName,
  DbCollectionCreator,
  IMigrationService,
} from '@/features/system/db';

@injectable()
@injectFromBase({
  extendConstructorArguments: false,
  extendProperties: true,
})
export class AccountCollectionCreator extends DbCollectionCreator<IAccountDC> {
  constructor(
    @inject(ACCOUNT_TYPES.AccountMigrationService) migrationService: IMigrationService,
  ) {
    super({
      collectionName: CollectionName.Account,
      schema: accountSchema,
      migrationStrategies: migrationService.getMigrationStrategies(),
    });
  }
}
