import { useCallback, useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export function useBehaviorSubject<T>(
  behaviorSubject: BehaviorSubject<T>,
): [T, (value: T) => void] {
  const [state, setState] = useState(behaviorSubject.value);

  useEffect(() => {
    const subscription = behaviorSubject.subscribe((value) => {
      setState(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const updateState = useCallback(
    (value: T) => {
      behaviorSubject.next(value);
    },
    [behaviorSubject],
  );

  return [state, updateState];
}
