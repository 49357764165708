import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { GridSortItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { MangoQuerySortDirection } from 'rxdb';

import { ROUTES } from '@/router/routes';

import { IEnrichmentEntity } from '@/features/enrichment/domain/entities';
import { EnrichmentSortSchemaField } from '@/features/enrichment/domain/types';
import { useTeamMemberSelect } from '@/features/settings/features/teamManagement';
import { useAppLogger } from '@/features/system/logger';

import { downloadFileByUrl } from '@/utils/downloadFileByUrl';
import { useObservableResult } from '@/utils/rx';

import { useEnrichmentUseCase } from './hooks';

type IEnrichmentContext = {
  search: string;
  filterByUser: string;
  data: IEnrichmentEntity[];
  isLoading: boolean;
  isLoaded: boolean;
  sort: GridSortItem | null;
  setSort(value: GridSortItem | null): void;
  setFilterByUser(value: string): void;
  setSearch(value: string): void;
  resetFilterByUser(): void;
  downloadFile(id: string): Promise<void>;
};

export const EnrichmentContext = createContext<IEnrichmentContext>({
  search: '',
  filterByUser: '',
  data: [],
  isLoading: false,
  isLoaded: false,
  sort: null,
  setSort() {},
  setFilterByUser() {},
  setSearch() {},
  resetFilterByUser() {},
  downloadFile() {
    return Promise.resolve();
  },
});

const SortSchemaFieldMapByColumnName: Record<string, EnrichmentSortSchemaField> = {
  name: 'name',
  createdAt: 'created_at',
  status: 'status',
  total: 'statistic.total.count',
  matched: 'statistic.total.matched',
  duplicate: 'statistic.total.duplicate',
  createdBy: 'created_by.full_name',
};

export const EnrichmentContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const logger = useAppLogger();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<GridSortItem | null>(null);
  const {
    value: filterByUser,
    setValue: setFilterByUser,
    resetValue: resetFilterByUser,
  } = useTeamMemberSelect();
  const enrichmentUseCase = useEnrichmentUseCase();

  const idToDownload = searchParams.get('download');

  const enrichmentSortSchema = useMemo(() => {
    if (!sort) return;

    const sortSchemaField: EnrichmentSortSchemaField =
      SortSchemaFieldMapByColumnName[sort.field];
    if (!sortSchemaField) return;

    return {
      [sortSchemaField]: sort.sort as MangoQuerySortDirection,
    };
  }, [sort]);

  const { data, isLoading, isLoaded } = useObservableResult(
    () =>
      enrichmentUseCase.getAllByParams(
        { createdBy: filterByUser, name: search },
        enrichmentSortSchema,
      ),
    {
      deps: [search, filterByUser, enrichmentSortSchema],
      defaultData: [],
    },
  );

  const downloadFile = async (id: string): Promise<void> => {
    try {
      const linkToDownload = await enrichmentUseCase.download(id);
      downloadFileByUrl(linkToDownload, `file-${dayjs().format('YYYY-MM-DDTHH:mm')}.csv`);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (!idToDownload) return;
    (async (): Promise<void> => {
      try {
        await downloadFile(idToDownload);
      } finally {
        navigate(ROUTES.ENRICHMENT_ENTRY, { replace: true });
      }
    })();
  }, [idToDownload]);

  return (
    <EnrichmentContext.Provider
      value={{
        search,
        data,
        isLoading,
        isLoaded,
        sort,
        setSort,
        filterByUser,
        setFilterByUser,
        resetFilterByUser,
        setSearch,
        downloadFile,
      }}
    >
      {children}
    </EnrichmentContext.Provider>
  );
};

export function withEnrichmentContextProvider<Props extends object>(
  Component: FC<Props>,
): FC<Props> {
  return (props: Props) => {
    return (
      <EnrichmentContextProvider>
        {/* @ts-ignore */}
        <Component {...props} />
      </EnrichmentContextProvider>
    );
  };
}
