import { FC } from 'react';
import { Box, OutlinedInput, Typography } from '@mui/material';

import { CardField, PaymentFormField, PaymentMethods } from '@/features/common/billing';

import { CountrySelect } from '@/components';
import { LockIcon } from '@/components/Icons';

import { PaymentFormFieldset } from './components';
import { usePaymentFormViewModel } from './usePaymentFormViewModel';

import styles from './styles.module.scss';

type PaymentFormProps = {
  isProcessing: boolean;
  onSubmit: () => Promise<void>;
};

export const PaymentForm: FC<PaymentFormProps> = ({ isProcessing, onSubmit }) => {
  const { t, bindPaymentControl, bindPaymentFormField, hasPaymentMethod } =
    usePaymentFormViewModel({
      isProcessing,
    });

  return (
    <form
      className={styles.form}
      id="payment-details"
      noValidate
      onSubmit={onSubmit}
      autoComplete="on"
    >
      {hasPaymentMethod ? (
        <PaymentFormFieldset title={t('cardDetailsTitle')}>
          <Box className={styles.cardDetails}>
            <Box className={styles.cardRow}>
              <PaymentFormField
                {...bindPaymentFormField('paymentMethod.card', true)}
                errorPosition="bottom"
              >
                <CardField {...bindPaymentControl('paymentMethod.card')} />
              </PaymentFormField>
              <Box className={styles.secure}>
                <LockIcon />
                <Typography variant="inherit" className={styles.secureText}>
                  {t('secure')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </PaymentFormFieldset>
      ) : (
        <PaymentMethods />
      )}
      <PaymentFormFieldset
        title={t('billingDetailsTitle')}
        subtitle={t('billingDetailsSubtitle')}
      >
        <Box className={styles.billingDetails}>
          <PaymentFormField {...bindPaymentFormField('billingDetails.name', true)}>
            <OutlinedInput
              {...bindPaymentControl('billingDetails.name')}
              autoComplete="name"
            />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.address', true)}>
            <OutlinedInput
              {...bindPaymentControl('billingDetails.address')}
              autoComplete="street-address"
            />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.country', true)}>
            <CountrySelect {...bindPaymentControl('billingDetails.country')} />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.city', true)}>
            <OutlinedInput
              {...bindPaymentControl('billingDetails.city')}
              autoComplete="address-level2"
            />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.state', false)}>
            <OutlinedInput
              {...bindPaymentControl('billingDetails.state')}
              autoComplete="address-level1"
            />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.postalCode', true)}>
            <OutlinedInput
              {...bindPaymentControl('billingDetails.postalCode')}
              autoComplete="postal-code"
            />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.company', false)}>
            <OutlinedInput
              {...bindPaymentControl('billingDetails.company')}
              autoComplete="organization"
            />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.vatId', false)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.vatId')} />
          </PaymentFormField>
        </Box>
      </PaymentFormFieldset>
    </form>
  );
};
